/* eslint-disable no-return-assign */
import { format } from 'date-fns';
import Modal, { Styles } from 'react-modal';
import Linkify from 'react-linkify';
import { useAuth } from 'hooks/auth';
import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as Search } from 'assets/search.svg';
import { ModalCloseButton } from 'components/IndicationModal/style';
import { ReactComponent as PaperPlane } from '../../../../assets/paper_plane.svg';
import { ReactComponent as Answer } from './assets/answer.svg';
import { ReactComponent as Open } from './assets/open.svg';
import { ReactComponent as Closed } from './assets/closed.svg';
import useLogic from './logic';
import { Chat, ItemBody, modalStyle, ModalBody, MessagesContainer } from './style';

import { Title } from '../../style';
import { Textarea } from '../Input';
import { Props } from './types';
import { Loader } from '../SendMessageItem/style';

const SendReportItem: React.FC<Props> = ({ showOpen }: Props) => {
  const { account } = useAuth();
  const {
    handleOnClickTicket,
    isModalOpen,
    closeModal,
    currentTicket,
    afterOpenModal,
    messages,
    handleOnSubmitTicketMessage,
    divModalRef,
    isAnswering,
    handleOnClickAnswer,
    handleOnClickDeleteMessage,
    handleOnClickCancelAnswer,
    formRef,
    handleOnClickCloseTicket,
    isLoadingMessages,
    filteredTickets,
  } = useLogic(showOpen);

  return (
    <ItemBody>
      <Title>Tickets {showOpen ? 'abertos' : 'fechados'}</Title>
      {filteredTickets.length === 0 && <span>Nenhum ticket {showOpen ? 'aberto' : 'fechado'} encontrado.</span>}
      {filteredTickets.map(ticket => (
        <div
          key={ticket.ticket_id}
          aria-hidden
          onClick={() => handleOnClickTicket(ticket)}
          className={showOpen ? '' : 'disabled'}
        >
          {showOpen ? <Open className="warning" /> : <Closed className="disabled" />}
          <p>{ticket.name}</p>
          <p>{ticket?.opening_date && format(ticket?.opening_date?.toDate(), 'dd/MM/yyyy, p')}</p>
          {ticket?.unreadMessages > 0 ? (
            <>
              <Search className="more-info" style={{ marginRight: -20 }} />
              <span className={`counter notification ${ticket.unreadMessages < 9 ? '' : 'bigger'}`}>
                {ticket?.unreadMessages}
              </span>
            </>
          ) : (
            <Search className="more-info" />
          )}
        </div>
      ))}
      <Modal
        isOpen={isModalOpen && currentTicket !== null}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyle as Styles}
        contentLabel="Example Modal"
        appElement={document.getElementById('root') || undefined}
      >
        <ModalBody>
          <ModalCloseButton type="button" onClick={closeModal}>
            <Close />
          </ModalCloseButton>

          {isLoadingMessages && (
            <ItemBody style={{ height: '100%' }}>
              <Loader style={{ height: '100%' }}>
                <span />
              </Loader>
            </ItemBody>
          )}
          {!isLoadingMessages && currentTicket && (
            <Chat>
              {account?.isSupport ? (
                <>
                  <h5>{currentTicket.name}</h5>
                  <h5>{currentTicket.metadata?.email || 'Email não encontrado'}</h5>
                </>
              ) : (
                <h5>Ticket {currentTicket.status === 'open' ? 'aberto' : 'fechado'}</h5>
              )}

              <MessagesContainer ref={divModalRef} onClick={handleOnClickCancelAnswer}>
                {messages?.map(
                  ({ messageId, whoIs, sentAt, message, isMessageAtRightPos, isDeletable, ref, unread }) => (
                    <div className={`message ${isMessageAtRightPos ? 'right' : 'left counter'}`} key={messageId}>
                      {isMessageAtRightPos ? <span className="spacer" /> : <div className="bubble-arrow" />}
                      <div className="message-bubble">
                        <div className="header">
                          <h4>{whoIs}</h4>
                          <span className="spacer" />
                          <p>{sentAt}</p>
                          {isDeletable && (
                            <button onClick={() => handleOnClickDeleteMessage(ref, unread)} type="button">
                              <Close />
                            </button>
                          )}
                        </div>
                        <Linkify>
                          <p>{message}</p>
                        </Linkify>
                      </div>
                      {isMessageAtRightPos ? <div className="bubble-arrow" /> : <span className="spacer" />}
                    </div>
                  ),
                )}
              </MessagesContainer>
              {currentTicket.status === 'open' && (
                <form onSubmit={handleOnSubmitTicketMessage} noValidate ref={formRef}>
                  {isAnswering && <Textarea label="Mensagem" name="report" required />}
                  <div className="buttons-container">
                    {!isAnswering ? (
                      <>
                        <button
                          type="button"
                          className="danger"
                          onClick={() => handleOnClickCloseTicket(currentTicket.ref)}
                        >
                          Finalizar ticket
                        </button>
                        <span />
                        <button aria-label="Reponder" type="button" onClick={handleOnClickAnswer}>
                          <span className="icon">
                            <Answer />
                          </span>
                          Nova mensagem
                        </button>
                      </>
                    ) : (
                      <>
                        <span />
                        <button className="success" aria-label="Salvar" type="submit">
                          <span className="icon">
                            <PaperPlane />
                          </span>
                          Enviar
                        </button>
                      </>
                    )}
                  </div>
                </form>
              )}
            </Chat>
          )}
        </ModalBody>
      </Modal>
    </ItemBody>
  );
};

export default SendReportItem;
