import { PayButton } from 'components/ShowPlanStatus/style';
import styled from 'styled-components';

export const CNPJInput = styled.input`
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 0;
`;

export const SupportChangeAccountButton = styled(PayButton)`
  margin-bottom: 20px;
  border-radius: 50px;
  padding: 15px;
  width: 90px;
  height: 90px;

  background-color: #5a6abd;
  :hover {
    background-color: #3f4b88;
  }

  :active,
  :focus {
    box-shadow: 0 0 0 0.2rem #7483ce;
  }

  > svg {
    width: 30px;
    height: 30px;
    path {
      fill: #fff;
    }
  }

  > p {
    font-size: 12px !important;
    font-weight: bold;
  }
`;

export const SupportWarning = styled.div`
  background-color: #eee;
  border-radius: 5px;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-justify: inter-word;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);

  p {
    vertical-align: middle;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    font-weight: 400;
    color: #666666;
  }
`;
