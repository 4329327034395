import { FC } from 'react';
import { ContainerBase } from '../style';
import { Textarea as TextareaInput, Label } from './style';
import { Props } from './types';

const Textarea: FC<Props> = ({ label, name, required }: Props) => {
  return (
    <ContainerBase>
      <Label htmlFor={name}>
        {label}
        &nbsp;
        {required && <span>*</span>}
        <TextareaInput id={name} name={name} required={required} rows={5} />
        <span className="error">Preencha este campo</span>
      </Label>
    </ContainerBase>
  );
};

export default Textarea;
