import { PlanIcon, PlanTitle, PlanSubTitle, PayButton, PriceSection, FlexContainer } from './style';

interface Props {
  onClick(): void;
  isButtonDisabled: boolean;
}

const BillWarning: React.FC<Props> = ({ onClick, isButtonDisabled }: Props) => {
  return (
    <FlexContainer>
      <PlanIcon
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <rect fill="none" height="24" width="24" />
        <path d="M21.9,21.9L2.1,2.1L0.69,3.51l1.55,1.55C2.09,5.34,2.01,5.66,2.01,6L2,18c0,1.11,0.89,2,2,2h13.17l3.31,3.31L21.9,21.9z M4,12V8h1.17l4,4H4z M6.83,4H20c1.11,0,2,0.89,2,2v12c0,0.34-0.08,0.66-0.23,0.94L14.83,12H20V8h-9.17L6.83,4z" />
      </PlanIcon>
      <div>
        <PlanTitle>Fatura atrasada</PlanTitle>
        <PlanSubTitle>O pagamento foi recusado.</PlanSubTitle>
        <PriceSection>
          <span style={{ flex: 1 }} />
          <PayButton type="button" onClick={onClick} disabled={isButtonDisabled}>
            Atualizar dados de pagamento
          </PayButton>
        </PriceSection>
      </div>
    </FlexContainer>
  );
};

export default BillWarning;
