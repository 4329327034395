import { ContainerDefault } from 'components/Skin/style';
import { useSelector } from 'react-redux';
import { IStore } from 'store/types';
import styled from 'styled-components';

interface ContainerProps {
  isShown: boolean;
}

export const ContainerBase = styled(ContainerDefault)<ContainerProps>`
  > button {
    padding: 0px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    > div {
      display: flex;
      align-items: center;
      align-self: center;
      align-content: center;
      margin: auto;

      > p {
        margin-left: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11.5px;
      }
      svg {
        width: 30px;
        height: 30px;
        padding-top: 5px;
      }
    }
  }

  > p {
    margin-top: 6px;
    margin-bottom: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px !important;
    text-align: justify;
  }
`;

export const Container = styled(ContainerBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  background-color: ${props => props.color};
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;
