import { ContainerDefault } from 'components/Skin/style';
import { useSelector } from 'react-redux';
import { IStore } from 'store/types';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isShown: boolean;
  minHeight?: number;
}

export const ContainerBase = styled(ContainerDefault)<ContainerProps>`
  min-height: 100px;
  position: relative;
  -webkit-transform: translateX(0);
  transform: translateX(0);

  > div .consumption-status {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;

    > p {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 25px;
      margin-left: 10px;
      margin-right: 10px;
      font-weight: bold;
    }
  }

  > div .releasing-point-color {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    width: 220px;
    height: 62px;
    opacity: 70%;
    top: 55px;
    left: 15px;
  }

  > div .releasing-point-color.mini {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    width: 220px;
    height: 40px;
    opacity: 70%;
    top: 55px;
    left: 15px;
  }

  > div .releasing-point {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    width: 220px;
    height: 50px;
    top: 43%;
    left: 15px;

    > button {
      padding: 8px;
      align-self: center;
      border-radius: 5px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);

      > p {
        padding-left: 5px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        height: 100%;
        align-items: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        text-align: center;
        margin: auto 0;
      }
      > svg {
        width: 18px;
        height: 18px;
      }
    }
  }
`;
export const Container = styled(ContainerBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const BackgroundColorBase = styled.div`
  background-color: ${props => props.color};
`;

export const BackgroundColor = styled(BackgroundColorBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  background-color: ${props => props.color};
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const Subtitle = styled.div`
  display: flex;

  > p {
    max-height: 13px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px !important;
    display: flex;

    > span {
      width: 8px !important;
      height: 8px;
      background-color: green !important;
      border-radius: 15px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 5px;
    }
  }

  > div {
    width: 100% !important;

    > .lock {
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
      max-height: 25px;
      p {
        padding-left: 5px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        align-items: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        text-align: center;
        margin: auto 0;
      }

      > div > svg {
        align-items: center;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        text-align: center;
        margin: auto 0;
        width: 14px;
        height: 14px;
        margin-bottom: 2px;
      }
    }
  }
`;

interface ConsumptionStatusProps {
  color: string;
}

export const ConsumptionStatus = styled.div<ConsumptionStatusProps>`
  max-width: 100px;
  color: ${props => props.color};

  > p {
    min-width: 80px !important;
    max-width: 10px;
  }

  > div {
    display: flex;

    > h1 {
      font-size: 35px;
      font-weight: bold;
    }

    > p {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;

export const ContainerModule = styled.div<ContainerProps>`
  display: ${props => (props.isShown ? 'block' : 'none')};
  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `};

  > button {
    margin-top: 10px;
    padding: 0px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    > div {
      display: flex;
      align-items: center;
      align-self: center;
      align-content: center;
      margin: auto;

      > p {
        margin-left: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11.5px;
      }
      svg {
        width: 30px;
        height: 30px;
        padding-top: 5px;
      }
    }
  }
`;
