import styled, { css, keyframes } from 'styled-components';

const buildAnimation = (backgroundColor: string) => keyframes`
  0% {
    background-color: ${backgroundColor};
  }
  50% {
    background-color: ${backgroundColor}50;
    text-shadow: 0px 0px 5px rgb(0 0 0);
  }
  100% {
    background-color: ${backgroundColor};
  }
`;

interface ContainerProps {
  borderRadiusTopLeft: boolean;
  backgroundColor?: string;
  color?: string;
  blink: boolean;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  background-color: #fff;
  border-radius: 0px 10px 0px 0px;

  > p {
    background-color: #9ca8b6;
    text-align: center;
    border-radius: 0px 10px 0px 0px;
    padding-top: 5px;
    color: #fff;
    font-size: 16px !important;
    text-shadow: 0px 0px 1px rgb(0 0 0 / 0%);
  }

  > div {
    background-color: #9ca8b6;
    height: 10px;
    max-width: 50%;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
  }

  @media (max-width: 1920px) {
    > p {
      font-size: 14px !important;
    }
  }
  @media (max-width: 1280px) {
    > p {
      font-size: 12px !important;
    }
    > div {
      max-width: 60%;
    }
  }

  ${props =>
    props.backgroundColor &&
    css`
      > p,
      > div {
        background-color: ${props.backgroundColor};
      }
    `}

  ${props =>
    props.borderRadiusTopLeft &&
    css`
      border-radius: 10px 10px 0px 0px;
      > p {
        border-radius: 10px 10px 0px 0px;
      }
    `}

  ${props =>
    props.color &&
    css`
      > p {
        font-weight: bold;
        color: ${props.color};
      }
    `}

  ${props =>
    props.blink &&
    props.backgroundColor &&
    css`
      > p,
      > div {
        animation: ${buildAnimation(props.backgroundColor)} normal 3s infinite ease-in-out;
      }
    `}
`;
