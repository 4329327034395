/* eslint-disable import/no-duplicates */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import initializeApp from '../initialize-app.json';

firebase.initializeApp(initializeApp);

const functionLocation = 'us-central1';
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.app().functions(functionLocation);
const remoteConfig = firebase.app().remoteConfig();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.REACT_APP_ENVIRONMENT === 'DEBUG' && process.env.NODE_ENV === 'development') {
  db.settings({
    host: `${process.env.REACT_APP_DEBUG_HOST}:${process.env.REACT_APP_DEBUG_FIRESTORE_PORT}`,
    ssl: false,
  });
  auth.useEmulator(`http://${process.env.REACT_APP_DEBUG_HOST}:${process.env.REACT_APP_DEBUG_AUTH_PORT}`);
  // firebase.firestore.setLogLevel('debug');
}

export { db, auth, functions, remoteConfig };

export default firebase;
