import { DashboardProvider } from './dashboard';
import { AuthProvider } from './auth';
import { SkinProvider } from './skin';
import { ProviderProvider } from './provider';
import { SystemProvider } from './system';

interface Props {
  children: React.ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <DashboardProvider>
      <SkinProvider>
        <ProviderProvider>
          <SystemProvider>
            <AuthProvider>{children}</AuthProvider>
          </SystemProvider>
        </ProviderProvider>
      </SkinProvider>
    </DashboardProvider>
  );
};

export default AppProvider;
