import styled from 'styled-components';
import { LabelInputBase } from '../style';

export const Label = styled(LabelInputBase)`
  > span.error {
    visibility: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    color: rgb(215, 45, 48);
    font-size: 0.75rem;
  }
`;

export const Textarea = styled.textarea`
  visibility: visible;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  padding: 11px 8px 10px 12px;
  margin: 5px 0px 3px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid rgb(207, 215, 223);
  resize: none;
  overflow: auto;
  box-shadow: rgba(18, 52, 77, 0.05) 0px 1px 2px 0px inset;
  width: 100%;
  outline: none;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  &.errored + span {
    visibility: visible;
  }
`;
