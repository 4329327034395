/* eslint-disable camelcase */
import { useAuth } from 'hooks/auth';
import { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from 'utils/firebase';
import { MySwal } from 'utils/MySwal';
import { ILogicReturn } from './types';

const useIndicationLogic = (): ILogicReturn => {
  const { account, user } = useAuth();
  const ticketsCollection = db.collection('tickets');
  const ticketsQuery = account?.isSupport
    ? ticketsCollection
    : ticketsCollection
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .where('metadata.account', '==', db.collection('accounts').doc(user!.uid));

  const [tickets, loading, error] = useCollection(ticketsQuery.orderBy('opening_date', 'desc'));

  useEffect(() => {
    error &&
      MySwal({
        showCancelButton: false,
        confirmButtonText: 'Entendi',
        title: <strong>Aviso</strong>,
        icon: 'error',
        html: <>Ocorreu uma falha ao buscar seus tickets, por favor tente novamente mais tarde.</>,
      });
  }, [error]);

  return { tickets, loadingTickets: loading, errorTickets: error };
};

export default useIndicationLogic;
