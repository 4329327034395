import { createContext, useContext } from 'react';
import { AuthContextData, Props } from './types';
import useAuthLogic from './logic';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    wasTermsShow,
    isLoginErrored,
    setIsLoginErrored,
    loadingUser,
    user,
    account,
    signOut,
    cleanSession,
    scheduleDelete,
    renewSub,
    handleClickSupportChangeAccount,
    saveIndicationCode,
    cleanIndication,
    indicationCode,
    handleGoogleAuth,
  } = useAuthLogic();

  return (
    <AuthContext.Provider
      value={{
        wasTermsShow,
        isLoginErrored,
        setIsLoginErrored,
        loadingUser,
        user,
        account,
        signOut,
        cleanSession,
        scheduleDelete,
        renewSub,
        handleClickSupportChangeAccount,
        saveIndicationCode,
        cleanIndication,
        indicationCode,
        handleGoogleAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
