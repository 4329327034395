import { getValue } from 'utils/dotNotation';
import { SvgFill, SvgFillBase } from './style';
import { ISkinProps } from './types';

const Consumption: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  let RealSvgFill = SvgFillBase;

  if (!skin) {
    RealSvgFill = SvgFill;
  }

  return (
    <RealSvgFill
      width="30"
      height="30"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M15.8438 35.8719C15.8438 36.2082 15.571 36.4813 15.2344 36.4813C14.8978 36.4813 14.625 36.2082 14.625 35.8719C14.625 35.5353 14.8978 35.2625 15.2344 35.2625C15.571 35.2625 15.8438 35.5353 15.8438 35.8719Z"
        fill="black"
      />
      <path
        d="M32.9062 35.8719C32.9062 36.2082 32.6335 36.4813 32.2969 36.4813C31.9603 36.4813 31.6875 36.2082 31.6875 35.8719C31.6875 35.5353 31.9603 35.2625 32.2969 35.2625C32.6335 35.2625 32.9062 35.5353 32.9062 35.8719Z"
        fill="black"
      />
      <path d="M30.4688 18.2H34.125V31.6063H30.4688V18.2Z" fill="black" />
      <path
        d="M24.375 35.8719C24.375 36.2082 24.1022 36.4813 23.7656 36.4813C23.429 36.4813 23.1562 36.2082 23.1562 35.8719C23.1562 35.5353 23.429 35.2625 23.7656 35.2625C24.1022 35.2625 24.375 35.5353 24.375 35.8719Z"
        fill="black"
      />
      <path
        d="M15.8438 35.8719C15.8438 36.2082 15.571 36.4813 15.2344 36.4813C14.8978 36.4813 14.625 36.2082 14.625 35.8719C14.625 35.5353 14.8978 35.2625 15.2344 35.2625C15.571 35.2625 15.8438 35.5353 15.8438 35.8719Z"
        fill="black"
      />
      <path
        d="M7.3125 35.8719C7.3125 36.2082 7.03971 36.4813 6.70312 36.4813C6.36654 36.4813 6.09375 36.2082 6.09375 35.8719C6.09375 35.5353 6.36654 35.2625 6.70312 35.2625C7.03971 35.2625 7.3125 35.5353 7.3125 35.8719Z"
        fill="black"
      />
      <path d="M13.4062 9.66876H17.0625V31.6063H13.4062V9.66876Z" fill="black" />
      <path d="M21.9375 2.35626H25.5938V31.6063H21.9375V2.35626Z" fill="black" />
      <path d="M4.875 24.2938H8.53125V31.6063H4.875V24.2938Z" fill="black" />
      <path
        d="M4.875 35.8719C4.87714 35.6638 4.91499 35.4577 4.98712 35.2625H1.82813C1.49154 35.2625 1.21875 35.5353 1.21875 35.8719C1.21875 36.2082 1.49154 36.4813 1.82813 36.4813H4.98712C4.91499 36.2861 4.87714 36.0797 4.875 35.8719Z"
        fill="black"
      />
      <path
        d="M8.41919 35.2625C8.56891 35.6548 8.56891 36.0887 8.41919 36.4813H13.5184C13.3687 36.0887 13.3687 35.6548 13.5184 35.2625H8.41919Z"
        fill="black"
      />
      <path
        d="M7.3125 35.8719C7.3125 36.2082 7.03971 36.4813 6.70312 36.4813C6.36654 36.4813 6.09375 36.2082 6.09375 35.8719C6.09375 35.5353 6.36654 35.2625 6.70312 35.2625C7.03971 35.2625 7.3125 35.5353 7.3125 35.8719Z"
        fill="black"
      />
      <path
        d="M24.375 35.8719C24.375 36.2082 24.1022 36.4813 23.7656 36.4813C23.429 36.4813 23.1562 36.2082 23.1562 35.8719C23.1562 35.5353 23.429 35.2625 23.7656 35.2625C24.1022 35.2625 24.375 35.5353 24.375 35.8719Z"
        fill="black"
      />
      <path
        d="M22.0497 36.4813C21.8999 36.0887 21.8999 35.6548 22.0497 35.2625H16.9504C17.1002 35.6548 17.1002 36.0887 16.9504 36.4813H22.0497Z"
        fill="black"
      />
      <path
        d="M30.5809 36.4813C30.4312 36.0887 30.4312 35.6548 30.5809 35.2625H25.4817C25.6314 35.6548 25.6314 36.0887 25.4817 36.4813H30.5809Z"
        fill="black"
      />
      <path
        d="M32.9062 35.8719C32.9062 36.2082 32.6335 36.4813 32.2969 36.4813C31.9603 36.4813 31.6875 36.2082 31.6875 35.8719C31.6875 35.5353 31.9603 35.2625 32.2969 35.2625C32.6335 35.2625 32.9062 35.5353 32.9062 35.8719Z"
        fill="black"
      />
      <path
        d="M37.1719 35.2625H34.0129C34.1627 35.6548 34.1627 36.0887 34.0129 36.4813H37.1719C37.5085 36.4813 37.7813 36.2082 37.7813 35.8719C37.7813 35.5353 37.5085 35.2625 37.1719 35.2625Z"
        fill="black"
      />
    </RealSvgFill>
  );
};

export default Consumption;
