/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAuth } from 'hooks/auth';
import { useEffect } from 'react';
import queryString from 'query-string';
import Login from '../../components/Login';
import Overlay from '../../components/Overlay';
import { useDashboard } from '../../hooks/dashboard';

const SignIn: React.FC = () => {
  const {
    signOut,
    loadingUser,
    user,
    // saveIndicationCode
    handleGoogleAuth,
  } = useAuth();
  const { isLoading } = useDashboard();

  const params = queryString.parse(window.location.search.substr(1));
  // NEED REFACTOR
  // useEffect(() => {
  //   saveIndicationCode(params.code as string);
  // }, [saveIndicationCode]);

  useEffect(() => {
    if (params.signup) {
      handleGoogleAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingUser && (user !== undefined || user !== null)) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUser, user]);

  return (
    <>
      <Login />

      <Overlay isLoading={isLoading} />
    </>
  );
};

export default SignIn;
