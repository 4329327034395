/* eslint-disable camelcase */
import { useTicket } from 'components/SendSupportMessageButton/hooks/Ticket';
import { useAuth } from 'hooks/auth';
import { useEffect, useState } from 'react';
import { ILogicReturn } from './types';

const useButtonLogic = (): ILogicReturn => {
  const { account } = useAuth();
  const { tickets } = useTicket();
  const [allUnreadMessages, setAllUnreadMessages] = useState(0);

  useEffect(() => {
    const ticketsData = tickets?.docs.map(doc => doc.data()) || [];
    const allUnread = ticketsData.reduce(
      (pV, { provider_unread_messages: pU, support_unread_messages: sU }) => pV + ((account?.isSupport ? pU : sU) || 0),
      0,
    );
    setAllUnreadMessages(allUnread);
  }, [account?.isSupport, tickets]);

  return { allUnreadMessages };
};

export default useButtonLogic;
