import { PlanTitle, PlanSubTitle, PayButton, PriceSection, PlanIcon, FlexContainer } from './style';

interface Props {
  onClick(): void;
  isButtonDisabled: boolean;
}

const Canceled: React.FC<Props> = ({ onClick, isButtonDisabled }: Props) => {
  return (
    <FlexContainer>
      <PlanIcon xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
      </PlanIcon>
      <div>
        <PlanTitle>Assinar agora</PlanTitle>
        <PlanSubTitle>
          Para continuar usando o App Provedor com mais de 100 clientes realize sua assinatura por aqui.
        </PlanSubTitle>
        <PriceSection>
          <span style={{ flex: 1 }} />
          <PayButton type="button" onClick={onClick} disabled={isButtonDisabled}>
            Assinar
          </PayButton>
        </PriceSection>
      </div>
    </FlexContainer>
  );
};

export default Canceled;
