import styled from 'styled-components';

export const OverlayContainer = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  inset: 0px;
  z-index: 10;
`;

export const OverlayBackground = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  background-color: #f8f9fa !important;
  border-radius: 0.2rem !important;
  position: absolute !important;
  inset: 0px;
  opacity: 0.85;
  backdrop-filter: blur(2px);

  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  -webkit-backdrop-filter: blur(2px);

  @media (min-width: 1920px) {
    min-width: 1100px;
  }
`;

export const OverlayLoader = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  span {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    box-sizing: border-box;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-radius: 50%;
    animation: spin 0.75s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
