import { getValue } from 'utils/dotNotation';
import { SvgFill } from './style';
import { ISkinProps } from './types';

const SpeedTest: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  return (
    <SvgFill
      width="30"
      height="30"
      viewBox="0 0 33 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M27.4841 5.47765C24.4791 2.3093 20.4567 0.537283 16.2696 0.537354C7.37545 0.537495 0.165397 8.37263 0.165527 18.0376C0.165592 22.6182 1.81836 27.0162 4.76908 30.2874C4.96701 30.5132 5.29585 30.5218 5.50352 30.3067C5.71125 30.0916 5.71917 29.7343 5.52124 29.5086C5.51787 29.5047 5.51436 29.5009 5.51085 29.4971C-0.313381 23.0402 -0.218053 12.6752 5.72378 6.34615C11.6656 0.0170813 21.204 0.12067 27.0283 6.57754C29.7887 9.63778 31.3349 13.7522 31.3349 18.0374C31.3433 22.2954 29.8165 26.3877 27.0818 29.4372C26.882 29.661 26.887 30.0186 27.0929 30.2357C27.2989 30.4528 27.628 30.4474 27.8278 30.2236C34.0213 23.2871 33.8674 12.2079 27.4841 5.47765Z"
        fill="#F0F0F0"
      />
      <path
        d="M16.9647 15.908C16.7414 15.8222 16.5063 15.7787 16.2695 15.7793C15.7723 15.7815 15.2926 15.979 14.9189 16.3354L11.4419 12.5576C11.2355 12.341 10.9066 12.3473 10.7073 12.5715C10.5129 12.7903 10.5129 13.1371 10.7073 13.3558L14.3199 17.2815C13.9386 18.4544 14.5045 19.7412 15.5839 20.1555C16.6633 20.5699 17.8474 19.9549 18.2287 18.782C18.61 17.6091 18.0441 16.3223 16.9647 15.908ZM16.2695 19.1664C15.6957 19.1664 15.2306 18.661 15.2306 18.0374C15.2306 17.4138 15.6957 16.9083 16.2695 16.9083C16.8434 16.9083 17.3085 17.4138 17.3085 18.0374C17.3085 18.661 16.8433 19.1664 16.2695 19.1664Z"
        fill="#F0F0F0"
      />
      <path
        d="M16.789 5.05354V2.79547C16.789 2.48372 16.5564 2.23096 16.2695 2.23096C15.9826 2.23096 15.75 2.48372 15.75 2.79547V5.05354C15.75 5.36529 15.9826 5.61805 16.2695 5.61805C16.5564 5.61805 16.789 5.36529 16.789 5.05354Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.24313 17.4729C1.95624 17.4729 1.72363 17.7257 1.72363 18.0374C1.72363 18.3492 1.95624 18.6019 2.24313 18.6019H4.3211C4.60799 18.6019 4.84059 18.3492 4.84059 18.0374C4.84059 17.7257 4.60799 17.4729 4.3211 17.4729H2.24313Z"
        fill="#F0F0F0"
      />
      <path
        d="M30.296 18.6019C30.5828 18.6019 30.8154 18.3492 30.8154 18.0374C30.8154 17.7257 30.5828 17.4729 30.296 17.4729H28.218C27.9311 17.4729 27.6985 17.7257 27.6985 18.0374C27.6985 18.3492 27.9311 18.6019 28.218 18.6019H30.296Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.02049 8.27514L6.55136 6.67869C6.35207 6.45444 6.02317 6.44823 5.8168 6.66479C5.61043 6.88135 5.60472 7.23876 5.80401 7.46301C5.80816 7.46774 5.81245 7.47233 5.8168 7.47691L7.28593 9.07336C7.48522 9.29762 7.81412 9.30383 8.02049 9.08726C8.22686 8.8707 8.23257 8.51329 8.03328 8.28904C8.02906 8.28431 8.02478 8.27973 8.02049 8.27514Z"
        fill="#F0F0F0"
      />
      <path
        d="M26.0006 6.66485C25.9962 6.66937 25.992 6.67402 25.9878 6.67868L24.5187 8.27514C24.3123 8.49177 24.3066 8.84911 24.506 9.07336C24.7053 9.29762 25.0342 9.30383 25.2405 9.08719C25.2449 9.08268 25.2491 9.07802 25.2533 9.07336L26.7224 7.47691C26.9288 7.26027 26.9345 6.90294 26.7351 6.67868C26.5358 6.45443 26.2069 6.44822 26.0006 6.66485Z"
        fill="#F0F0F0"
      />
      <path
        d="M25.2532 27.0013C25.0469 26.7846 24.718 26.7909 24.5187 27.0151C24.3242 27.2339 24.3242 27.5807 24.5187 27.7995L25.9878 29.3959C26.1942 29.6126 26.523 29.6064 26.7224 29.3821C26.9168 29.1634 26.9168 28.8165 26.7224 28.5977L25.2532 27.0013Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.28592 27.0012L5.81679 28.5977C5.61042 28.8143 5.60471 29.1717 5.80406 29.3959C6.00336 29.6202 6.33226 29.6264 6.53863 29.4097C6.54298 29.4052 6.5472 29.4006 6.55136 29.3959L8.02049 27.7995C8.21978 27.5752 8.21413 27.2179 8.00776 27.0012C7.80639 26.79 7.48723 26.79 7.28592 27.0012Z"
        fill="#F0F0F0"
      />
    </SvgFill>
  );
};

export default SpeedTest;
