import { IAlert } from 'hooks/dashboard';
import { AnyAction } from 'redux';
import { SET_ALERT } from 'store/contants';

const initialState = [] as IAlert[];

export const alert = (state = initialState, action: AnyAction): IAlert[] => {
  const newState = [...state];
  newState.length = 2;

  switch (action.type) {
    case SET_ALERT:
      newState.unshift(action.alert);
      return newState;
    default:
      return state;
  }
};
