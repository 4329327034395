import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { createStore } from 'redux';
import reducers from 'store/reducers';
import { Provider } from 'react-redux';
import App from './App';

export const store = createStore(reducers);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
