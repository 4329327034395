import { getValue } from 'utils/dotNotation';
import { SvgFill, SvgFillBase } from './style';
import { ISkinProps } from './types';

const User: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  let RealSvgFill = SvgFillBase;

  if (!skin) {
    RealSvgFill = SvgFill;
  }

  return (
    <RealSvgFill
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M19.1005 19.106C19.02 19.1063 18.9402 19.0907 18.8657 19.0602C18.7912 19.0296 18.7235 18.9846 18.6665 18.9278C18.6094 18.8709 18.5642 18.8034 18.5333 18.729C18.5024 18.6547 18.4865 18.575 18.4865 18.4944V18.0923C18.4865 16.9723 17.7912 15.9678 16.7591 15.5992C15.4874 15.1435 13.3771 14.5998 10.497 14.5998C7.61691 14.5998 5.50666 15.1444 4.23917 15.5967C3.20876 15.9653 2.51345 16.9689 2.51345 18.089V18.4911C2.51345 18.8312 2.237 19.1026 1.89939 19.1026C1.56178 19.1026 1.28198 18.8295 1.28198 18.4944V18.0923C1.28198 16.452 2.30401 14.9869 3.82533 14.4432C5.18414 13.9548 7.44182 13.3742 10.497 13.3742C13.5522 13.3742 15.8082 13.9548 17.1687 14.4432C18.69 14.9877 19.7121 16.4562 19.7121 18.0923V18.4944C19.7121 18.8295 19.4356 19.106 19.1005 19.106ZM10.497 2.93776C12.5285 2.93776 14.183 4.59228 14.183 6.62378C14.183 8.65528 12.5294 10.3098 10.497 10.3098C8.46469 10.3098 6.81101 8.65696 6.81101 6.62378C6.81101 4.59061 8.46553 2.93776 10.497 2.93776ZM10.497 1.70797C9.21241 1.73702 7.99019 2.26773 7.09192 3.18654C6.19366 4.10535 5.69071 5.33925 5.69071 6.6242C5.69071 7.90915 6.19366 9.14305 7.09192 10.0619C7.99019 10.9807 9.21241 11.5114 10.497 11.5404C11.79 11.5236 13.0243 10.9981 13.9327 10.0778C14.841 9.15749 15.3503 7.91644 15.3503 6.62336C15.3503 5.33028 14.841 4.08924 13.9327 3.16894C13.0243 2.24865 11.79 1.72318 10.497 1.7063V1.70797Z"
        fill="none"
      />
    </RealSvgFill>
  );
};

export default User;
