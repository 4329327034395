import { useAuth } from 'hooks/auth';
import { useDashboard } from 'hooks/dashboard';
import api from 'services/api';
import { functions } from 'utils/firebase';
import { getValuesToCharge, perCustomer } from 'utils/charger';
import { useSystem } from 'hooks/system';
import { MySwal } from 'utils/MySwal';
import { IPaymentStatus } from 'hooks/auth/types';
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import NonBillable from './NonBillable';
import Billable from './Billable';
import { PlanContainer } from './style';
import Paid from './Paid';
import BillWarning from './BillWarning';
import Blocked from './Blocked';
import Canceled from './Canceled';

const showErrorAlert = (
  message = 'Ocorreu um erro ao acessar os servidores da stripe, por favor tente novamente mais tarde',
) => {
  MySwal({
    showCancelButton: false,
    confirmButtonText: 'Entendi',
    title: <strong>Algo deu errado</strong>,
    icon: 'error',
    html: <>{message}</>,
  });
};

interface Props {
  setIsLoading?(value: boolean): void;
  status?: IPaymentStatus;
  uid?: string;
  isDocumentAndNameNotSet: boolean;
}

const ShowPlanStatus: React.FC<Props> = ({ setIsLoading, status, uid, isDocumentAndNameNotSet }: Props) => {
  const { user, account } = useAuth();
  const { setIsLoading: setIsLoadingByDash, setLoaderMessage } = useDashboard();
  const { sensitives } = useSystem();
  const { allCustomers, quantity } = getValuesToCharge(sensitives);
  const maxCustomers = Number(perCustomer) * (account?.subQuantity || quantity);
  const [isClicked, setIsClicked] = useState(false);

  const createCheckout = () => {
    setIsClicked(true);
    const userUid = user?.uid || uid;
    if (userUid) {
      if (setIsLoadingByDash) {
        setLoaderMessage('Estamos criando sua conta de pagamento, aguarde, pode demorar alguns segundos...');
        setIsLoadingByDash(true);
      } else {
        setIsLoading && setIsLoading(true);
      }
      api
        .post<{ url: string }>('/viewAccountCreateCheckout')
        .then(res => {
          if (res.data?.url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(res.data.url);
          } else {
            showErrorAlert('Ocorreu um erro ao acessar os servidores da stripe, por favor tente novamente mais tarde');
          }
        })
        .catch(() => {
          setIsClicked(false);
          showErrorAlert();
        })
        .finally(() => {
          setIsLoadingByDash && setIsLoadingByDash(false);
          setIsLoading && setIsLoading(false);
        });
    }
  };

  const changeAccount = () => {
    setIsClicked(true);
    if (setIsLoadingByDash) {
      setLoaderMessage('Estamos acessando sua conta de pagamento, aguarde...');
      setIsLoadingByDash(true);
    } else {
      setIsLoading && setIsLoading(true);
    }
    const functionRef = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const req = { returnUrl: window.location.origin };
    functionRef(req)
      .then(({ data }) => window.location.assign(data.url))
      .catch(() => {
        setIsClicked(false);
        showErrorAlert();
      })
      .finally(() => {
        setIsLoadingByDash && setIsLoadingByDash(false);
        setIsLoading && setIsLoading(false);
      });
  };

  const setComponent = () => {
    switch (status || account?.paymentStatus) {
      case 'canceled':
        return <Canceled onClick={createCheckout} isButtonDisabled={isClicked} />;
      case 'billable':
        return (
          <Billable
            onClick={createCheckout}
            isButtonDisabled={isClicked}
            isDocumentAndNameNotSet={isDocumentAndNameNotSet}
          />
        );
      case 'trialing':
      case 'paid':
        return (
          <Paid
            onClick={changeAccount}
            qtdCustomers={allCustomers}
            maxCustomers={maxCustomers}
            isButtonDisabled={isClicked}
          />
        );
      case 'warning':
        return <BillWarning onClick={changeAccount} isButtonDisabled={isClicked} />;
      case 'blocked':
        return <Blocked onClick={changeAccount} isButtonDisabled={isClicked} />;
      case 'non-billable':
      default:
        return (
          <NonBillable
            onClick={createCheckout}
            isButtonDisabled={isClicked}
            isDocumentAndNameNotSet={isDocumentAndNameNotSet}
          />
        );
    }
  };

  return (
    <PlanContainer>
      {isClicked ? (
        <>
          <SkeletonTheme color="#E3E6EA" highlightColor="#f0f3f8">
            <Skeleton width="100%" height="100%" />
          </SkeletonTheme>
          <div style={{ margin: 15 }} />
        </>
      ) : (
        setComponent()
      )}
    </PlanContainer>
  );
};

export default ShowPlanStatus;
