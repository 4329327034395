import { useSelector } from 'react-redux';
import { IStore } from 'store/types';
import styled from 'styled-components';

export const ParagraphBase = styled.p`
  color: ${props => props.color};
`;

export const Paragraph = styled(ParagraphBase).attrs(() => {
  const color = useSelector((state: IStore) => state.skin.main.header.my_account_button.color);

  return {
    style: {
      color,
    },
  };
})``;

export const DivBase = styled.div`
  color: ${props => props.color};
`;

export const Div = styled(DivBase).attrs(() => {
  const backgroundColor = useSelector(
    (state: IStore) => state.skin.main.header.my_account_button.circle.backgroundColor,
  );

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const H2Base = styled.h2`
  color: ${props => props.color};
`;

export const H2 = styled(H2Base).attrs(() => {
  const color = useSelector((state: IStore) => state.skin.main.header.title.color);

  return {
    style: {
      color,
    },
  };
})``;

export const ContainerBase = styled.div`
  background-color: ${props => props.color};
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 5px;
`;

export const Container = styled(ContainerBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.header.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const NotificationButtonBase = styled.div`
  background-color: ${props => props.color};
  margin-right: 13px;
  border-radius: 7px;
  padding: 5px;
  max-height: 24px;

  > svg {
    width: 15px;
    height: 15px;
  }
`;

export const NotificationButton = styled(NotificationButtonBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.header.my_account_button.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const MyAccountButtonBase = styled.div`
  display: flex;
  background-color: ${props => props.color};
  border-radius: 7px;
  align-items: center;
  padding-right: 10px;
  min-height: 24px;
  max-height: 24px;

  > p {
    margin-left: 8px;
    max-width: 65px;
    min-width: 65px;
    font-size: 11px !important;
  }

  > div {
    height: 20px;
    width: 20px;
    padding-top: 2.5px;
    padding-left: 3px;
    border-radius: 5px;
    margin-right: -8px;
    margin-left: 5px;

    > svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export const MyAccountButton = styled(MyAccountButtonBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.header.my_account_button.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > div.name-and-my-account {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -25px;

    > div {
      margin-left: auto;
      margin-right: 0px;
      max-width: 110px;
    }

    > h2 {
      margin-left: 15px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 29px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      max-width: 187px;
    }
  }

  > img {
    border-radius: 50%;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
  }
`;
