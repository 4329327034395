import { FC } from 'react';
import { ReactComponent as Close } from 'assets/close.svg';

import TitleOptions from 'components/IndicationModal/components/TitleOptions';
import { ModalProvider } from 'components/IndicationModal/hooks/Modal';
import { ModalCloseButton } from 'components/IndicationModal/style';
import { TicketProvider } from './hooks/Ticket';
import { ModalBody } from './style';
import ModalLogic from './logic';
import Button from './components/Button';
import Menu from './components/Menu';

const SendSupportMessageButton: FC = () => {
  const { handleClickModalButton, handleCloseModalButton, isModalShown } = ModalLogic();

  return (
    <>
      <TicketProvider>
        {isModalShown && (
          <ModalBody>
            <ModalProvider>
              <div className="title">
                <TitleOptions />
                <ModalCloseButton type="button" onClick={handleCloseModalButton}>
                  <Close />
                </ModalCloseButton>
              </div>
              <Menu />
            </ModalProvider>
          </ModalBody>
        )}
        <Button onClick={handleClickModalButton} />
      </TicketProvider>
    </>
  );
};

export default SendSupportMessageButton;
