import {
  Text,
  Textarea,
  // File
} from '../Input';
import { ItemBody, Loader } from './style';
import { ReactComponent as Sent } from './assets/sent.svg';
import useLogic from './logic';
import { Title } from '../../style';

const SendMessageItem: React.FC = () => {
  const { handleOnSubmit, isFormSent, isLoading } = useLogic();

  return (
    <ItemBody>
      {isLoading && (
        <Loader>
          <span />
        </Loader>
      )}
      {!isLoading &&
        (isFormSent ? (
          <div className="form-sent">
            <Sent />
            <div>Obrigado. Responderemos em breve.</div>
          </div>
        ) : (
          <>
            <Title>Pedir ajuda ao suporte</Title>
            <form noValidate onSubmit={handleOnSubmit}>
              <Text label="Seu nome" name="name" />
              <Textarea label="Fale mais sobre o seu pedido" name="description" required />
              {/* <File name="files" /> */}
              <p className="disclaimer">Os tickets são respondidos em até 48 horas.</p>
              <button aria-label="Salvar" type="submit">
                Abrir ticket
              </button>
            </form>
          </>
        ))}
    </ItemBody>
  );
};

export default SendMessageItem;
