import { defaultStyle } from 'components/Skin/style';
import { ISkin } from 'hooks/skin/types';
import { AnyAction } from 'redux';
import {
  SET_SKIN_TITLE,
  CHANGE_ALL,
  SKIN_GENERAL_BACKGROUNDCOLOR,
  SKIN_MAIN_HEADER_BACKGROUNDCOLOR,
  SKIN_MAIN_HEADER_TITLE_COLOR,
  SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_BACKGROUNDCOLOR,
  SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_COLOR,
  SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_CIRCLE_BACKGROUNDCOLOR,
  SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_CIRCLE_COLOR,
  SKIN_MAIN_CARDS_BACKGROUNDCOLOR,
  SKIN_MAIN_CARDS_COLOR,
  SKIN_MAIN_CARDS_BUTTON_BACKGROUNDCOLOR,
  SKIN_MAIN_CARDS_BUTTON_COLOR,
  SKIN_MAIN_CARDS_MINI_CARDS_BACKGROUNDCOLOR,
  SKIN_MAIN_CARDS_MINI_CARDS_COLOR,
  SKIN_MAIN_CARDS_MINI_CARDS_BUTTON_BACKGROUNDCOLOR,
  SKIN_MAIN_CARDS_MINI_CARDS_BUTTON_COLOR,
} from 'store/contants';

const initialState = defaultStyle;

export const skin = (state = initialState, action: AnyAction): ISkin => {
  const newState = { ...state };
  switch (action.type) {
    case SET_SKIN_TITLE:
      newState.title = action.title;
      return newState;
    case CHANGE_ALL:
      return { ...state, ...action.skin };
    case SKIN_GENERAL_BACKGROUNDCOLOR:
      newState.general.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_HEADER_BACKGROUNDCOLOR:
      newState.main.header.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_HEADER_TITLE_COLOR:
      newState.main.header.title.color = action.color;
      return newState;
    case SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_BACKGROUNDCOLOR:
      newState.main.header.my_account_button.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_COLOR:
      newState.main.header.my_account_button.color = action.color;
      return newState;
    case SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_CIRCLE_BACKGROUNDCOLOR:
      newState.main.header.my_account_button.circle.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_CIRCLE_COLOR:
      newState.main.header.my_account_button.circle.color = action.color;
      return newState;
    case SKIN_MAIN_CARDS_BACKGROUNDCOLOR:
      newState.main.cards.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_CARDS_COLOR:
      newState.main.cards.color = action.color;
      return newState;
    case SKIN_MAIN_CARDS_BUTTON_BACKGROUNDCOLOR:
      newState.main.cards.button.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_CARDS_BUTTON_COLOR:
      newState.main.cards.button.color = action.color;
      return newState;
    case SKIN_MAIN_CARDS_MINI_CARDS_BACKGROUNDCOLOR:
      newState.main.cards.mini_cards.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_CARDS_MINI_CARDS_COLOR:
      newState.main.cards.mini_cards.color = action.color;
      return newState;
    case SKIN_MAIN_CARDS_MINI_CARDS_BUTTON_BACKGROUNDCOLOR:
      newState.main.cards.mini_cards.button.backgroundColor = action.color;
      return newState;
    case SKIN_MAIN_CARDS_MINI_CARDS_BUTTON_COLOR:
      newState.main.cards.mini_cards.button.color = action.color;
      return newState;

    default:
      return state;
  }
};
