import axios, { AxiosRequestConfig } from 'axios';
import { auth } from '../utils/firebase';

export const baseURL =
  process.env.REACT_APP_ENVIRONMENT === 'DEBUG' && process.env.NODE_ENV === 'development'
    ? `http://${process.env.REACT_APP_DEBUG_HOST}:${process.env.REACT_APP_DEBUG_FUNCTIONS_PORT}/app-provedor-testing/us-central1`
    : process.env.REACT_APP_FUNCTIONS_URL;

const api = axios.create({
  baseURL,
  headers: { withCredentials: 'true' },
  validateStatus(status) {
    return status === 200;
  },
});

api.interceptors.request.use<AxiosRequestConfig<{ headers: { authorization: string } }>>(
  config => {
    const newConfig = { ...config, headers: { ...config.headers } };
    if (auth.currentUser)
      return auth.currentUser.getIdToken().then(token => {
        newConfig.headers.authorization = `Bearer ${token}`;
        return newConfig;
      });
    return newConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;
