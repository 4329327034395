import { getValue } from 'utils/dotNotation';
import { SvgFill } from './style';
import { ISkinProps } from './types';

const Contact: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  return (
    <SvgFill
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M13 0C5.8318 0 0 5.83187 0 13.0001C0 20.1683 5.8318 26 13 26C20.1682 26 26 20.1683 26 13.0001C26 5.83187 20.1682 0 13 0V0ZM13 23.6364C7.13502 23.6364 2.36364 18.865 2.36364 13.0001C2.36364 7.1352 7.13504 2.36364 13 2.36364C18.865 2.36364 23.6364 7.1352 23.6364 13.0001C23.6364 18.865 18.8649 23.6364 13 23.6364V23.6364Z"
        fill="black"
      />
      <path
        d="M12.9997 5.51514C12.131 5.51514 11.4243 6.22234 11.4243 7.09162C11.4243 7.96011 12.131 8.66668 12.9997 8.66668C13.8685 8.66668 14.5752 7.96011 14.5752 7.09162C14.5752 6.22234 13.8685 5.51514 12.9997 5.51514Z"
        fill="black"
      />
      <path
        d="M12.9999 11.0303C12.3472 11.0303 11.8181 11.5594 11.8181 12.2121V19.303C11.8181 19.9557 12.3473 20.4848 12.9999 20.4848C13.6526 20.4848 14.1817 19.9557 14.1817 19.303V12.2121C14.1817 11.5594 13.6526 11.0303 12.9999 11.0303V11.0303Z"
        fill="black"
      />
    </SvgFill>
  );
};

export default Contact;
