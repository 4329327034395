import { useSelector } from 'react-redux';
import { IStore } from 'store/types';
import styled from 'styled-components';
import { getValue } from 'utils/dotNotation';

interface ISvg {
  selector: string;
}

export const SvgFillBase = styled.svg<ISvg>`
  fill: ${props => props.color} !important;
  path {
    fill: inherit !important;
  }
`;

export const SvgFill = styled(SvgFillBase).attrs<ISvg>(props => {
  const fill = useSelector((state: IStore) => getValue(state, props.selector));

  return {
    style: {
      fill,
    },
  };
})``;

export const SvgStrokeBase = styled.svg<ISvg>`
  stroke: ${props => props.color} !important;
  path {
    stroke: inherit !important;
  }
`;

export const SvgStroke = styled(SvgStrokeBase).attrs<ISvg>(props => {
  const stroke = useSelector((state: IStore) => getValue(state, props.selector));

  return {
    style: {
      stroke,
    },
  };
})``;
