import { IAlert } from 'hooks/dashboard';
import { ISkin } from 'hooks/skin/types';
import * as constants from './contants';

export const actions = {
  setColors: (
    skin?: ISkin,
  ): {
    type: string;
    skin: ISkin | undefined;
  } => ({ type: constants.CHANGE_ALL, skin }),
  setSkinTitle: (
    title: string,
  ): {
    type: string;
    title: string;
  } => ({ type: constants.SET_SKIN_TITLE, title }),
  setAlert: (alert: IAlert): { type: string; alert: IAlert } => ({ type: constants.SET_ALERT, alert }),
};
