import { FC } from 'react';
import { ReactComponent as Question } from 'assets/question.svg';
import { SendSupportMessageButton } from './style';
import { Props } from './types';
import useButtonLogic from './logic';

const Button: FC<Props> = ({ onClick }: Props) => {
  const { allUnreadMessages } = useButtonLogic();

  return (
    <SendSupportMessageButton onClick={onClick}>
      <Question />
      <p>Suporte</p>
      {allUnreadMessages > 0 && allUnreadMessages < 99 && (
        <span className={`notification ${allUnreadMessages < 9 ? '' : 'bigger'}`}>
          {allUnreadMessages < 99 ? allUnreadMessages : 99}
        </span>
      )}
    </SendSupportMessageButton>
  );
};

export default Button;
