import styled, { css } from 'styled-components';

export const FooterLoginWrapper = styled.div`
  visibility: visible;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  user-select: none;
  margin-top: 20px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  visibility: visible;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  user-select: none;

  > .footer-login-text-wrapper {
    font-size: 11px;
    width: 100px;
    text-align: right;
  }
  > .footer-login-codize-wrapper {
    width: 100px;
    display: flex;
    justify-content: center;

    > a img {
      width: 80px;
      margin-left: 10px;
      margin-right: 20px;
    }
  }
`;

export const IndicationCode = styled.p`
  margin-top: -100px;
  background-color: #1b171a2e;
  color: #dedede;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 18px !important;
  border: 1px dashed #ffffff70;
`;
interface FormContainerProps {
  isLoginErrored: boolean;
}

export const Container = styled.div`
  height: 100%;
  min-width: 1024px;
  min-height: 650px;
  display: flex;
  align-items: center;
  align-content: center;
`;

export const FormContainer = styled.div<FormContainerProps>`
  margin: auto;
  margin-right: 20px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  border-radius: 5px;

  ${props =>
    props.isLoginErrored &&
    css`
      form label input {
        background-color: #f8d7da;
        box-shadow: 0 0 0 0.2rem #fcbaba;
      }
    `}
`;

export const Form = styled.form`
  align-items: center;
  background-color: #fff;
  border: none;

  border-top-left-radius: 4px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 4px;
  box-shadow: 4px 4px 12px 1px rgb(0 0 0 / 20%);

  box-sizing: border-box;
  margin: 0 0 0;
  padding: 20px 0 28px;
  width: 400px;
  min-width: 400px;
  max-height: 512px;
  margin-left: auto;
  margin-right: auto;

  > p {
    text-align: center;
    font-size: 24px !important;
    padding: 0 16px;
    color: #222;
    width: 100%;
    font-family: Roboto;
    font-weight: 500;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  div {
    width: 90%;
    height: 60px;
    position: relative;

    > svg {
      position: absolute;
      top: 20px;
      left: 15px;
    }

    > p {
      color: #666;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
`;

interface InputProps {
  type: string;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  height: 60px;
  padding-left: 50px;
  border-radius: 10px;
  border: none;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 10%);
  font-size: 18px;
  outline: none;
  color: #444;
  transition: 0.2s;

  :focus {
    box-shadow: 0 0 0 2pt #b976ac;
  }

  ${props =>
    props.type === 'submit' &&
    css`
      background-color: #602f58;
      width: 50%;
    `}
`;

export const Button = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;

  button {
    cursor: pointer;
    width: 100%;
    height: 60px;
    cursor: pointer;
    border: none;
    font-weight: bold;
    background-image: linear-gradient(to right, #6b2f61 0%, #a53e93 51%, #56204d 100%);
    margin-top: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;

    display: flex;
    align-items: center;

    div {
      align-items: center;
      margin: auto;
      display: flex;

      > svg {
        margin-right: 8px;
        height: 22px;
        width: 22px;
      }
      > p {
        font-size: 14px !important;
      }
    }

    :hover {
      background-position: right center;
      color: #fff;
      text-decoration: none;
    }

    :active,
    :focus {
      box-shadow: 0 0 0 0.2rem #e8d3e4;
      transition: box-shadow 0.15s ease;
    }
  }
`;

export const GoogleButton = styled.button`
  text-transform: uppercase;

  height: 60px;
  display: flex;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  /* background-color: #efefef; */
  border: 1px solid #ededed;
  margin-right: auto;
  margin-left: auto;
  width: 360px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #f1f1f1 0%, #e6e6e6 51%, #d6d6d6 100%);
  transition: 0.5s;
  background-size: 200% auto;

  > div {
    display: flex;
    margin: auto;
  }

  :hover {
    transition: background 0.4s ease;
    /* background-color: #efefef; */
    background-position: right center;
    /* color: #fff;
    text-decoration: none; */
  }

  :active,
  :focus {
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.15s ease;
  }

  svg {
    margin-right: 8px;
    height: 20px;
  }

  h3 {
    font-weight: bold;
    color: #444;
    margin-top: 1.5px;
    font-size: 14px;
  }
`;

export const Others = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;

  hr {
    margin: auto;
    margin-left: 15px;
    margin-right: 15px;
    flex: 1;
    height: 1px;

    background-color: #ccc;
    height: 1px;
    border: 0;
  }

  > p {
    color: #666;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
  }
`;

export const AboutTheApp = styled.div`
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));

  img {
    height: 100%;
    width: 100%;
    max-height: 342px;
    width: auto;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  > h2 {
    color: #fff;
    font-size: 30px !important;
  }
`;
