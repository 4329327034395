import { ContainerDefault } from 'components/Skin/style';
import { useSelector } from 'react-redux';
import { IStore } from 'store/types';
import styled from 'styled-components';

interface ContainerProps {
  isShown: boolean;
}

export const ContainerBase = styled(ContainerDefault)<ContainerProps>``;

export const Container = styled(ContainerBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const CardButtonContainer = styled.div<ContainerProps>`
  display: ${props => (props.isShown ? 'flex' : 'none')};
  button {
    display: flex;
    padding: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 10px;

    > div {
      display: flex;
      align-items: center;
      margin: auto;

      > p {
        margin-left: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11.5px;
      }
      > svg {
        width: 30px;
        height: 30px;
        padding-top: 5px;
      }
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  background-color: ${props => props.color};
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const ContactList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  div {
    flex: 1;
    margin: 4px;
    justify-content: center;
    align-items: center;

    div {
      margin: auto;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background-color: #f3f3f3;

      svg {
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 4px;
      }
    }

    p {
      font-family: Roboto;
      font-size: 11px;
      font-weight: bold;
      color: white;
      overflow: hidden;
      text-transform: capitalize;
      color: #666;
      text-align: center;
      max-width: 90px;
      margin: auto;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
