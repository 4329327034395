/* eslint-disable camelcase */
import { createContext, useContext } from 'react';
import useProviderLogic from './logic';

import { Props, ProviderContextData } from './types';

const ProviderContext = createContext<ProviderContextData>({} as ProviderContextData);

const ProviderProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    isLoadingProvider,
    setIsLoadingProvider,
    provider,
    setTemporaryAttr,
    handleProviderSubscription,
    cleanSessionProvider,
    messagesQuery,
    isLoadingMessagesQuery,
    requestMessages,
    setMessagesQuery,
    isAccountNotPopulated,
  } = useProviderLogic();

  return (
    <ProviderContext.Provider
      value={{
        isLoadingProvider,
        setIsLoadingProvider,
        provider,
        setTemporaryAttr,
        handleProviderSubscription,
        cleanSessionProvider,
        messagesQuery,
        isLoadingMessagesQuery,
        requestMessages,
        setMessagesQuery,
        isAccountNotPopulated,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

function useProvider(): ProviderContextData {
  const context = useContext(ProviderContext);

  if (!context) {
    throw new Error('useProvider must be used within an ProviderProvider');
  }

  return context;
}

export { ProviderProvider, useProvider };
