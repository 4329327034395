import Overlay from 'components/Overlay';
import { useProvider } from 'hooks/provider';
import { Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ component: Component, ...rest }: RouteProps) => {
  const { loadingUser, user } = useAuth();
  const { isLoadingProvider } = useProvider();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (loadingUser || isLoadingProvider) {
          return <Overlay isLoading />;
        }

        if (!user?.uid) {
          if (location.pathname === '/') {
            return <Component />;
          }
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        if (location.pathname === '/dashboard') {
          return <Component />;
        }
        return (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
