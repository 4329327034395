import { FC, useEffect } from 'react';
import IndicationMenuItem from 'components/IndicationModal/components/IndicationMenuItem';
import { useAuth } from 'hooks/auth';
import { useTicket } from 'components/SendSupportMessageButton/hooks/Ticket';
import { useModal } from 'components/IndicationModal/hooks/Modal';
import SendMessageItem from '../SendMessageItem';
import SendReportItem from '../SendReportItem';
import { ItemBody, Loader } from '../SendMessageItem/style';

const Menu: FC = () => {
  const { account } = useAuth();
  const { tickets, loadingTickets } = useTicket();
  const { closeTab } = useModal();

  useEffect(() => {
    if (tickets && tickets.docs.length > 0) {
      closeTab(0, true, true);
    } else {
      closeTab(2, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets]);

  return (
    <>
      <div className="menu">
        {loadingTickets && (
          <ItemBody>
            <Loader>
              <span />
            </Loader>
          </ItemBody>
        )}
        {!loadingTickets && (
          <>
            <IndicationMenuItem title="Tickets abertos" index={0} hideOnOpen={2}>
              <SendReportItem showOpen />
            </IndicationMenuItem>
            <IndicationMenuItem title="Tickets fechados" index={1} hideOnOpen={2}>
              <SendReportItem showOpen={false} />
            </IndicationMenuItem>
            {!account?.isSupport && (
              <IndicationMenuItem title="Abrir novo ticket" index={2} isComeBackShown highlighted>
                <SendMessageItem />
              </IndicationMenuItem>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Menu;
