import { useCallback, useRef } from 'react';

import { useProvider } from 'hooks/provider';
import { useAuth } from 'hooks/auth';
import {
  FormContainer,
  Form,
  Label,
  Input,
  Button,
  GoogleButton,
  Container,
  Others,
  AboutTheApp,
  IndicationCode,
  FooterLoginWrapper,
} from './style';
import firebase, { auth } from '../../utils/firebase';
import { ReactComponent as GoogleIcon } from '../../assets/googleIcon.svg';
import { useDashboard } from '../../hooks/dashboard';

import appProviderLogo from '../../assets/appProviderLogo.png';

const Login: React.FC = () => {
  const { isLoginErrored, setIsLoginErrored, indicationCode } = useAuth();
  const { Alert, setIsLoading } = useDashboard();
  const { setIsLoadingProvider } = useProvider();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsLoading(true);
      setIsLoadingProvider(true);

      const FormLogin = new FormData(formRef.current || undefined);

      const email = (FormLogin.get('email') || '').toString();
      const password = (FormLogin.get('password') || '').toString();

      auth
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => auth.signInWithEmailAndPassword(email, password))
        .catch((err: firebase.auth.AuthError) => {
          switch (err.code) {
            case 'auth/user-disabled':
              Alert({
                message: 'Usuário desativado!',
                type: 'warning',
              });
              break;
            case 'auth/invalid-email':
              Alert({
                message: 'E-mail inválido',
                type: 'error',
              });
              break;
            case 'auth/wrong-password':
              Alert({
                message: 'Senha incorreta!',
                type: 'error',
              });
              break;
            case 'auth/too-many-requests':
              Alert({
                message: 'O login para esta conta foi desativado temporariamente por causa das tentativas repetitivas',
                type: 'warning',
              });
              break;
            case 'auth/user-not-found':
              Alert({
                message: 'Usuário não encontrado!',
                type: 'error',
              });
              break;
            default:
              Alert({
                message: err.message,
                type: 'error',
              });
              break;
          }

          setIsLoginErrored(true);
          setIsLoading(false);
          setIsLoadingProvider(false);
        });
    },
    [Alert, setIsLoading, setIsLoadingProvider, setIsLoginErrored],
  );

  return (
    <Container>
      <FormContainer isLoginErrored={isLoginErrored} onSubmit={handleSubmit}>
        <AboutTheApp>
          <img src={appProviderLogo} alt="" />
        </AboutTheApp>

        <Form ref={formRef} autoComplete="off">
          {/* NEED REFACTOR */}
          {/* <IndicationCode style={{ visibility: indicationCode ? 'visible' : 'hidden' }}>
            Código de indicação: <b>{indicationCode && indicationCode}</b>
          </IndicationCode> */}
          <IndicationCode style={{ visibility: 'hidden' }}>
            Código de indicação: <b>{indicationCode && indicationCode}</b>
          </IndicationCode>
          <div style={{ marginTop: 70 }} />
          <p>Já tenho conta</p>
          <div style={{ marginTop: 20 }} />
          <Label>
            <div>
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
                <path
                  d="M18 17.136V15.456C18 14.5649 17.5786 13.7103 16.8284 13.0801C16.0783 12.45 15.0609 12.096 14 12.096H6C4.93913 12.096 3.92172 12.45 3.17157 13.0801C2.42143 13.7103 2 14.5649 2 15.456V17.136"
                  stroke="#666360"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 9.072C12.2091 9.072 14 7.26681 14 5.04C14 2.81318 12.2091 1.008 10 1.008C7.79086 1.008 6 2.81318 6 5.04C6 7.26681 7.79086 9.072 10 9.072Z"
                  stroke="#666360"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Input name="email" type="text" placeholder="E-mail" autoComplete="off" />
            </div>
          </Label>
          <Label>
            <div>
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.4444 8.10001H4.55556C3.69645 8.10001 3 8.83263 3 9.73637V15.4636C3 16.3674 3.69645 17.1 4.55556 17.1H15.4444C16.3036 17.1 17 16.3674 17 15.4636V9.73637C17 8.83263 16.3036 8.10001 15.4444 8.10001Z"
                  stroke="#666360"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 8.09999V4.89999C6 3.83913 6.42143 2.82171 7.17157 2.07157C7.92172 1.32142 8.93913 0.899994 10 0.899994C11.0609 0.899994 12.0783 1.32142 12.8284 2.07157C13.5786 2.82171 14 3.83913 14 4.89999V8.09999"
                  stroke="#666360"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Input name="password" type="password" placeholder="Senha" />
            </div>
          </Label>
          <Button>
            <button type="submit">
              <div>
                <svg width="336" height="218" viewBox="0 0 336 218" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.217041 9.48438V208.306L97.175 108.493L0.217041 9.48438Z" fill="white" />
                  <path d="M335.783 9.48438V208.306L239.307 108.895L335.783 9.48438Z" fill="white" />
                  <path
                    d="M7.55811 0.46582H327.813L201.948 131.524C192.99 140.851 180.617 146.123 167.686 146.123C154.755 146.123 142.382 140.851 133.424 131.524L7.55811 0.46582Z"
                    fill="white"
                  />
                  <path
                    d="M209.209 138.496C198.41 149.74 183.276 156.19 167.686 156.19C152.097 156.19 136.962 149.742 126.164 138.497L104.815 116.267L7.55811 217.534H327.813L230.557 116.267L209.209 138.496Z"
                    fill="white"
                  />
                </svg>
                <p>Entrar com e-mail</p>
              </div>
            </button>
          </Button>
          <GoogleButton
            type="button"
            onClick={() => {
              setIsLoadingProvider(true);
              setIsLoading(true);
              const provider = new firebase.auth.GoogleAuthProvider();
              provider.setCustomParameters({
                prompt: 'select_account',
              });
              auth.signInWithPopup(provider).catch(err => {
                switch (err.code) {
                  case 'auth/popup-closed-by-user':
                    break;
                  default:
                    Alert({
                      message: 'O popup de autenticação foi fechado pelo usuário',
                      type: 'error',
                    });
                    break;
                }
                setIsLoading(false);
                setIsLoadingProvider(false);
              });
            }}
          >
            <div>
              <GoogleIcon />
              <h3>Entrar com Google</h3>
            </div>
          </GoogleButton>
          <div style={{ marginTop: 20 }} />
          <Others>
            <hr />
            <p>ou</p>
            <hr />
          </Others>
          <div style={{ marginTop: 10 }} />
          <GoogleButton
            type="button"
            onClick={() => {
              setIsLoadingProvider(true);
              setIsLoading(true);
              const provider = new firebase.auth.GoogleAuthProvider();
              provider.setCustomParameters({
                prompt: 'select_account',
              });
              auth.signInWithPopup(provider).catch(err => {
                switch (err.code) {
                  case 'auth/popup-closed-by-user':
                    break;
                  default:
                    Alert({
                      message: 'O popup de autenticação foi fechado pelo usuário',
                      type: 'error',
                    });
                    break;
                }
                setIsLoading(false);
                setIsLoadingProvider(false);
              });
            }}
          >
            <div>
              <GoogleIcon />
              <h3>Criar conta com Google</h3>
            </div>
          </GoogleButton>

          <FooterLoginWrapper>
            <div className="footer-login-text-wrapper">Powered by</div>
            <div className="footer-login-codize-wrapper">
              <a href="https://www.codize.com.br" target="_blank" rel="noreferrer">
                <img alt="Logo Codize" src="./assets/images/logo-codize.png" />
              </a>
            </div>
          </FooterLoginWrapper>

          <div style={{ marginTop: 50 }} />
          <hr style={{ border: 'solid 1px #6d3664', visibility: 'hidden' }} />
          <div style={{ marginBottom: 18 }} />
        </Form>
      </FormContainer>
    </Container>
  );
};

export default Login;
