import { getValue } from 'utils/dotNotation';
import { SvgFillBase, SvgFill } from './style';
import { ISkinProps } from './types';

const Bill: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  let RealSvgFill = SvgFillBase;

  if (!skin) {
    RealSvgFill = SvgFill;
  }

  return (
    <>
      <RealSvgFill
        width="39"
        height="26"
        viewBox="0 0 39 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        selector={selector}
        color={getValue({ skin }, selector)}
        // style={{ height: '18px' }}
      >
        <path
          d="M0.279955 5C0.205706 5 0.134499 4.9705 0.081997 4.91798C0.0294953 4.86546 0 4.79422 0 4.71995V0.280049C0 0.205776 0.0294953 0.134544 0.081997 0.0820249C0.134499 0.0295054 0.205706 0 0.279955 0H4.72004C4.79429 0 4.8655 0.0295054 4.918 0.0820249C4.97051 0.134544 5 0.205776 5 0.280049C5 0.354323 4.97051 0.425555 4.918 0.478074C4.8655 0.530594 4.79429 0.560099 4.72004 0.560099H0.55991V4.71995C0.55991 4.79422 0.530415 4.86546 0.477913 4.91798C0.425412 4.9705 0.354204 5 0.279955 5V5Z"
          fill="black"
        />
        <path
          d="M38.72 5C38.6457 5 38.5744 4.9705 38.5219 4.91798C38.4694 4.86546 38.4399 4.79422 38.4399 4.71995V0.560099H34.28C34.2058 0.560099 34.1345 0.530594 34.082 0.478074C34.0295 0.425555 34 0.354323 34 0.280049C34 0.205776 34.0295 0.134544 34.082 0.0820249C34.1345 0.0295054 34.2058 0 34.28 0H38.72C38.7942 0 38.8655 0.0295054 38.918 0.0820249C38.9705 0.134544 39 0.205776 39 0.280049V4.71995C39 4.79422 38.9705 4.86546 38.918 4.91798C38.8655 4.9705 38.7942 5 38.72 5Z"
          fill="black"
        />
        <path
          d="M4.72004 26H0.279955C0.205706 26 0.134499 25.9705 0.081997 25.918C0.0294953 25.8655 0 25.7942 0 25.72V21.28C0 21.2058 0.0294953 21.1345 0.081997 21.082C0.134499 21.0295 0.205706 21 0.279955 21C0.354204 21 0.425412 21.0295 0.477913 21.082C0.530415 21.1345 0.55991 21.2058 0.55991 21.28V25.4399H4.72004C4.79429 25.4399 4.8655 25.4694 4.918 25.5219C4.97051 25.5744 5 25.6457 5 25.72C5 25.7942 4.97051 25.8655 4.918 25.918C4.8655 25.9705 4.79429 26 4.72004 26Z"
          fill="black"
        />
        <path
          d="M38.72 26H34.28C34.2058 26 34.1345 25.9705 34.082 25.918C34.0295 25.8655 34 25.7942 34 25.72C34 25.6457 34.0295 25.5744 34.082 25.5219C34.1345 25.4694 34.2058 25.4399 34.28 25.4399H38.4399V21.28C38.4399 21.2058 38.4694 21.1345 38.5219 21.082C38.5744 21.0295 38.6457 21 38.72 21C38.7942 21 38.8655 21.0295 38.918 21.082C38.9705 21.1345 39 21.2058 39 21.28V25.72C39 25.7942 38.9705 25.8655 38.918 25.918C38.8655 25.9705 38.7942 26 38.72 26Z"
          fill="black"
        />
        <path d="M9 3H10V23H9V3Z" fill="black" />
        <path d="M7 3H8V23H7V3Z" fill="black" />
        <path d="M18 3H19V23H18V3Z" fill="black" />
        <path d="M14 3H15V23H14V3Z" fill="black" />
        <path d="M16 3H17V23H16V3Z" fill="black" />
        <path d="M4 3H6V23H4V3Z" fill="black" />
        <path d="M11 3H13V23H11V3Z" fill="black" />
        <path d="M31 23H30V3H31V23Z" fill="black" />
        <path d="M33 23H32V3H33V23Z" fill="black" />
        <path d="M26 23H25V3H26V23Z" fill="black" />
        <path d="M22 23H21V3H22V23Z" fill="black" />
        <path d="M24 23H23V3H24V23Z" fill="black" />
        <path d="M36 23H34V3H36V23Z" fill="black" />
        <path d="M28 23H27V3H28V23Z" fill="black" />
        <path d="M39 12V14H3.40186e-07V12H39Z" fill="black" />
      </RealSvgFill>
    </>
  );
};

export default Bill;
