import { createGlobalStyle } from 'styled-components';
import bgLowOpacity from '../assets/bgLowOpacity.png';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  .container-swal-wide > div {
    min-width: 310px;
    width: 1050px;
    height: 300px;

    @media (min-height: 310px) {
      height: 300px;
    }
    @media (min-height: 410px) {
      height: 400px;
    }
    @media (min-height: 510px) {
      height: 500px;
    }
    @media (min-height: 610px) {
      height: 600px;
    }
    @media (min-height: 710px) {
      height: 700px;
    }
  }

  .html-container-swal-wide {
    border-radius: 5px;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 5px;
    width: 100%;
    height: 200px;

    @media (min-height: 310px) {
      height: 200px;
    }
    @media (min-height: 410px) {
      height: 300px;
    }
    @media (min-height: 510px) {
      height: 400px;
      iframe {
        height: 70.5%;
      }
    }
    @media (min-height: 610px) {
      height: 500px;
    }
    @media (min-height: 710px) {
      height: 600px;
    }

    > iframe {
      display: none;
      font-size: 10px;
      font-family: Arial, Helvetica, sans-serif;
      pointer-events: auto;
      width: 100%;
      height: 73%;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: transparent;

      @media (min-height: 310px) {
        height: 73%;
      }
      @media (min-height: 410px) {
        height: 81.5%;
      }
      @media (min-height: 510px) {
        height: 86.5%;
      }
      @media (min-height: 610px) {
        height: 88.5%;
      }

      &#web-terms-loader {
        display: block;
      }
      /* background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23FF0000" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">PLACEHOLDER</text></svg>') 0px 0px no-repeat; */
    }
  }

  .actions-swal-wide {
    margin-top: 0px;
    max-height: 47px;
  }

  :root {
      background-color:#F0F3F8;

      p {
        font-size: 11px;
      }

      span {
        font-size: 14px;
      }

      i {
        font-size: 12px;
      }

      h4 {
        font-size: 14px;
      }

      @media (min-width: 1280px) {
        p {
          font-size: 12px;
        }

        span {
          font-size: 15px;
        }

        i {
          font-size: 13px;
        }

        h4 {
          font-size: 15px;
        }
    }

  @media (min-width: 1920px) {
        p {
          font-size: 13px;
        }

        span {
          font-size: 16px;
        }

        i {
          font-size: 14px;
        }

        h4 {
          font-size: 18px;
        }
    }
  }

  body {
    background: #56204d;
    background-image: url(${bgLowOpacity});
    background-repeat: repeat;

    -webkit-font-smoothing: antialiased;


  }

  body, input, button {
    font-family: 'Roboto', serif;
  }

  button {
    cursor: pointer;
  }

  .hr {
    color: #666;
    margin-bottom: 5px;

    border-bottom: 1px solid #c4c4c4;
    cursor: default;
 }
`;
