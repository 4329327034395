import format from 'date-fns/format';
import { IMessage } from 'hooks/provider/types';

interface MessageDTO {
  id: string;
  targets: Array<string>;
  content: string;
  date: Date;
}

const formatTarget = (target: string) => {
  switch (target) {
    case 'all':
      return 'Enviado a todos os usuários do app';
    default:
      return 'Tipo de alvo não configurado';
  }
};

export const formatMessage = (messageData: MessageDTO): IMessage => {
  return {
    id: messageData.id,
    targets: messageData.targets?.map(formatTarget).join(', '),
    content: messageData.content,
    date: format(messageData.date, 'dd/MM/yyyy, p'),
  };
};
