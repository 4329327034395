import { useAuth } from 'hooks/auth';
import { ReactComponent as User } from 'assets/user.svg';
import { SupportChangeAccountButton } from './style';

const SupportButton: React.FC = () => {
  const { handleClickSupportChangeAccount } = useAuth();

  return (
    <SupportChangeAccountButton type="button" onClick={handleClickSupportChangeAccount}>
      <User />
      <p>Trocar de conta</p>
    </SupportChangeAccountButton>
  );
};

export default SupportButton;
