/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useState } from 'react';
import { ILogicReturn } from './types';

const ModalLogic = (): ILogicReturn => {
  const [isModalShown, setIsModalShown] = useState(false);

  const handleClickModalButton = () => {
    setIsModalShown(oldIsModalShown => !oldIsModalShown);
  };

  const handleCloseModalButton = () => {
    setIsModalShown(false);
  };

  return { handleClickModalButton, isModalShown, handleCloseModalButton };
};

export default ModalLogic;
