import { ContainerDefault } from 'components/Skin/style';
import { useSelector } from 'react-redux';
import { IStore } from 'store/types';
import styled from 'styled-components';

interface ContainerProps {
  isShown: boolean;
}

export const ContainerBase = styled(ContainerDefault)<ContainerProps>`
  > p {
    margin-top: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
  }

  > button {
    padding: 0px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    > div {
      display: flex;
      align-items: center;
      align-self: center;
      align-content: center;
      margin: auto;

      > p {
        margin-left: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11.5px;
      }
      svg {
        width: 30px;
        height: 30px;
        padding-top: 5px;
      }
    }
  }
`;

export const Container = styled(ContainerBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const MiniCardTextBase = styled.p`
  color: ${props => props.color};
`;

export const MiniCardText = styled(MiniCardTextBase).attrs(() => {
  const color = useSelector((state: IStore) => state.skin.main.cards.mini_cards.color);

  return {
    style: {
      color,
    },
  };
})``;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  background-color: ${props => props.color};
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const MiniCardBase = styled.div`
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
  background-color: ${props => props.color};
  align-content: flex-start;

  > p {
    text-align: initial;
    font-size: 10px;
  }

  > div > div {
    display: flex;
    margin-bottom: 6px;
    align-items: center;

    > p {
      font-size: 11px;
      max-width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  > div > .info {
    margin-left: 15px;
  }

  > .options {
    display: flex;
    margin-bottom: 0px;
    p {
      flex: 1;
      align-self: center;
      margin: auto;
    }
    > div {
      margin-bottom: 0px;
      display: block;
      > div {
        > div {
          width: 40px;
          margin: auto;
          > img {
            border-radius: 20px;
            border: white solid 3px;
            max-width: 38px;
            height: 38px;
          }
        }
      }
    }
  }
`;

export const MiniCard = styled(MiniCardBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.mini_cards.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;
