/* eslint-disable no-case-declarations */
import { useModal } from 'components/IndicationModal/hooks/Modal';
import { useTicket } from 'components/SendSupportMessageButton/hooks/Ticket';
import { useAuth } from 'hooks/auth';
import { useProvider } from 'hooks/provider';
import { useEffect, useState } from 'react';
import firebase, { db } from 'utils/firebase';
import { MySwal } from 'utils/MySwal';
import { ILogicReturn } from './types';

export const validator = (elements: HTMLInputElement[]): boolean =>
  elements
    .map(element => {
      switch (element.type) {
        case 'text':
        case 'textarea':
          const isTextValid = element?.required ? !!element.value : true;
          !isTextValid && element.classList.add('errored');
          return isTextValid;
        case 'file':
          const isFileValid = element?.required ? element?.files?.length && element?.files?.length > 0 : true;
          !isFileValid && element.classList.add('errored');
          return isFileValid;
        default:
          return true;
      }
    })
    .reduce((pV, cV) => pV && !!cV, true) || false;

export const getData = (
  elements: HTMLInputElement[],
): {
  [key: string]: string | FileList | null;
} =>
  elements
    .map(element => {
      switch (element.type) {
        case 'text':
        case 'textarea':
          return { [element.name]: element?.value };
        case 'file':
          return { [element.name]: element?.files };
        default:
          return undefined;
      }
    })
    .reduce((pV, cV) => Object.assign(pV, cV), {}) || {};

const useLogic = (): ILogicReturn => {
  const { setIsComeBackShown } = useModal();
  const { account, user } = useAuth();
  const { provider } = useProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const { tickets } = useTicket();

  useEffect(() => {
    isFormSent && tickets && tickets.docs.length > 0 && setIsComeBackShown(true);
  }, [isFormSent, setIsComeBackShown, tickets]);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const elements = Array.from(e.currentTarget.elements) as Array<HTMLInputElement>;

    const isValid = validator(elements);

    if (isValid) {
      const data = getData(elements);

      if (data) {
        const { name, description } = data;

        const ticket = db.collection('tickets').doc();
        const message = ticket.collection('messages').doc();
        const batch = db.batch();

        batch.set(ticket, {
          ticket_id: ticket.id,
          status: 'open',
          name,
          files: [],
          opening_date: firebase.firestore.FieldValue.serverTimestamp(),
          metadata: {
            cnpj: provider?.company_doc || null,
            email: user?.email || null,
            provider: (account?.provider && db.doc(account?.provider)) || null,
            account: (user?.uid && db.collection('accounts').doc(user?.uid)) || null,
          },
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          provider_unread_messages: 1,
          support_unread_messages: 0,
        });

        batch.set(message, {
          message: description,
          sent_at: firebase.firestore.FieldValue.serverTimestamp(),
          unread: true,
        });

        setIsLoading(true);
        batch
          .commit()
          .then(() => {
            setIsFormSent(true);
          })
          .catch(() =>
            MySwal({
              showCancelButton: false,
              confirmButtonText: 'Entendi',
              title: '<strong>Aviso</strong>',
              icon: 'error',
              html: 'Algo deu errado ao criar sua mensagem. Tente novamente mais tarde',
            }),
          )
          .finally(() => setIsLoading(false));
      }
    }
  };

  return { handleOnSubmit, isFormSent, isLoading };
};

export default useLogic;
