/* eslint-disable camelcase */

import { ISkin } from 'hooks/skin/types';
import { Container, ContainerBase, Title } from './style';
import { SpeedTestIcon, TapIcon } from '../../Icon';
import {
  SubTitle,
  SubTitleBase,
  TitleText,
  TitleTextBase,
  CardButtonBase,
  CardButton,
  ButtonTextBase,
  ButtonText,
} from '../../style';

interface Props {
  skin?: ISkin;
  isShown: boolean;
}

const SpeedTest: React.FC<Props> = ({ skin, isShown }: Props) => {
  let RealContainer;
  let RealTitleText;
  let RealSubTitle;
  let RealCardButton;
  let RealButtonText;

  if (skin) {
    RealContainer = ContainerBase;
    RealTitleText = TitleTextBase;
    RealSubTitle = SubTitleBase;
    RealCardButton = CardButtonBase;
    RealButtonText = ButtonTextBase;
  } else {
    RealContainer = Container;
    RealTitleText = TitleText;
    RealSubTitle = SubTitle;
    RealCardButton = CardButton;
    RealButtonText = ButtonText;
  }

  return (
    <RealContainer
      isShown={isShown}
      className="module-container speed_test_is_on"
      color={skin?.main?.cards?.backgroundColor}
    >
      <Title>
        <SpeedTestIcon selector="skin.main.cards.color" skin={skin} />
        <RealTitleText color={skin?.main?.cards?.color}>Teste de velocidade</RealTitleText>
      </Title>

      <RealSubTitle color={skin?.main?.cards?.color}>
        Faça o teste de velocidade para descobrir a velocidade da sua internet
      </RealSubTitle>

      <RealCardButton type="button" color={skin?.main?.cards?.button?.backgroundColor}>
        <div>
          <TapIcon selector="skin.main.cards.button.color" skin={skin} />
          <RealButtonText color={skin?.main?.cards?.button?.color}>Teste de velocidade</RealButtonText>
        </div>
      </RealCardButton>
    </RealContainer>
  );
};

export default SpeedTest;
