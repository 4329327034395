import { Container } from './style';

interface Props {
  message: string;
  borderRadiusTopLeft?: boolean;
  backgroundColor?: string;
  color?: string;
  blink?: boolean;
}

const Strip: React.FC<Props> = ({
  message,
  borderRadiusTopLeft = false,
  backgroundColor,
  color,
  blink = false,
}: Props) => {
  return (
    <Container blink={blink} borderRadiusTopLeft={borderRadiusTopLeft} backgroundColor={backgroundColor} color={color}>
      <p>{message}</p>
      <div />
    </Container>
  );
};

export default Strip;
