// SKIN
export const SET_SKIN_TITLE = 'SKIN/SET_SKIN_TITLE';
export const CHANGE_ALL = 'SKIN/skin';
// general
export const SKIN_GENERAL_BACKGROUNDCOLOR = 'SKIN/skin.general.backgroundColor';
// header
export const SKIN_MAIN_HEADER_BACKGROUNDCOLOR = 'SKIN/skin.main.header.backgroundColor';
export const SKIN_MAIN_HEADER_TITLE_COLOR = 'SKIN/skin.main.header.title.color';
// my account
export const SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_BACKGROUNDCOLOR =
  'SKIN/skin.main.header.my_account_button.backgroundColor';
export const SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_COLOR = 'SKIN/skin.main.header.my_account_button.color';
export const SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_CIRCLE_BACKGROUNDCOLOR =
  'SKIN/skin.main.header.my_account_button.circle.backgroundColor';
export const SKIN_MAIN_HEADER_MY_ACCOUNT_BUTTON_CIRCLE_COLOR = 'SKIN/skin.main.header.my_account_button.circle.color';
// cards
export const SKIN_MAIN_CARDS_BACKGROUNDCOLOR = 'SKIN/skin.main.cards.backgroundColor';
export const SKIN_MAIN_CARDS_COLOR = 'SKIN/skin.main.cards.color';
// button cards
export const SKIN_MAIN_CARDS_BUTTON_BACKGROUNDCOLOR = 'SKIN/skin.main.cards.button.backgroundColor';
export const SKIN_MAIN_CARDS_BUTTON_COLOR = 'SKIN/skin.main.cards.button.color';
// mini cards
export const SKIN_MAIN_CARDS_MINI_CARDS_BACKGROUNDCOLOR = 'SKIN/skin.main.cards.mini_cards.backgroundColor';
export const SKIN_MAIN_CARDS_MINI_CARDS_COLOR = 'SKIN/skin.main.cards.mini_cards.color';
// button mini_cards
export const SKIN_MAIN_CARDS_MINI_CARDS_BUTTON_BACKGROUNDCOLOR =
  'SKIN/skin.main.cards.mini_cards.button.backgroundColor';
export const SKIN_MAIN_CARDS_MINI_CARDS_BUTTON_COLOR = 'SKIN/skin.main.cards.mini_cards.button.color';

// ALERT
export const SET_ALERT = 'ALERT/SET_ALERT';
