import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MySwal = (options: SweetAlertOptions): Promise<SweetAlertResult<any>> => {
  const MySweetAlert = withReactContent(Swal);
  return MySweetAlert.fire({
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonColor: '#602f58',
    confirmButtonText: 'Confirmar',
    confirmButtonAriaLabel: 'Confirmar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar',
    reverseButtons: true,
    ...options,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MyLoginSwal = ({ html }: SweetAlertOptions): Promise<SweetAlertResult<any>> => {
  const MySweetAlert = withReactContent(Swal);

  return MySweetAlert.fire({
    html,
    width: 1000,
    padding: 5,
    background: 'none',
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: 'Fechar',
    cancelButtonColor: '#602f58',
  });
};
