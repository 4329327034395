import { getValue } from 'utils/dotNotation';
import { SvgFill } from './style';
import { ISkinProps } from './types';

const OpenLock: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  return (
    <SvgFill
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M30.8942 7.55293C30.6472 7.05905 30.0467 6.85881 29.5527 7.10578L25.553 9.10555C25.059 9.35253 24.8588 9.95313 25.1058 10.4471C25.3527 10.9409 25.9533 11.1412 26.4473 10.8942L30.447 8.89444C30.941 8.64747 31.1412 8.04687 30.8942 7.55293Z"
        fill="black"
      />
      <path
        d="M30.447 18.1055L26.4473 16.1057C25.9536 15.8589 25.3528 16.0589 25.1058 16.5529C24.8588 17.0468 25.059 17.6474 25.553 17.8944L29.5527 19.8942C30.0467 20.1412 30.6473 19.9409 30.8942 19.4471C31.1412 18.9531 30.941 18.3525 30.447 18.1055Z"
        fill="black"
      />
      <path
        d="M21.2378 14.5918L7.36571 14.5918L7.36571 9.99021C7.36571 7.48629 9.45844 5.44918 12.0307 5.44918C14.5691 5.44918 16.6343 7.48628 16.6343 9.99021L16.6343 11.8066C16.6343 12.3082 17.0465 12.7148 17.555 12.7148L21.2379 12.7148C21.7464 12.7148 22.1586 12.3082 22.1586 11.8066L22.1586 9.99021C22.1586 4.48163 17.6153 -3.07996e-06 12.0308 -2.10352e-06C6.41233 5.94259e-05 1.84144 4.48164 1.84144 9.99027L1.84144 14.7481C0.769787 15.1229 2.82068e-06 16.1324 3.0277e-06 17.3164L4.94382e-06 28.2754C5.2065e-06 29.7777 1.23911 31 2.76217 31L21.2378 31C22.7609 31 24 29.7777 24 28.2754L24 17.3164C24 15.8141 22.7609 14.5918 21.2378 14.5918ZM3.68289 9.99027C3.68288 5.48321 7.42776 1.81647 12.0307 1.81647C16.5999 1.81647 20.3171 5.48326 20.3171 9.99027L20.3171 10.8985L18.4757 10.8985L18.4757 9.99027C18.4757 6.48477 15.5844 3.63288 12.0307 3.63289C8.4664 3.63289 5.52433 6.48587 5.52433 9.99027L5.52433 14.5919L3.68289 14.5919L3.68289 9.99027ZM22.1586 28.2754C22.1586 28.7762 21.7455 29.1836 21.2378 29.1836L2.76217 29.1836C2.25448 29.1836 1.84145 28.7762 1.84145 28.2754L1.84144 17.3164C1.84144 16.8156 2.25448 16.4082 2.76217 16.4082C3.62077 16.4082 20.4942 16.4082 21.2378 16.4082C21.7455 16.4082 22.1586 16.8156 22.1586 17.3164L22.1586 28.2754Z"
        fill="black"
      />
      <path
        d="M30 13L26 13C25.4477 13 25 13.4477 25 14C25 14.5523 25.4477 15 26 15L30 15C30.5523 15 31 14.5523 31 14C31 13.4477 30.5523 13 30 13Z"
        fill="black"
      />
      <path
        d="M12 18C10.3458 18 9 19.2032 9 20.6821C9 21.8476 9.83607 22.8414 11 23.2105L11 26.106C11 26.5997 11.4477 27 12 27C12.5523 27 13 26.5997 13 26.106L13 23.2105C14.1639 22.8414 15 21.8476 15 20.6821C15 19.2032 13.6542 18 12 18ZM12 21.5761C11.4486 21.5761 11 21.175 11 20.6821C11 20.1891 11.4486 19.788 12 19.788C12.5514 19.788 13 20.1891 13 20.6821C13 21.175 12.5514 21.5761 12 21.5761Z"
        fill="black"
      />
    </SvgFill>
  );
};

export default OpenLock;
