/* eslint-disable camelcase */
import { createContext, useContext } from 'react';

import useSkinLogic from './logic';
import { Props, SkinContextData } from './types';

const SkinContext = createContext<SkinContextData>({} as SkinContextData);

const SkinProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    temporarySkin,
    setTemporarySkin,
    requestThemes,
    skinThemes,
    isLoadingThemes,
    currentSelected,
    setCurrentSelected,
    isSendButtonShown,
    setIsSendButtonShown,
  } = useSkinLogic();

  return (
    <SkinContext.Provider
      value={{
        temporarySkin,
        setTemporarySkin,
        requestThemes,
        skinThemes,
        isLoadingThemes,
        currentSelected,
        setCurrentSelected,
        isSendButtonShown,
        setIsSendButtonShown,
      }}
    >
      {children}
    </SkinContext.Provider>
  );
};

function useSkin(): SkinContextData {
  const context = useContext(SkinContext);

  if (!context) {
    throw new Error('useSkin must be used within an SkinProvider');
  }

  return context;
}

export { SkinProvider, useSkin };
