import firebase from 'firebase/app';
import { ISystemSensitive } from 'hooks/system/types';

export const perCustomer = process.env.REACT_APP_PER_CUSTOMER;

// eslint-disable-next-line prettier/prettier
export function getValuesToCharge(
  sensitives: firebase.firestore.QueryDocumentSnapshot<ISystemSensitive>[],
): {
  quantity: number;
  systemsIn: string[];
  allCustomers: number;
  isUsingDemo: boolean;
} {
  const systemsIn: Array<string> = [];
  let allCustomers = 0;
  let isUsingDemo = false;

  sensitives?.forEach(doc => {
    const { connector, analytics } = doc.data() || {};

    const { customersInApi } = analytics || {};
    allCustomers += customersInApi || 0;
    isUsingDemo = isUsingDemo || connector?.host === process.env.REACT_APP_DEMO_HOST;

    const { api } = connector || {};
    !!api && systemsIn.push(api);
  });

  const quantity = Math.ceil(allCustomers / Number(perCustomer)) || 1;

  return { quantity, systemsIn, allCustomers, isUsingDemo };
}
