import { Props } from './types';
import { ReactComponent as Arrow } from './assets/arrow.svg';

import IndicationMenuItemLogic from './logic';
import { IndicationModalMenuItem } from './style';

const IndicationMenuItem: React.FC<Props> = ({
  children,
  title,
  index,
  isOpen = false,
  showButton = true,
  isComeBackShown = false,
  hideOnOpen,
  backgroundColor,
  highlighted,
}: Props) => {
  const { handleClickItem, isModalShown, showButtonState } = IndicationMenuItemLogic(
    isOpen,
    index,
    isComeBackShown,
    showButton,
    hideOnOpen,
  );

  return (
    <>
      {showButtonState && !isModalShown && (
        <IndicationModalMenuItem onClick={handleClickItem} backgroundColor={backgroundColor} highlighted={highlighted}>
          <p>{title}</p>
          <Arrow />
        </IndicationModalMenuItem>
      )}
      {isModalShown && children}
    </>
  );
};

export default IndicationMenuItem;
