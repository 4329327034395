import { useCallback, useEffect, useState } from 'react';

import firebase, { db } from 'utils/firebase';
import { useDashboard } from '../dashboard';
import { ISystem, ILogicReturn, ISystemSensitive } from './types';

const useSystemLogic = (): ILogicReturn => {
  const { Alert, setIsLoading, setCheckpoint, setSyncWhenPopulateProvider } = useDashboard();
  const [unsubscribe, setUnsubscribe] = useState<() => () => void>();
  const [showAlertAfterSync, setShowAlertAfterSync] = useState<string>('');

  const [systems, setSystems] = useState<firebase.firestore.QueryDocumentSnapshot<ISystem>[]>(
    [] as firebase.firestore.QueryDocumentSnapshot<ISystem>[],
  );

  const [sensitives, setSensitives] = useState<firebase.firestore.QueryDocumentSnapshot<ISystemSensitive>[]>(
    [] as firebase.firestore.QueryDocumentSnapshot<ISystemSensitive>[],
  );

  const handleSystemSubscription = useCallback((providerId: string) => {
    const unsub = () =>
      db
        .doc(providerId)
        .collection('systems')
        .onSnapshot(querySystem => {
          const docsSystem = querySystem.docs.filter(docSystem => docSystem.exists);
          const systemsSnap = docsSystem as firebase.firestore.QueryDocumentSnapshot<ISystem>[];
          setSystems(systemsSnap);
        });

    setUnsubscribe(unsub);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    const unsubSen = () => {
      const unsubAll = systems?.filter
        ? systems
            ?.filter(system => !!system && system.exists)
            ?.map(system => {
              return system.ref.collection('sensitive').onSnapshot(querySensitive => {
                const docsSensitive = querySensitive.docs[0];
                const firstSensitiveData: ISystemSensitive = docsSensitive?.data();

                // Remove alert if sync fails
                setShowAlertAfterSync(oldId => {
                  if (oldId === docsSensitive.id && firstSensitiveData.status === 'fail') {
                    return '';
                  }
                  return oldId;
                });

                if (!!firstSensitiveData?.connector?.host && !!firstSensitiveData?.connector?.jwt_auth) {
                  setCheckpoint('system');
                }
                if (docsSensitive?.exists) {
                  setSensitives(oldSensitives => {
                    const newSensitives = [
                      ...oldSensitives
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        .filter(doc => doc.ref.parent.parent!.id !== docsSensitive.ref.parent.parent!.id)
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        .filter((value, index) => value.ref.parent.parent!.id === systems[index].id),
                      docsSensitive,
                    ];
                    const systemThatFailed = newSensitives?.find(doc => doc.data().status === 'fail');
                    const serverFail = !!systemThatFailed;
                    if (serverFail) {
                      if (systemThatFailed) {
                        Alert({
                          message: `Houve um erro na sincronização de um dos sistemas "${
                            systemThatFailed.data().last_fail_message
                          }"`,
                          type: 'error',
                        });
                      }
                    }
                    return newSensitives;
                  });
                }
              });
            })
        : [];

      return () => {
        return unsubAll.map(unsub => unsub());
      };
    };

    return unsubSen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systems]);

  const cleanSessionSystem = useCallback(() => {
    setSystems({} as firebase.firestore.QueryDocumentSnapshot<ISystem>[]);

    if (unsubscribe) {
      const unsub = unsubscribe();
      unsub && unsub();
    }
  }, [unsubscribe]);

  const syncSystem = (sensitive: firebase.firestore.DocumentReference<ISystemSensitive>) => {
    return sensitive
      .set({ status: 'wait' }, { merge: true })
      .then(() => {
        setShowAlertAfterSync(sensitive.path);
        setSyncWhenPopulateProvider(false);
        setCheckpoint('provider');
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(err => {
        Alert({
          message: err.message,
          type: 'error',
        });
      });
  };

  return {
    systems,
    sensitives,
    handleSystemSubscription,
    cleanSessionSystem,
    syncSystem,
    showAlertAfterSync,
    setShowAlertAfterSync,
  };
};

export default useSystemLogic;
