import { IndicationModalBody as ModalBodyBase } from 'components/IndicationModal/style';
import styled from 'styled-components';

export const ModalBody = styled(ModalBodyBase)`
  right: 30px;
`;

export const Title = styled.h2`
  visibility: visible;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: rgb(18, 52, 71);
  word-break: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 700;

  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 15px;
`;
