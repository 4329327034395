import { getValue } from 'utils/dotNotation';
import { SvgFill, SvgFillBase } from './style';
import { ISkinProps } from './types';

const Contact: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  let RealSvgFill = SvgFillBase;

  if (!skin) {
    RealSvgFill = SvgFill;
  }

  return (
    <RealSvgFill
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M19.3229 6.77415C19.0208 2.98852 15.8271 0 11.9448 0H9.05524C5.17286 0 1.97923 2.98852 1.67713 6.77415C0.717514 6.9975 0 7.86118 0 8.89023V12.4507C0 13.4803 0.718243 14.3443 1.67859 14.5671C1.96496 17.9757 4.71617 20.6983 8.14933 20.9606C8.37381 21.5668 8.9565 22 9.63827 22H11.1669C12.0423 22 12.7546 21.2859 12.7546 20.4082V20.2655C12.7546 19.3878 12.0423 18.6737 11.1669 18.6737H9.63827C8.97347 18.6737 8.40317 19.0857 8.16703 19.6682C5.43111 19.4206 3.24249 17.2639 2.96829 14.5545C3.4017 14.4421 3.78249 14.1978 4.0665 13.8676C5.24889 16.2119 7.68844 17.825 10.5 17.825C13.3116 17.825 15.7511 16.2119 16.9335 13.8676C17.3312 14.33 17.9191 14.6236 18.5745 14.6236H18.8328C20.0278 14.6236 21 13.6489 21 12.4508V8.89023C21 7.86118 20.2825 6.9975 19.3229 6.77415ZM9.3363 20.2655C9.3363 20.0986 9.47177 19.9628 9.63827 19.9628H11.1669C11.3334 19.9628 11.4689 20.0986 11.4689 20.2655V20.4082C11.4689 20.5751 11.3334 20.7109 11.1669 20.7109H9.63827C9.47177 20.7109 9.3363 20.5751 9.3363 20.4082V20.2655V20.2655ZM9.05524 1.28906H11.9448C15.1215 1.28906 17.74 3.70657 18.0331 6.78605C17.5991 6.89837 17.2178 7.14282 16.9335 7.47338C15.7511 5.12909 13.3116 3.51605 10.5 3.51605C7.68844 3.51605 5.24889 5.12909 4.0665 7.47338C3.78219 7.14282 3.40089 6.89837 2.96687 6.78605C3.26006 3.70657 5.8785 1.28906 9.05524 1.28906ZM3.30703 12.4508C3.30703 12.9381 2.91159 13.3345 2.42554 13.3345H2.1672C1.68116 13.3345 1.28571 12.9381 1.28571 12.4508V8.89023C1.28571 8.40293 1.68116 8.0065 2.1672 8.0065H2.42554C2.91159 8.0065 3.30703 8.40297 3.30703 8.89023V12.4508ZM10.5 16.5359C7.24273 16.5359 4.59274 13.9047 4.59274 10.6705C4.59274 7.4363 7.24273 4.80511 10.5 4.80511C13.7573 4.80511 16.4073 7.4363 16.4073 10.6705C16.4073 13.9047 13.7573 16.5359 10.5 16.5359ZM19.7143 12.4508C19.7143 12.9381 19.3188 13.3345 18.8328 13.3345H18.5745C18.0884 13.3345 17.693 12.938 17.693 12.4508V8.89023C17.693 8.40293 18.0884 8.0065 18.5745 8.0065H18.8328C19.3188 8.0065 19.7143 8.40297 19.7143 8.89023V12.4508Z"
        fill="black"
      />
      <path
        d="M8.5 10C8.77613 10 9 9.69787 9 9.32519V8.67481C9 8.30213 8.77613 8 8.5 8C8.22387 8 8 8.30213 8 8.67481V9.32519C8 9.69787 8.22387 10 8.5 10Z"
        fill="black"
      />
      <path
        d="M12.5 8C12.2239 8 12 8.30213 12 8.67481V9.32519C12 9.69787 12.2239 10 12.5 10C12.7761 10 13 9.69787 13 9.32519V8.67481C13 8.30213 12.7761 8 12.5 8Z"
        fill="black"
      />
      <path
        d="M10.2656 13.6367C9.81836 13.6367 9.39258 13.5771 8.98828 13.458C8.58398 13.3389 8.22656 13.1631 7.91602 12.9307C7.60352 12.6982 7.38672 12.4463 7.26562 12.1748L7.72266 12.0107C7.93359 12.2959 8.25977 12.5186 8.70117 12.6787C9.14258 12.8389 9.6582 12.9189 10.248 12.9189L10.3389 12.9189C10.9307 12.9189 11.4482 12.8398 11.8916 12.6816C12.335 12.5234 12.666 12.2998 12.8848 12.0107L13.333 12.1748C13.2139 12.4404 13.0029 12.6885 12.7002 12.9189C12.3994 13.1475 12.0518 13.3223 11.6572 13.4434C11.2627 13.5645 10.8506 13.6289 10.4209 13.6367L10.2656 13.6367Z"
        fill="black"
      />
    </RealSvgFill>
  );
};

export default Contact;
