/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import { useTicket } from 'components/SendSupportMessageButton/hooks/Ticket';
import { ITicketProvider } from 'components/SendSupportMessageButton/hooks/Ticket/types';
import format from 'date-fns/format';
import { useAuth } from 'hooks/auth';
import { useCallback, useEffect, useRef, useState } from 'react';
import firebase, { db } from 'utils/firebase';
import { MySwal } from 'utils/MySwal';
import { getData, validator } from '../SendMessageItem/logic';
import { ILogicReturn, ITicketMessageState, ITicketMessage, ITicketProviderState, ITicketMessageMapped } from './types';

const useLogic = (showOpen: boolean): ILogicReturn => {
  const { account } = useAuth();
  const { tickets } = useTicket();
  const [ticketsState, setTicketsState] = useState<ITicketProviderState[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTicket, setCurrentTicket] = useState<ITicketProviderState | null>(null);
  const [messages, setMessages] = useState<Array<ITicketMessageState>>([]);
  const [isAnswering, setIsAnswering] = useState(false);
  const divModalRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const currentUnread = account?.isSupport ? 'provider_unread_messages' : 'support_unread_messages';
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [unsubscribeToMessages, setUnsubscribeToMessages] = useState(() =>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  );

  const stopLoading = () => setIsLoadingMessages(false);
  const showError = (action = 'executar essa ação') =>
    MySwal({
      showCancelButton: false,
      confirmButtonText: 'Entendi',
      title: <strong>Aviso</strong>,
      icon: 'error',
      html: <>Ocorreu uma falha ao {action}, por favor tente novamente mais tarde.</>,
    });

  useEffect(() => {
    let isMounted = true;
    isMounted &&
      tickets &&
      setTicketsState(
        tickets.docs
          .map(doc => ({ ...doc.data(), ref: doc.ref } || {}))
          .map(data => ({
            ...data,
            unreadMessages: data[currentUnread] || 0,
          })),
      );
    return () => {
      isMounted = false;
    };
  }, [account?.isSupport, currentUnread, showOpen, tickets]);

  const filteredTickets = ticketsState.filter(
    data => (data.status === 'open' && showOpen) || (data.status === 'closed' && !showOpen),
  );

  useEffect(() => {
    !isModalOpen && setMessages([]);
  }, [isModalOpen]);

  const handleOnClickTicket = (ticket: ITicketProviderState) => {
    setCurrentTicket(ticket);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAnswering(false);
    unsubscribeToMessages();
  };

  const afterOpenModal = useCallback(() => {
    const ticketDoc = currentTicket?.ref;
    const ticketId = currentTicket?.ticket_id;

    const formatMessages = ({ id, message, sent_at, is_support, ref, unread = false }: ITicketMessageMapped) => {
      let whoIs = 'Você';
      let isMessageAtRightPos = true;
      let isDeletable = false;

      if (account?.isSupport) {
        if (is_support) {
          isDeletable = showOpen;
        } else {
          whoIs = 'Cliente';
          isMessageAtRightPos = false;
        }
      } else if (is_support) {
        whoIs = 'Suporte';
        isMessageAtRightPos = false;
      }

      return {
        ref,
        messageId: id,
        whoIs,
        isMessageAtRightPos,
        sentAt: format(sent_at ? sent_at.toDate() : new Date(), 'dd/MM/yyyy, p'),
        message,
        isDeletable,
        unread,
      };
    };

    if (ticketId && ticketDoc) {
      setUnsubscribeToMessages(() => {
        return ticketDoc.collection('messages').onSnapshot(messageQuery => {
          const ticketsData = messageQuery.docs
            .map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref } as ITicketMessageMapped))
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .sort((a, b) => (a.sent_at && b.sent_at && a.sent_at.toDate() - b.sent_at.toDate()) || 1);

          setMessages(oldTickets => {
            const newTickets = ticketsData.map(formatMessages);
            oldTickets.length !== ticketsData.length && setIsLoadingMessages(false);
            return newTickets;
          });
          divModalRef.current && (divModalRef.current.scrollTop = divModalRef.current.scrollHeight);

          const batch = db.batch();
          const increment = ticketsData.reduce((pv, { ref, unread = false, is_support = false }) => {
            const isBeingRead = (is_support && !account?.isSupport) || (!is_support && account?.isSupport);
            const cV = isBeingRead ? (batch.update(ref, 'unread', false), unread) : false;
            return pv + Number(cV);
          }, 0);

          if (increment > 0) {
            batch.update(ticketDoc, currentUnread, firebase.firestore.FieldValue.increment(-increment));
            batch.commit();
          }
        });
      });
    }
  }, [showOpen, account?.isSupport, currentTicket, currentUnread]);

  const resetForm = () => {
    formRef.current && formRef.current.reset();
  };

  const handleOnSubmitTicketMessage = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const elements = Array.from(e.currentTarget.elements) as Array<HTMLInputElement>;
      const resetFormFields = () => {
        resetForm();
        elements.forEach(element => element.classList.remove('errored'));
      };

      const isValid = validator(elements);
      if (isValid) {
        const data = getData(elements);

        if (data && isValid && currentTicket) {
          const batch = db.batch();
          const ticketDoc = currentTicket.ref;
          const messageDoc = ticketDoc.collection('messages').doc();

          const ticket = {};
          const message = {
            message: data.report,
            sent_at: firebase.firestore.FieldValue.serverTimestamp(),
            unread: true,
          };

          if (account?.isSupport) {
            Object.assign(ticket, { support_unread_messages: firebase.firestore.FieldValue.increment(1) });
            Object.assign(message, { is_support: true });
          } else {
            Object.assign(ticket, { provider_unread_messages: firebase.firestore.FieldValue.increment(1) });
          }

          batch.update(ticketDoc, ticket);
          batch.set(messageDoc, message);

          setIsLoadingMessages(true);
          setIsAnswering(false);
          batch
            .commit()
            .then(resetFormFields)
            .catch(() => {
              setIsAnswering(true);
              showError('enviar mensagem');
            })
            .finally(stopLoading);
        }
      }
    },
    [account?.isSupport, currentTicket],
  );

  const handleOnClickAnswer = () => {
    setIsAnswering(true);
    new Promise(r => setTimeout(r, 20)).then(() => {
      divModalRef.current && (divModalRef.current.scrollTop = divModalRef.current.scrollHeight);
    });
  };

  const handleOnClickDeleteMessage = useCallback(
    (ref: firebase.firestore.DocumentReference<ITicketMessage>, unread: boolean) => {
      MySwal({
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        title: <strong>Aviso</strong>,
        icon: 'warning',
        html: <>Tem certeza que deseja deletar essa mensagem?</>,
      })
        .then(res => {
          if (res.isConfirmed) {
            const batch = db.batch();
            unread &&
              ref.parent.parent &&
              batch.update(ref.parent.parent, 'support_unread_messages', firebase.firestore.FieldValue.increment(-1));
            batch.delete(ref);

            setIsLoadingMessages(true);
            return batch.commit();
          }

          return Promise.resolve();
        })
        .then(stopLoading)
        .catch(() => showError('deletar mensagem'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUnread],
  );

  const handleOnClickCloseTicket = (ref: firebase.firestore.DocumentReference<ITicketProvider>) => {
    const handleConfirm = () => {
      setIsLoadingMessages(true);
      setIsModalOpen(false);

      return ref
        .update(
          'status',
          'closed',
          'closing_date',
          firebase.firestore.FieldValue.serverTimestamp(),
          'updated_at',
          firebase.firestore.FieldValue.serverTimestamp(),
        )
        .then(() => {
          return MySwal({
            showCancelButton: false,
            confirmButtonText: 'Confirmar',
            title: <strong>Aviso</strong>,
            icon: 'success',
            html: <>Ticket fechado.</>,
          });
        });
    };

    setIsModalOpen(false);
    MySwal({
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      preConfirm: handleConfirm,
      title: <strong>Aviso</strong>,
      icon: 'warning',
      html: <>Tem certeza que deseja fechar este ticket?</>,
    })
      .then(res => !res.isConfirmed && setIsModalOpen(true))
      .then(stopLoading)
      .catch(() => showError('fechar ticket'));
  };

  const handleOnClickCancelAnswer = () => {
    setIsAnswering(false);
    resetForm();
  };

  return {
    handleOnClickTicket,
    isModalOpen,
    closeModal,
    currentTicket,
    afterOpenModal,
    messages,
    handleOnSubmitTicketMessage,
    divModalRef,
    isAnswering,
    handleOnClickAnswer,
    handleOnClickDeleteMessage,
    handleOnClickCancelAnswer,
    formRef,
    handleOnClickCloseTicket,
    isLoadingMessages,
    filteredTickets,
  };
};

export default useLogic;
