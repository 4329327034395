import styled from 'styled-components';

export const AlertContainer = styled.div`
  .Toastify__toast-container {
    width: 95%;
  }

  .container {
    border-radius: 5px;
    display: none;
    min-height: 50px;
    left: 30px;
    right: 30px;
    bottom: 50px;
    display: flex;

    .badge {
      border-radius: 30px;
      padding-right: 0.6em;
      padding-left: 0.6em;
      padding-top: 0.07em;
      padding-bottom: 0.1em;
      border-radius: 10rem;
      margin: auto 0px;
      margin-left: 15px;

      div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        > p {
          font-size: 14px;
          font-weight: 700;
          text-align: center;
        }
      }
    }

    .content {
      flex: 1;
      margin: auto 0px;
      margin-left: 10px;
    }
  }

  .Toastify__toast--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;

    .badge {
      color: #fff;
      background-color: #28a745;

      div > p::after {
        content: 'Sucesso';
      }
    }

    .Toastify__progress-bar {
      background-color: #155724;
    }
    .Toastify__close-button > svg {
      fill: #155724;
    }
  }

  .Toastify__toast--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;

    .badge {
      color: #212529;
      background-color: #ffc107;

      div > p::after {
        content: 'Alerta';
      }
    }

    .Toastify__progress-bar {
      background-color: #856404;
    }
    .Toastify__close-button > svg {
      fill: #856404;
    }
  }

  .Toastify__toast--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    .badge {
      color: #fff;
      background-color: #dc3545;

      div > p::after {
        content: 'Erro';
      }
    }

    .Toastify__progress-bar {
      background-color: #721c24;
    }
    .Toastify__close-button > svg {
      fill: #721c24;
    }
  }
`;
