/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useState } from 'react';
import { useModal } from '../../hooks/Modal';
import { ILogicReturn } from './types';

const IndicationMenuItemLogic = (
  isOpen: boolean,
  index: number,
  isComeBackShown: boolean,
  showButton: boolean,
  hideOnOpen?: number,
): ILogicReturn => {
  const { registerTab, tabs, closeTab, setIsComeBackShown, setIsOpen } = useModal();
  const [showButtonState, setShowButtonState] = useState(showButton);

  const handleClickItem = () => {
    setIsComeBackShown(isComeBackShown);
    closeTab(index);
  };

  useEffect(() => {
    if (hideOnOpen !== undefined) {
      setShowButtonState(!tabs?.[hideOnOpen]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs?.[hideOnOpen !== undefined ? hideOnOpen : -1]]);

  useEffect(() => {
    if (isOpen) {
      isOpen && setIsOpen(index);
    }
    registerTab(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleClickItem, isModalShown: tabs[index], showButtonState };
};

export default IndicationMenuItemLogic;
