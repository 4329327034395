import { IndicationMenuItemBodyBase } from 'components/IndicationModal/style';
import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  min-height: 300px;
  background-color: none !important;

  span {
    visibility: visible;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: rgb(18, 52, 71);
    word-break: break-word;
    overflow-wrap: break-word;
    border-width: 3px;
    border-style: solid;
    border-color: rgb(46, 110, 216) rgb(235, 239, 243) rgb(235, 239, 243);
    border-image: initial;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: 1s linear 0s infinite normal none running ${rotation};
    margin: auto;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
  }
`;

export const ItemBody = styled(IndicationMenuItemBodyBase)`
  > form {
    margin-left: 15px;
    margin-right: 15px;

    p.disclaimer {
      margin-top: 8px;
      margin-bottom: 8px;
      color: rgb(18, 52, 71);
    }

    > button {
      visibility: visible;
      -webkit-font-smoothing: antialiased;
      word-break: break-word;
      overflow-wrap: break-word;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 9px 18px 12px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
      background: rgb(0, 96, 99);
      color: rgb(255, 255, 255);
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 600;
      border: 1px solid rgba(0, 0, 0, 0.125);
      transition: all 0.2s ease-in-out 0s;
      width: 100%;
      min-width: 140px;
      margin-right: 0px;
      outline: none;
    }
  }

  > div.form-sent {
    display: flex;
    flex-direction: column;

    > svg {
      align-self: center;
      width: 120px;
      height: 120px;
    }

    > div {
      visibility: visible;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      color: rgb(18, 52, 71);
      word-break: break-word;
      overflow-wrap: break-word;
      text-align: center;
      box-sizing: border-box;
      margin: 32px 0px 16px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2;
      animation: 0.2s linear 0s 1 normal none running ThanksAnimation;
    }

    > button {
      align-self: center;
      visibility: visible;
      -webkit-font-smoothing: antialiased;
      word-break: break-word;
      overflow-wrap: break-word;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 9px 18px 12px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
      background: rgb(0, 96, 99);
      color: rgb(255, 255, 255);
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 600;
      border: 1px solid rgba(0, 0, 0, 0.125);
      transition: all 0.2s ease-in-out 0s;
      width: 140px;
      margin-right: 0px;
      outline: none;
    }
  }
`;
