/* eslint-disable global-require */
/* eslint-disable camelcase */
import styled, { css } from 'styled-components';
import bgImage from 'assets/bgLowOpacityRotated.png';
import { ISkin } from 'hooks/skin/types';
import { useSelector } from 'react-redux';

import { IStore } from 'store/types';
import { isSafari } from '../../utils/whatBrowserAmI';

interface ContainerProps {
  isShown: boolean;
}

export const ContainerDefault = styled.div<ContainerProps>`
  display: ${props => (props.isShown ? 'block' : 'none')};
  border-radius: 5px;
  padding: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 15px;
  margin-top: 0px;
  margin-bottom: 10px;

  background-color: ${props => props.color};
`;

export const defaultStyle: ISkin = {
  title: 'App Provedor',
  main: {
    header: {
      title: { color: '#ffffff' },
      backgroundColor: '#6e336a',
      my_account_button: {
        circle: { backgroundColor: '#ffffff', color: '#56204d' },
        backgroundColor: '#ffffff',
        color: '#56204d',
      },
    },
    cards: {
      mini_cards: {
        color: '#000000',
        backgroundColor: '#edebeb',
        button: { backgroundColor: '#56204d', color: '#ffffff' },
      },
      color: '#56204d',
      backgroundColor: '#ffffff',
      button: { color: '#ffffff', backgroundColor: '#56204d' },
    },
  },
  general: { backgroundColor: '#56204d' },
};

export const customSkinStyle: ISkin = {
  title: 'Personalizar',
  general: {
    backgroundColor: '#a2a4a4',
  },
  main: {
    header: {
      my_account_button: {
        color: '#ffffff',
        circle: {
          backgroundColor: '#a4a4a4',
          color: '#ffffff',
        },
        backgroundColor: '#9d9d9d',
      },
      title: {
        color: '#ffffff',
      },
      backgroundColor: '#858787',
    },
    cards: {
      button: {
        backgroundColor: '#9a9a9a',
        color: '#ffffff',
      },
      mini_cards: {
        backgroundColor: '#edebeb',
        button: {
          backgroundColor: '#9a9a9a',
          color: '#ffffff',
        },
        color: '#4a4a4a',
      },
      color: '#4a4a4a',
      backgroundColor: '#ffffff',
    },
  },
};

export const TopBezel = styled.div`
  width: 200px;
  height: 30px;
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

interface PortraitProps {
  scale?: number;
}

export const Portrait = styled.div<PortraitProps>`
  position: relative;
  background-color: #000;
  border-radius: 45px !important;
  width: 381px;
  height: 580px;
  max-width: 320px;
  max-height: 650px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 20px;

  svg {
    transform: none !important;
  }

  * {
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
`;

export const BackgroundBase = styled.div`
  padding-top: 20px;
  margin-bottom: -10px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  padding-top: 15px;
  padding-bottom: 5px;
  height: 440px;

  background-color: ${props => props.color};

  button {
    cursor: initial;
  }

  ${isSafari &&
  css`
    .main .fill,.stroke
    display: none;
  `}

  svg {
    width: auto !important;
  }
  // eslint-disable-next-line global-require
  background-image: url(${bgImage});
  background-repeat: repeat;
  background-size: 350px 800px;

  :last-child {
    margin-bottom: 20px;
  }

  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Background = styled(BackgroundBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.general.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const Svg = styled.svg`
  ${props =>
    isSafari &&
    props.id !== 'main' &&
    css`
      .fill,
      .stroke {
        display: none;
      }
    `}

  svg {
    width: auto !important;
  }
`;

export const ForeignObject = styled.foreignObject`
  border-radius: 50px;
  y: 10px;
  x: 2px;
  width: 376px;
  height: 757px;

  * {
    cursor: default;
  }
`;

export const CardButtonBase = styled.button`
  background-color: ${props => props.color};
`;

export const CardButton = styled(CardButtonBase).attrs(() => {
  const backgroundColor = useSelector((state: IStore) => state.skin.main.cards.button.backgroundColor);

  return {
    style: {
      backgroundColor,
    },
  };
})``;

export const ButtonTextBase = styled.p`
  color: ${props => props.color};
`;

export const ButtonText = styled(ButtonTextBase).attrs(() => {
  const color = useSelector((state: IStore) => state.skin.main.cards.button.color);

  return {
    style: {
      color,
    },
  };
})``;

export const TitleTextBase = styled.h2`
  color: ${props => props.color};
`;

export const TitleText = styled(TitleTextBase).attrs(() => {
  const color = useSelector((state: IStore) => state.skin.main.cards.color);

  return {
    style: {
      color,
    },
  };
})``;

export const SubTitleBase = styled.p`
  color: ${props => props.color};
`;

export const SubTitle = styled(SubTitleBase).attrs(() => {
  const color = useSelector((state: IStore) => state.skin.main.cards.color);

  return {
    style: {
      color,
    },
  };
})``;
