import { getValue } from 'utils/dotNotation';
import { SvgFill } from './style';
import { ISkinProps } from './types';

const Lock: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  return (
    <SvgFill
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M13.0306 27.6806L12.5049 32.4079C12.4789 32.6431 12.5545 32.8791 12.7124 33.0557C12.8703 33.2323 13.0965 33.3332 13.3333 33.3332L16.6667 33.3332C16.9035 33.3332 17.1298 33.2323 17.2876 33.0557C17.4455 32.8792 17.5212 32.6431 17.4951 32.4079L16.9694 27.6806C17.8231 27.0596 18.3333 26.0774 18.3333 24.9999C18.3333 23.1615 16.8384 21.6665 15 21.6665C13.1615 21.6665 11.6666 23.1615 11.6666 24.9999C11.6666 26.0774 12.1769 27.0596 13.0306 27.6806ZM15 23.3332C15.9187 23.3332 16.6666 24.0811 16.6666 24.9999C16.6666 25.6387 16.3037 26.2108 15.7194 26.494C15.4012 26.6486 15.2148 26.9856 15.2539 27.3363L15.7357 31.6665L14.2644 31.6665L14.7462 27.3363C14.7852 26.9855 14.5989 26.6486 14.2807 26.494C13.6964 26.2108 13.3334 25.6387 13.3334 24.9999C13.3333 24.0811 14.0812 23.3332 15 23.3332Z"
        fill="black"
      />
      <path
        d="M29.1666 15L26.6666 15L26.6666 11.6666C26.6666 5.23359 21.4331 0 15 0C8.56687 0 3.33336 5.23359 3.33336 11.6666L3.33336 15L0.833359 15C0.372734 15 0 15.3727 0 15.8334L0 36.6667C0 38.5051 1.49492 40 3.33336 40L26.6667 40C28.5051 40 30 38.5051 30 36.6666L30 15.8334C30 15.3727 29.6273 15 29.1666 15ZM5 11.6666C5 6.15234 9.4857 1.66664 15 1.66664C20.5143 1.66664 25 6.15234 25 11.6666L25 15L23.3334 15L23.3334 11.6666C23.3334 7.07187 19.5948 3.33328 15 3.33328C10.4052 3.33328 6.66664 7.07195 6.66664 11.6666L6.66664 15L5 15L5 11.6666ZM21.6666 11.6666L21.6666 15L8.33336 15L8.33336 11.6666C8.33336 7.9907 11.3241 5 15 5C18.6759 5 21.6666 7.9907 21.6666 11.6666ZM28.3334 36.6666C28.3334 37.5854 27.5855 38.3333 26.6667 38.3333L3.33336 38.3333C2.41461 38.3333 1.66672 37.5854 1.66672 36.6666L1.66672 16.6666L28.3334 16.6666L28.3334 36.6666V36.6666Z"
        fill="black"
      />
    </SvgFill>
  );
};

export default Lock;
