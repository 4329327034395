import styled from 'styled-components';

export const ContainerBase = styled.div``;

export const LabelInputBase = styled.label`
  display: flex;
  flex-direction: column;

  visibility: visible;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: rgb(18, 52, 71);
  word-break: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  text-align: left;
  display: block;
  font-size: 0.75rem;

  > span {
    visibility: visible;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: left;
    box-sizing: border-box;
    color: rgb(215, 45, 48);
    font-size: 0.75rem;
  }
`;
