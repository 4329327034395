import styled from 'styled-components';

export const Input = styled.input`
  visibility: visible;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  padding: 11px 8px 10px 12px;
  margin: 5px 0px 3px;
  border-radius: 4px;
  display: block;
  border: 1px solid rgb(207, 215, 223);
  width: 100%;
  box-shadow: rgba(18, 52, 77, 0.05) 0px 1px 2px 0px inset;
  font-size: 0.875rem;
  font-weight: 600;
`;
