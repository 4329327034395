import styled from 'styled-components';
import { ContainerBase, LabelInputBase } from '../style';

export const Label = styled(LabelInputBase)`
  display: flex;

  > svg {
    margin: auto;
  }

  > h5 {
    visibility: visible;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: rgb(18, 52, 71);
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 1.13em;
    margin: 0px;
  }

  > p {
    visibility: visible;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: rgb(18, 52, 71);
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0.5em 0px;
    font-size: 0.75rem;
  }
`;

export const Container = styled(ContainerBase)`
  visibility: visible;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: rgb(18, 52, 71);
  word-break: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: rgb(247, 249, 250);
  border: 1px dashed rgb(172, 182, 190);
  text-align: center;
  padding: 25px 0px;
  cursor: pointer;
`;

export const Input = styled.input`
  visibility: visible;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  padding: 11px 8px 10px 12px;
  margin: 5px 0px 3px;
  border-radius: 4px;
  display: block;
  border: 1px solid rgb(207, 215, 223);
  width: 100%;
  box-shadow: rgba(18, 52, 77, 0.05) 0px 1px 2px 0px inset;
  font-size: 0.875rem;
  font-weight: 600;
`;
