import { useSkin } from 'hooks/skin';
import { ISkin } from 'hooks/skin/types';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'store/actions';

import firebase, { db } from 'utils/firebase';
import { formatMessage } from 'utils/formatMessage';
import { useDashboard } from '../dashboard';
import { ProviderState, IProvider, ILogicReturn, IMessage } from './types';

const useProviderLogic = (): ILogicReturn => {
  const { setIsLoading, setCheckpoint } = useDashboard();
  const { setTemporarySkin } = useSkin();
  const [isLoadingProvider, setIsLoadingProvider] = useState(true);
  const [unsubscribe, setUnsubscribe] = useState<() => () => void>();
  const dispatch = useDispatch();

  const [provider, setProvider] = useState<ProviderState>({} as ProviderState);

  const [isAccountNotPopulated, setIsAccountNotPopulated] = useState(true);

  useEffect(() => {
    setIsAccountNotPopulated(
      !provider?.name ||
        !provider?.company_name ||
        !provider?.company_doc ||
        !provider?.logo ||
        !provider?.contacts ||
        provider?.contacts.length < 1,
    );
  }, [provider]);

  const handleProviderSubscription = useCallback(
    (providerId: string) => {
      const unsub = () =>
        db.doc(providerId).onSnapshot(docProvider => {
          setIsLoading(false);

          if (docProvider.exists) {
            const providerSetData = docProvider.data() as IProvider;
            if (
              !(
                !providerSetData?.name ||
                !providerSetData?.company_name ||
                !providerSetData?.company_doc ||
                !providerSetData?.logo ||
                !providerSetData?.contacts ||
                providerSetData?.contacts.length < 1
              )
            ) {
              setCheckpoint('provider');
            }
            localStorage.setItem('@AdminWebAppProvedor:providerId', providerId);
            setProvider(providerSetData);
            setTemporarySkin(providerSetData?.skin as ISkin);

            dispatch(actions.setColors(providerSetData.skin));
          }
          setIsLoadingProvider(false);
        });
      setUnsubscribe(unsub);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setTemporaryAttr = useCallback((name: string, value: Array<string>) => {
    setProvider((oldData: ProviderState) => ({
      ...oldData,
      [`temporary_${name}`]: value,
    }));
  }, []);

  const cleanSessionProvider = useCallback(() => {
    localStorage.removeItem('@AdminWebAppProvedor:providerId');
    setProvider({});

    setIsLoadingProvider(false);

    if (unsubscribe) {
      const unsub = unsubscribe();
      unsub && unsub();
    }
  }, [unsubscribe]);

  const [messagesQuery, setMessagesQuery] = useState<IMessage[]>();
  const [isLoadingMessagesQuery, setIsLoadingMessageQuery] = useState(false);

  const requestMessages = useCallback((providerId: string) => {
    setMessagesQuery(oldMessages => {
      setIsLoadingMessageQuery(oldLoading => {
        if (oldMessages === undefined && !oldLoading) {
          setIsLoadingMessageQuery(true);
          db.doc(providerId)
            .collection('messages')
            .where('target', 'array-contains', 'all')
            .orderBy('date', 'asc')
            .limit(30)
            .get()
            .then(messages => {
              setMessagesQuery(
                messages.docs
                  .map((currentMessage: firebase.firestore.DocumentData) => {
                    const messageData = currentMessage.data();
                    return formatMessage({
                      id: currentMessage.id,
                      targets: messageData.targets,
                      content: messageData.content,
                      date: messageData.date.toDate(),
                    });
                  })
                  .reverse(),
              );
            })
            .finally(() => setIsLoadingMessageQuery(false));
        }
        return oldLoading;
      });
      return oldMessages;
    });
  }, []);

  return {
    isLoadingProvider,
    setIsLoadingProvider,
    provider,
    setTemporaryAttr,
    handleProviderSubscription,
    cleanSessionProvider,
    messagesQuery,
    isLoadingMessagesQuery,
    requestMessages,
    setMessagesQuery,
    isAccountNotPopulated,
  };
};

export default useProviderLogic;
