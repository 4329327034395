/* eslint-disable default-case */
/* eslint-disable camelcase */
import Alert from 'components/Alert';
import { createContext, useContext, useRef, useState, useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { actions } from 'store/actions';
import { store } from 'index';
import { intervalToDuration } from 'date-fns';
import { ACTIONS, CallBackProps, LIFECYCLE } from 'react-joyride';

const startValuesCheckpoint = { dashboard: false, system: false, provider: false, ended: false };

interface DashboardContext {
  isLoading: boolean;
  flexOptionSelected: string;
  setIsLoading(value: boolean): void;
  Alert(value: IAlert): void;
  setFlexOptionSelected: React.Dispatch<React.SetStateAction<string>>;
  cleanSessionDashboard(): void;
  currentJoyride: string;
  setCurrentJoyride(value: string): void;
  currentTabRef: React.RefObject<HTMLDivElement>;
  srollTo: (x?: number, y?: number) => void;
  setScroll: (data: CallBackProps) => void;
  setCheckpoint: (...checks: ICheckpoint[]) => void;
  checkpoint: typeof startValuesCheckpoint;
  showTour: boolean;
  setShowTour: (value: boolean) => void;
  syncWhenPopulateProvider: boolean;
  setSyncWhenPopulateProvider: (value: boolean) => void;
  select: number;
  setSelect(value: number): void;
  resetWizard: () => void;
  stepByStepReseted: boolean;
  loaderMessage: string;
  setLoaderMessage(value: string): void;
  setIsShowScroll: (value?: boolean) => void;
}

const DashboardContext = createContext<DashboardContext>({} as DashboardContext);

interface Props {
  children: React.ReactNode;
}

type ICheckpoint = 'dashboard' | 'provider' | 'system' | 'ended';

type IAlertType = 'success' | 'warning' | 'error';
export interface IAlert {
  message?: string;
  type?: IAlertType;
  receivedAt?: Date;
}

const defaultLoaderMessage = 'Carregando...';

const DashboardProvider: React.FC<Props> = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [flexOptionSelected, setFlexOptionSelected] = useState<string>('');
  const [loaderMessage, setLoaderMessage] = useState(defaultLoaderMessage);
  const dispatch = useDispatch();
  const [checkpoint, setCheckpoint] = useState<typeof startValuesCheckpoint>(() => {
    const getCheckpoint = localStorage.getItem('@AdminWebAppProvedor:checkpoint');
    const currentCheckpoint = getCheckpoint !== null ? JSON.parse(getCheckpoint) : startValuesCheckpoint;
    return currentCheckpoint;
  });
  const [currentJoyride, setCurrentJoyride] = useState(() => {
    if (checkpoint.dashboard) {
      return '';
    }
    return 'dashboard';
  });
  const [stepByStepReseted, setStepByStepReseted] = useState(false);
  const currentTabRef = useRef<HTMLDivElement>(null);
  const [showTour, setShowTour] = useState(false);
  const [syncWhenPopulateProvider, setSyncWhenPopulateProvider] = useState(() => {
    const getSyncWhenPopulateProvider = localStorage.getItem('@AdminWebAppProvedor:syncWhenPopulateProvider');
    const currentSyncWhenPopulateProvider =
      getSyncWhenPopulateProvider !== null ? getSyncWhenPopulateProvider === 'true' : false;
    return currentSyncWhenPopulateProvider;
  });
  const [select, setSelect] = useState(() => {
    const selected = localStorage.getItem('@AdminWebAppProvedor:selected');
    return selected === null ? 0 : Number(selected);
  });

  const cleanSessionDashboard = () => {
    setCurrentJoyride('');
    setCheckpoint(startValuesCheckpoint);
    setSelect(0);
    localStorage.removeItem('@AdminWebAppProvedor:selected');
    localStorage.removeItem('@AdminWebAppProvedor:checkpoint');
    localStorage.removeItem('@AdminWebAppProvedor:syncWhenPopulateProvider');
  };

  const handleAlert = (currentAlert: IAlert) => {
    const { alert } = store.getState();
    const container = (
      <div className="container">
        <div className="badge">
          <div>
            <p />
          </div>
        </div>
        <div className="content">{currentAlert.message}</div>
      </div>
    );

    const autoClose = 5000;

    const toastConfig: ToastOptions = {
      position: 'bottom-center',
      autoClose,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      draggableDirection: 'y',
      draggablePercent: 40,
      progress: undefined,
      icon: false,
    };

    if (currentAlert.message) {
      const searchEqualOrInTimeToReplace = alert.find(alertItem => {
        const searchEqual = alertItem?.message === currentAlert.message && alertItem.type === currentAlert.type;
        if (alertItem?.receivedAt) {
          const duration = intervalToDuration({
            start: alertItem.receivedAt,
            end: new Date(),
          });

          if (
            (duration?.years && duration?.years >= 1) ||
            (duration?.months && duration?.months >= 1) ||
            (duration?.weeks && duration?.weeks >= 1) ||
            (duration?.days && duration?.days >= 1) ||
            (duration?.hours && duration?.hours >= 1) ||
            (duration?.minutes && duration?.minutes >= 1) ||
            (duration?.seconds && duration?.seconds >= autoClose / 1000)
          ) {
            return false;
          }
        }

        return searchEqual;
      });

      if (!alert || !searchEqualOrInTimeToReplace) {
        const action = actions.setAlert({ ...currentAlert, receivedAt: new Date() });
        dispatch(action);
        toast.clearWaitingQueue();
        switch (currentAlert.type) {
          case 'success':
            toast.success(container, toastConfig);
            break;
          case 'error':
            toast.error(container, toastConfig);
            break;
          case 'warning':
            toast.warning(container, toastConfig);
            break;
          default:
            break;
        }
      }
    }
  };

  const srollTo = (x = 0, y = 0) => {
    currentTabRef.current?.scroll(x, y);
  };

  const setIsShowScroll = (value = true) => {
    if (currentTabRef.current) {
      if (value) {
        currentTabRef.current.classList.remove('scroll-hidden');
      } else {
        currentTabRef.current.classList.add('scroll-hidden');
      }
    }
  };

  const setScroll = (data: CallBackProps) => {
    switch (data.action) {
      case ACTIONS.START:
      case ACTIONS.UPDATE:
        data.type !== LIFECYCLE.BEACON && setIsShowScroll(false);
        break;
      case ACTIONS.RESET:
      case ACTIONS.CLOSE:
      case ACTIONS.STOP:
        setIsShowScroll();
        break;
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        isLoading,
        setIsLoading: (value: boolean) => {
          if (value === false) {
            setLoaderMessage(defaultLoaderMessage);
          }
          setIsLoading(value);
        },
        Alert: handleAlert,
        flexOptionSelected,
        setFlexOptionSelected,
        cleanSessionDashboard,
        currentJoyride,
        setCurrentJoyride: (value: string) => {
          setCurrentJoyride(value);
        },
        currentTabRef,
        srollTo,
        setScroll,
        setCheckpoint: useCallback((...checks: ICheckpoint[]) => {
          setCheckpoint(oldValue => {
            if (!oldValue.ended || checks.some(v => !oldValue[v])) {
              const check = checks.reduce((pV, cV) => ({ ...pV, [cV]: true }), {});
              const value = { ...oldValue, ...check };

              if (!value.dashboard) {
                value.dashboard = value.provider && value.system;
              }

              if (!value.ended) {
                value.ended = Object.values({ ...value, ended: true }).reduce((v, lv) => !!v && !!lv);
              }

              localStorage.setItem('@AdminWebAppProvedor:checkpoint', JSON.stringify(value));
              return value;
            }
            return oldValue;
          });
        }, []),
        resetWizard: () => {
          setStepByStepReseted(true);
          setCurrentJoyride('dashboard');
          setCheckpoint(startValuesCheckpoint);
          localStorage.setItem('@AdminWebAppProvedor:checkpoint', JSON.stringify(startValuesCheckpoint));
        },
        checkpoint,
        showTour,
        setShowTour,
        syncWhenPopulateProvider,
        setSyncWhenPopulateProvider: (value: boolean) => {
          setSyncWhenPopulateProvider(() => {
            localStorage.setItem('@AdminWebAppProvedor:syncWhenPopulateProvider', value.toString());
            return value;
          });
        },
        select,
        setSelect: (value: number) => {
          localStorage.setItem('@AdminWebAppProvedor:selected', value.toString());
          setSelect(value);
        },
        stepByStepReseted,
        loaderMessage,
        setLoaderMessage,
        setIsShowScroll,
      }}
    >
      {children}
      <Alert />
    </DashboardContext.Provider>
  );
};

function useDashboard(): DashboardContext {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useAuth must be used within an DashboardProvider');
  }

  return context;
}

export { DashboardProvider, useDashboard };
