/* eslint-disable jsx-a11y/label-has-associated-control */

import { useCallback, useEffect, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';

import firebase, { auth, db, remoteConfig } from 'utils/firebase';
import { useProvider } from 'hooks/provider';
import { useSystem } from 'hooks/system';
import api from 'services/api';
import { MySwal } from 'utils/MySwal';
import { CNPJInput } from 'components/SupportChangeAccountButton/style';
import ShowPlanStatus from 'components/ShowPlanStatus';
import { useDashboard } from '../dashboard';
import { IAccount, ILogicReturn } from './types';

const useAuthLogic = (): ILogicReturn => {
  const [isLoginErrored, setIsLoginErrored] = useState(false);
  const { provider } = useProvider();
  const [user, loadingUser] = useAuthState(auth);
  const { setIsLoading, cleanSessionDashboard } = useDashboard();
  const { handleProviderSubscription, cleanSessionProvider, setIsLoadingProvider, isAccountNotPopulated } =
    useProvider();
  const { cleanSessionSystem, handleSystemSubscription } = useSystem();
  const [indicationCode, setIndicationCode] = useState<string>('');

  const [account, setAccount] = useState<IAccount>({} as IAccount);

  const [queryAccount, setQueryAccount] = useState<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> | undefined
  >();
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [wasWarningShow, setWasWarningShow] = useState(false);
  const [wasTermsShow, setWasTermsShow] = useState(false);

  useEffect(() => {
    if (!loadingUser && queryAccount && !queryAccount?.empty) {
      if (!!account?.isNotCollectable || account?.paymentStatus !== 'blocked') {
        setWasWarningShow(true);
      } else if (!wasWarningShow) {
        setWasWarningShow(true);
        MySwal({
          showCancelButton: false,
          showConfirmButton: false,
          title: <strong>Aviso</strong>,
          icon: 'warning',
          html: (
            <>
              <div style={{ marginBottom: -20 }}>
                <ShowPlanStatus
                  setIsLoading={setIsLoading}
                  status={account?.paymentStatus}
                  uid={account?.uid}
                  isDocumentAndNameNotSet={!provider?.company_doc || !provider?.company_name}
                />
              </div>
            </>
          ),
        });
      }
    }
  }, [
    account?.isNotCollectable,
    account?.paymentStatus,
    account?.uid,
    loadingUser,
    provider?.company_doc,
    provider?.company_name,
    provider?.name,
    queryAccount,
    setIsLoading,
    wasWarningShow,
  ]);

  useEffect(() => {
    const code = localStorage.getItem('@AdminWebAppProvedor:indicationCode');
    if (code) {
      setIndicationCode(code);
    }
  }, []);

  useEffect(() => {
    if (user?.uid) {
      setLoadingAccount(true);

      const unsub = db
        .collection(`accounts`)
        .where('uid', '==', user?.uid)
        .limit(1)
        .onSnapshot(
          queryAccountSnap => {
            setQueryAccount(oldQueryAccountSnap => {
              if (!!oldQueryAccountSnap && !oldQueryAccountSnap?.empty && queryAccountSnap.empty) {
                signOut();
              }
              if (queryAccountSnap.empty) {
                return undefined;
              }
              return queryAccountSnap;
            });

            setLoadingAccount(false);
          },
          () => {
            signOut();
            setLoadingAccount(false);
          },
        );
      return unsub;
    }
    return () => {
      null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  useEffect(() => {
    if (!loadingAccount && !loadingUser) {
      if (user?.uid) {
        if (queryAccount) {
          user?.getIdToken().then(async () => {
            if (!queryAccount.empty) {
              const docAccount = queryAccount.docs[0];

              if (docAccount.exists) {
                const accountData = docAccount.data();
                const accountSetData = {
                  uid: accountData.uid,
                  provider: accountData?.provider?.path,
                  paymentStatus: accountData?.payment_status || 'non-billable',
                  subQuantity: accountData?.sub_quantity,
                  isNotCollectable: !!accountData?.is_not_collectable,
                  deleteOnCancel: !!accountData?.delete_on_cancel,
                  isSupport: !!accountData?.is_support,
                  isTermsAccepted: !!accountData?.is_terms_accepted,
                };

                setAccount(accountSetData);
              }
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryAccount, loadingAccount, loadingUser]);

  useEffect(() => {
    const isAccountPopulated = !!user && isAccountNotPopulated;

    if (!loadingUser && !account?.provider && !isAccountPopulated) {
      setIsLoading(false);
    }
  }, [account.provider, loadingUser, isAccountNotPopulated, setIsLoading, user]);

  useEffect(() => {
    if (!loadingUser && !user?.uid) {
      setIsLoadingProvider(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUser]);

  useEffect(() => {
    if (!loadingUser && user?.uid && account) {
      if (account?.provider) {
        handleProviderSubscription(account?.provider);
        handleSystemSubscription(account?.provider);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const cleanSessionAuth = useCallback(() => {
    setQueryAccount(undefined);
    setAccount({} as IAccount);
    setWasTermsShow(false);
  }, []);

  const cleanSession = useCallback(() => {
    cleanSessionDashboard();
    cleanSessionProvider();
    cleanSessionSystem();
    cleanSessionAuth();
  }, [cleanSessionAuth, cleanSessionDashboard, cleanSessionProvider, cleanSessionSystem]);

  const signOut = useCallback(() => {
    auth.signOut().then(cleanSession);
  }, [cleanSession]);

  const [isTermsSelected, setIsTermsSelected] = useState(false);

  useEffect(() => {
    if (
      !loadingUser &&
      queryAccount &&
      !queryAccount?.empty &&
      !wasTermsShow &&
      account.uid &&
      !account.isTermsAccepted
    ) {
      const handleConfirm = () => {
        let currentValue = false;
        setIsTermsSelected(oldTermsSelected => {
          currentValue = oldTermsSelected;
          return oldTermsSelected;
        });
        if (currentValue) {
          user?.getIdToken().then(() => queryAccount.docs[0].ref.update('is_terms_accepted', true));
        } else {
          showAlert();
        }
      };
      const showAlert = () => {
        MySwal({
          showCancelButton: true,
          showConfirmButton: true,
          customClass: {
            container: 'container-swal-wide',
            htmlContainer: 'html-container-swal-wide',
            actions: 'actions-swal-wide',
          },
          heightAuto: false,
          cancelButtonText: 'Não aceitar',
          confirmButtonText: 'Confirmar',
          html: (
            <>
              <iframe
                id="web-terms-loader"
                // className="iframe-placeholder"
                srcDoc="<style>@keyframes rotation {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}div {min-height: 300px;background-color: none !important;}span {visibility: visible;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;-webkit-font-smoothing: antialiased;color: rgb(18, 52, 71);word-break: break-word;overflow-wrap: break-word;border-width: 3px;border-style: solid;border-color: #602f58 rgb(235, 239, 243) rgb(235, 239, 243);border-image: initial;border-radius: 50%;width: 30px;height: 30px;animation: 1s linear 0s infinite normal none running rotation;margin: auto;position: absolute;inset: 0px;box-sizing: border-box;}</style><html><body><div><span /><div/></body></html>"
                title="loader"
              />
              <iframe
                id="web-terms"
                src={remoteConfig.getString('web_terms_url')}
                title="description"
                onLoad={() => {
                  const loader = document.getElementById('web-terms-loader');
                  const terms = document.getElementById('web-terms');
                  loader && (loader.style.display = 'none');
                  terms && (terms.style.display = 'block');
                }}
              />
              <div style={{ marginTop: 19 }}>
                <input
                  onChange={() => setIsTermsSelected(oldTermsSelected => !oldTermsSelected)}
                  type="checkbox"
                  id="scales"
                  name="scales"
                  style={{ marginRight: 5 }}
                />
                <label htmlFor="scales">Li e aceito os termos de uso.</label>
                <span style={{ display: 'block', color: 'red' }}>
                  *Você deve aceitar os termos para utilizar o App Provedor.
                </span>
              </div>
            </>
          ),
          preConfirm: handleConfirm,
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
        }).then(({ isConfirmed }) => !isConfirmed && signOut());
      };
      remoteConfig.fetchAndActivate().then(() => showAlert());
      setWasTermsShow(true);
    }
  }, [
    isTermsSelected,
    account.isTermsAccepted,
    account.uid,
    loadingUser,
    queryAccount,
    setIsLoading,
    signOut,
    user,
    wasTermsShow,
  ]);

  const scheduleDelete = useCallback(() => {
    setIsLoading(true);
    api
      .delete('/viewAccountOnScheduleDelete')
      .then(() => {
        setIsLoading(false);
        MySwal({
          showCancelButton: false,
          confirmButtonText: 'Entendi',
          title: <strong>Deleção agendada</strong>,
          icon: 'success',
          html: (
            <>
              Ao final do período de faturamento vamos excluir a conta, caso seja solicitada a renovação da inscrição
              antes do final do período a conta não será apagada.
            </>
          ),
        });
      })
      .catch(() => {
        setIsLoading(false);
        MySwal({
          showCancelButton: false,
          confirmButtonText: 'Entendi',
          title: <strong>Falha ao agendar deleção</strong>,
          icon: 'error',
          html: <>Ocorreu uma falha ao agendar, por favor tente novamente mais tarde.</>,
        });
      });
  }, [setIsLoading]);

  const renewSub = useCallback(() => {
    setIsLoading(true);
    api
      .post('/viewAccountOnScheduleRenew')
      .then(() => {
        setIsLoading(false);
        MySwal({
          showCancelButton: false,
          confirmButtonText: 'Entendi',
          title: <strong>Deleção agendada cancelada</strong>,
          icon: 'success',
          html: <>Pronto, sua conta não será mais deletada.</>,
        });
      })
      .catch(() => {
        setIsLoading(false);
        MySwal({
          showCancelButton: false,
          confirmButtonText: 'Entendi',
          title: <strong>Falha ao desistir do cancelamento</strong>,
          icon: 'error',
          html: <>Ocorreu uma falha ao desistir do cancelamento, por favor tente novamente mais tarde.</>,
        });
      });
  }, [setIsLoading]);

  const handleClickSupportChangeAccount = () => {
    if (queryAccount?.docs?.[0]?.exists) {
      const { is_support: isSupport, uid } = queryAccount.docs[0].data() || {};

      const setProvider = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const email = document.querySelector('input#email')?.value;

        if (email) {
          return api
            .get<{ uid: string }>(`/viewAdminGetUserUidByEmail/${encodeURIComponent(email)}`)
            .then(res => db.collection(`accounts`).doc(res.data.uid).get())
            .then(accountRef => {
              if (accountRef.exists) {
                const accountData = accountRef.data();

                return queryAccount?.docs?.[0].ref.set({
                  ...accountData,
                  stripeId: null,
                  is_support: true,
                  uid,
                });
              }
              return Promise.resolve();
            })
            .then(() =>
              MySwal({
                showCancelButton: false,
                confirmButtonText: 'Entendi',
                title: <strong>Conta trocada</strong>,
                icon: 'success',
                html: <></>,
              }).then(() => document.location.reload()),
            )
            .catch(err => {
              const newMessage = {
                404: 'Provedor não encontrado',
              };

              MySwal({
                showCancelButton: false,
                confirmButtonText: 'Entendi',
                title: <strong>Algo deu errado</strong>,
                icon: 'error',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                html: <>{newMessage?.[err.response.status] || err.message}</>,
              });
            });
        }

        return MySwal({
          showCancelButton: false,
          confirmButtonText: 'Entendi',
          title: <strong>Algo deu errado</strong>,
          icon: 'error',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          html: <>Provedor não encontrado</>,
        });
      };

      // confirm if is support
      if (isSupport) {
        MySwal({
          showCancelButton: false,
          confirmButtonText: 'Trocar',
          preConfirm: setProvider,
          title: <strong>Trocar de conta</strong>,
          icon: 'question',
          html: <CNPJInput id="email" type="text" placeholder="Insira o e-mail do provedor" />,
          showLoaderOnConfirm: true,
        });
      }
    }
  };

  const saveIndicationCode = (code?: string) => {
    if (code) {
      const newCode = code.replace(/[^a-z0-9]/gi, '').slice(0, 8);
      if (newCode.length === 8) {
        localStorage.setItem('@AdminWebAppProvedor:indicationCode', newCode);
        setIndicationCode(newCode);
      }
    }
  };

  const cleanIndication = () => {
    localStorage.removeItem('@AdminWebAppProvedor:indicationCode');
    setIndicationCode('');
  };

  const handleGoogleAuth = useCallback(() => {
    const authProvider = new firebase.auth.GoogleAuthProvider();
    authProvider.setCustomParameters({
      prompt: 'select_account',
    });
    auth.signInWithRedirect(authProvider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    wasTermsShow,
    isLoginErrored,
    setIsLoginErrored,
    loadingUser,
    user,
    account,
    signOut,
    cleanSession,
    scheduleDelete,
    renewSub,
    handleClickSupportChangeAccount,
    saveIndicationCode,
    cleanIndication,
    indicationCode,
    handleGoogleAuth,
  };
};

export default useAuthLogic;
