import { FC } from 'react';
import { ContainerBase, LabelInputBase } from '../style';
import { Input } from './style';
import { Props } from './types';

const InputDoc: FC<Props> = ({ label, name, required }: Props) => {
  return (
    <ContainerBase>
      <LabelInputBase htmlFor={name}>
        {label}
        &nbsp;
        {required && <span>*</span>}
        <Input id={name} name={name} required={required} />
      </LabelInputBase>
    </ContainerBase>
  );
};

export default InputDoc;
