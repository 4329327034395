import { ISkin } from 'hooks/skin/types';

import { useSystem } from 'hooks/system';
import { Portrait, TopBezel, BackgroundBase, Background } from './style';
import { SpeedTest, Consumption, Billing, Contact } from './Module';
import { MainHeader } from './Header';

interface Props {
  img?: string;
  skin?: ISkin;
  id?: string;
  children?: React.ReactNode;
}

const Skin: React.FC<Props> = ({ img, skin, id, children }: Props) => {
  const { systems } = useSystem();
  const billingActive =
    !!systems && ((systems[0] && systems[0].data().modules?.billing?.is_on === true) || !systems[0]);
  const speedTestActive =
    !!systems && ((systems[0] && systems[0].data().modules?.speed_test?.is_on === true) || !systems[0]);
  const consumptionActive =
    !!systems && ((systems[0] && systems[0].data().modules?.consumption?.is_on === true) || !systems[0]);
  const releasingPointActive =
    !!systems && ((systems[0] && systems[0].data().modules?.releasing_point?.is_on === true) || !systems[0]);
  const ticketActive = !!systems && ((systems[0] && systems[0].data().modules?.ticket?.is_on === true) || !systems[0]);
  const changePlanActive =
    !!systems && ((systems[0] && systems[0].data().modules?.change_plan?.is_on === true) || !systems[0]);

  let RealBackground;

  if (skin) {
    RealBackground = BackgroundBase;
  } else {
    RealBackground = Background;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Portrait id={id}>
      {/* background */}
      <TopBezel>
        <svg width="196" height="30" viewBox="0 0 196 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M192 4.49978C192 3 194.251 0.496646 195.5 0.499775C195.5 0.499775 -0.999946 -0.000695497 1.00003 0.499775C3 1.00025 5.00003 3.00025 5.00003 4.50002C5.00003 5.99979 2.99989 29 23 29C43.0001 29 149.5 29.5116 172 29C194.5 28.4884 192 5.99955 192 4.49978Z"
            fill="black"
          />
        </svg>
      </TopBezel>
      {children}
      <MainHeader img={img} skin={skin} id={id} />
      <RealBackground color={skin?.general?.backgroundColor}>
        <Billing skin={skin} isShown={billingActive} />
        <Consumption
          skin={skin}
          consumptionActive={consumptionActive}
          releasingPointActive={releasingPointActive}
          changePlanActive={changePlanActive}
        />
        <SpeedTest skin={skin} isShown={speedTestActive} />
        <Contact skin={skin} isShown={ticketActive} />
      </RealBackground>
    </Portrait>
  );
};

export default Skin;
