import styled from 'styled-components';

export const TitleOptionsBody = styled.div`
  > button {
    visibility: visible;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    z-index: 1000;
    background-color: rgba(7, 18, 40, 0.4);
    position: absolute;
    left: 16px;
    top: 16px;
    min-width: 80px;
    border-radius: 8px;
    height: 32px;
    font-size: 0.875rem;
    color: rgb(255, 255, 255);
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.2s ease 0s;
    text-transform: capitalize;

    > svg {
      margin-left: 9px;
      visibility: visible;
      -webkit-font-smoothing: antialiased;
      word-break: break-word;
      overflow-wrap: break-word;
      font-size: 0.875rem;
      color: rgb(255, 255, 255);
      font-weight: 600;
      cursor: pointer;
      text-transform: capitalize;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      vertical-align: bottom;
      float: left;
    }

    > p {
      visibility: visible;
      -webkit-font-smoothing: antialiased;
      word-break: break-word;
      overflow-wrap: break-word;
      font-size: 0.875rem;
      color: rgb(255, 255, 255);
      font-weight: 600;
      cursor: pointer;
      text-transform: capitalize;
      box-sizing: border-box;
    }
  }
`;
