/* eslint-disable camelcase */
import { useCallback, useState } from 'react';

import firebase, { db } from 'utils/firebase';
import { ISkin, ILogicReturn } from './types';

const useSkinLogic = (): ILogicReturn => {
  const [temporarySkin, setTemporarySkin] = useState({} as ISkin);
  const [skinThemes, setSkinThemes] = useState<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>();
  const [isLoadingThemes, setIsLoadingThemes] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<string>();
  const [isSendButtonShown, setIsSendButtonShown] = useState(false);

  const requestThemes = useCallback(() => {
    setSkinThemes(oldThemes => {
      setIsLoadingThemes(oldLoading => {
        if (oldThemes === undefined && !oldLoading) {
          setIsLoadingThemes(true);
          db.collection('skins')
            .get()
            .then(skins => setSkinThemes(skins))
            .finally(() => setIsLoadingThemes(false));
        }
        return oldLoading;
      });
      return oldThemes;
    });
  }, []);

  return {
    temporarySkin,
    setTemporarySkin,
    requestThemes,
    skinThemes,
    isLoadingThemes,
    currentSelected,
    setCurrentSelected,
    isSendButtonShown,
    setIsSendButtonShown,
  };
};

export default useSkinLogic;
