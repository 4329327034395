import styled, { css } from 'styled-components';

interface IndicationModalMenuItemProps {
  backgroundColor?: string;
  highlighted?: boolean;
}

export const IndicationModalMenuItem = styled.button<IndicationModalMenuItemProps>`
  width: 100%;
  background-color: ${props => props.backgroundColor || '#fff'};
  border-radius: 8px;
  padding: 16px;
  margin-top: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  text-align: left;
  border: none;
  border: 2px solid rgb(255, 255, 255);

  > p {
    float: right;
    font-size: 16px !important;
    font-weight: bold;
    color: #123447;
    flex: 1;
  }

  &:hover {
    border: 2px solid rgb(44, 92, 197);
    transition: all 0.05s ease-out 0s;
  }

  ${props =>
    props.highlighted &&
    css`
      background-color: rgb(0, 96, 99);

      > p {
        color: white !important;
      }

      > svg path {
        fill: white !important;
      }
    `}
`;
