/* eslint-disable camelcase */

import { useProvider } from 'hooks/provider';
import { ISkin } from 'hooks/skin/types';
import {
  Container,
  ContainerBase,
  MyAccountButton,
  Title,
  NotificationButton,
  NotificationButtonBase,
  MyAccountButtonBase,
  Paragraph,
  Div,
  H2,
  ParagraphBase,
  DivBase,
  H2Base,
} from './style';
import { UserIcon, NotificationBellIcon } from '../../Icon';

interface Props {
  img?: string;
  skin?: ISkin;
  id?: string;
}

const Main: React.FC<Props> = ({ img, skin, id }: Props) => {
  const { provider } = useProvider();

  let RealContainer;
  let RealNotificationButton;
  let RealMyAccountButton;
  let RealParagraph;
  let RealDiv;
  let RealH2;

  if (skin) {
    RealContainer = ContainerBase;
    RealNotificationButton = NotificationButtonBase;
    RealMyAccountButton = MyAccountButtonBase;
    RealParagraph = ParagraphBase;
    RealDiv = DivBase;
    RealH2 = H2Base;
  } else {
    RealContainer = Container;
    RealNotificationButton = NotificationButton;
    RealMyAccountButton = MyAccountButton;
    RealParagraph = Paragraph;
    RealDiv = Div;
    RealH2 = H2;
  }

  return (
    <RealContainer color={skin?.main?.header?.backgroundColor}>
      <Title>
        <img alt="logo" src={(id === 'main' && img) || './assets/images/image_placeholder.svg'} />
        <div className="name-and-my-account">
          <div style={{ marginLeft: 65, display: 'flex' }}>
            <RealNotificationButton color={skin?.main?.header?.my_account_button?.backgroundColor}>
              <NotificationBellIcon skin={skin} selector="skin.main.header.my_account_button.circle.color" />
            </RealNotificationButton>
            <RealMyAccountButton color={skin?.main?.header?.my_account_button?.backgroundColor}>
              <RealParagraph color={skin?.main?.header?.my_account_button?.color}>Desconectar</RealParagraph>
              <RealDiv color={skin?.main?.header?.my_account_button?.circle?.backgroundColor}>
                <UserIcon skin={skin} selector="skin.main.header.my_account_button.circle.color" />
              </RealDiv>
            </RealMyAccountButton>
          </div>
          <RealH2 color={skin?.main?.header?.title?.color}>
            {provider?.temporary_name || provider?.name || 'Nome do seu provedor'}
          </RealH2>
        </div>
      </Title>
    </RealContainer>
  );
};

export default Main;
