import styled from 'styled-components';

export const IndicationButton = styled.button`
  position: absolute;
  bottom: 36px;
  right: 140px;
  padding: 8px;
  border: none;
  z-index: 2147483648;
  display: flex;
  border-radius: 30px 8px 30px 30px;

  > p {
    margin: auto;
    font-size: 0.9rem;
    font-weight: 600;
    font-weight: bold;
    float: left;
    margin: 0px 8px 0px 6px;
    color: #602f58;
  }

  > svg {
    height: 16px;
    width: 16px;
    float: left;
    circle,
    line {
      stroke: #602f58;
    }
  }
`;

export const ModalCloseButton = styled.button`
  visibility: visible;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  overflow-wrap: break-word;
  color: white;
  box-sizing: border-box;
  z-index: 1000;
  background-color: rgba(7, 18, 40, 0.4);
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border: none;

  > svg {
    visibility: visible;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    overflow-wrap: break-word;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    fill: rgb(255, 255, 255);
    margin: 0px auto;
    display: block;
    width: 32px;
    height: 32px;
    transition: all 0.1s ease-in 0s;
  }
`;

export const IndicationModalBody = styled.div`
  position: absolute;
  bottom: 77px;
  right: 140px;
  width: 420px;
  min-height: 250px;
  max-height: 90%;
  background-color: #f5f7f9;
  border-radius: 10px;
  box-shadow: 2px 0px 11px rgb(0 0 0 / 34%);

  > div.title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 16px;
    background: linear-gradient(218.44deg, rgb(129, 61, 218) 0%, rgb(155, 6, 148) 100%) 0% 0% / contain;
    height: 160px;
  }

  > div.menu {
    margin-top: -110px;
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 20px;
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;

    @media (min-height: 320px) {
      max-height: 64vh;
    }

    @media (min-height: 360px) {
      max-height: 66vh;
    }

    @media (min-height: 400px) {
      max-height: 68vh;
    }

    @media (min-height: 440px) {
      max-height: 70vh;
    }

    @media (min-height: 480px) {
      max-height: 72vh;
    }

    @media (min-height: 600px) {
      max-height: 74vh;
    }

    @media (min-height: 650px) {
      max-height: 76vh;
    }

    @media (min-height: 700px) {
      max-height: 80vh;
    }

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
`;

export const IndicationMenuItemBodyBase = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left;
  border: none;
`;

export const IndicationBox = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 10px;
  display: flex;

  > span {
    flex: 1;
    max-width: 3px;
    background-color: #8f4d7f;
    border-radius: 5px;
  }

  > div {
    flex: 1;
    text-align: center;
    background-color: #f4f4f4;
  }
`;
