import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;

  > div {
    flex: 1;
  }
`;

export const PlanIcon = styled.svg`
  height: 100%;
  width: 100px;
  min-width: 100px;
  margin-right: 15px;
  transform: rotate(0deg) !important;
`;

export const PlanContainer = styled.div`
  flex: 1;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const PriceSection = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const PlanTitle = styled.h1`
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #838383;
`;

export const PlanSubTitle = styled.p`
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: ${props => props.color || '#838383'};
`;

export const PayButton = styled.button`
  padding: 10px;
  border-radius: 5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #fff;

  background-color: #602f58;
  border: none;

  :not(:disabled) {
    :hover {
      transition: background 0.3s ease;
      background-color: #96498a;
    }

    :active,
    :focus {
      transition: box-shadow 0.15s ease;
      box-shadow: 0 0 0 0.2rem #79366f;
    }
  }

  :disabled {
    background-color: #999;
    color: #eee;
  }
`;
