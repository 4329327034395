import { ToastContainer, Zoom } from 'react-toastify';
import { AlertContainer } from './style';
import 'react-toastify/dist/ReactToastify.css';

const Alert: React.FC = () => {
  return (
    <>
      <AlertContainer>
        <ToastContainer transition={Zoom} limit={2} />
      </AlertContainer>
    </>
  );
};

export default Alert;
