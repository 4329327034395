import { ResetWizardButton } from 'pages/Dashboard/style';
import styled from 'styled-components';

export const SendSupportMessageButton = styled(ResetWizardButton)`
  right: 30px;
  background-color: #006063;

  > p {
    color: #fff;
  }

  > svg {
    height: 19px;
    width: 19px;
    circle,
    line,
    path {
      stroke: #fff;
    }
  }

  > span {
    background-color: orange;
    color: #fff;
    font-size: 15px !important;
    height: 24px;
    width: 24px;
    padding: 3.5px;
    border-radius: 15px;
    position: absolute;
    top: -10px;
    right: 0px;
  }
`;
