/* eslint-disable @typescript-eslint/ban-ts-comment */
// Opera 8.0+
// @ts-ignore
export const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
// @ts-ignore
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  // @ts-ignore
  /constructor/i.test(window.HTMLElement) ||
  // eslint-disable-next-line func-names
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
    // @ts-ignore
  })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
// @ts-ignore
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE;

// Chrome 1 - 71
// @ts-ignore
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;
