/* eslint-disable camelcase */

import { ReactComponent as Phone } from 'assets/phone.svg';
import { ReactComponent as Mail } from 'assets/mail.svg';
import { ReactComponent as Whatsapp } from 'assets/whatsapp.svg';
import { ReactComponent as Facebook } from 'assets/facebook.svg';
import { ReactComponent as Instagram } from 'assets/instagram.svg';
import { ReactComponent as Telegram } from 'assets/telegram.svg';
import { ReactComponent as Webchat } from 'assets/webchat.svg';
import { ReactComponent as Website } from 'assets/website.svg';
import genKey from 'utils/genKey';
import { useProvider } from 'hooks/provider';
import { ISkin } from 'hooks/skin/types';
import { IContact } from 'hooks/provider/types';
import { ContactIcon, TapIcon } from '../../Icon';
import { Container, ContainerBase, Title, ContactList, CardButtonContainer } from './style';
import {
  SubTitle,
  SubTitleBase,
  TitleText,
  TitleTextBase,
  CardButtonBase,
  CardButton,
  ButtonTextBase,
  ButtonText,
} from '../../style';

interface Props {
  skin?: ISkin;
  isShown: boolean;
}

const Contact: React.FC<Props> = ({ skin, isShown }: Props) => {
  const { provider } = useProvider();

  let RealContainer;
  let RealTitleText;
  let RealSubTitle;
  let RealCardButton;
  let RealButtonText;

  if (skin) {
    RealContainer = ContainerBase;
    RealTitleText = TitleTextBase;
    RealSubTitle = SubTitleBase;
    RealCardButton = CardButtonBase;
    RealButtonText = ButtonTextBase;
  } else {
    RealContainer = Container;
    RealTitleText = TitleText;
    RealSubTitle = SubTitle;
    RealCardButton = CardButton;
    RealButtonText = ButtonText;
  }

  const contactList = provider?.contacts?.map((contact: IContact) => {
    if (skin) {
      RealSubTitle = SubTitleBase;
    } else {
      RealSubTitle = SubTitle;
    }

    const selectIcon = () => {
      switch (contact.target) {
        case 'phone':
          return <Phone />;
        case 'email':
          return <Mail />;
        case 'whatsapp':
          return <Whatsapp />;
        case 'facebook':
          return <Facebook />;
        case 'instagram':
          return <Instagram />;
        case 'telegram':
          return <Telegram />;
        case 'webchat':
          return <Webchat />;
        case 'website':
          return <Website />;
        default:
          return <Phone />;
      }
    };

    return (
      <div key={genKey()}>
        <div>{selectIcon()}</div>
        <RealSubTitle color={skin?.main?.cards?.color}>{contact.title}</RealSubTitle>
      </div>
    );
  });

  return (
    <RealContainer isShown color={skin?.main?.cards?.backgroundColor}>
      <Title>
        <ContactIcon selector="skin.main.cards.color" skin={skin} />
        <RealTitleText color={skin?.main?.cards?.color}>Atendimento</RealTitleText>
      </Title>
      <ContactList>{contactList}</ContactList>

      <CardButtonContainer isShown={isShown} className="module-container ticket_is_on">
        <RealCardButton type="button" color={skin?.main?.cards?.button?.backgroundColor}>
          <div>
            <TapIcon selector="skin.main.cards.button.color" skin={skin} />
            <RealButtonText color={skin?.main?.cards?.button?.color}>Meus atendimentos</RealButtonText>
          </div>
        </RealCardButton>
      </CardButtonContainer>
    </RealContainer>
  );
};

export default Contact;
