import { useCallback, useState } from 'react';
import { ILogicReturn } from './types';

const useModalLogic = (): ILogicReturn => {
  const [tabs, setTabs] = useState<Array<boolean>>([]);
  const [isComeBackShown, setIsComeBackShown] = useState(false);
  const [isOpen, setIsOpen] = useState(0);
  const registerTab = (isOpenValue = false) => setTabs(oldTabs => oldTabs.concat(isOpenValue));

  const closeTab = (pos: number, value?: boolean, needAllClose?: boolean) =>
    setTabs(oldTabs => {
      if (needAllClose) {
        const tabsClosed = oldTabs.reduce((pV, cV) => (cV === false ? pV + 1 : pV), 0);
        if (tabsClosed !== oldTabs.length) {
          return oldTabs;
        }
      }
      const newValue = !oldTabs[pos];
      const newTabs = newValue ? oldTabs.map(() => false) : [...oldTabs];
      newTabs[pos] = value ?? newValue;
      return newTabs;
    });

  const handleClickComeBack = useCallback(() => {
    closeTab(isOpen);
    setIsComeBackShown(false);
  }, [isOpen]);

  return {
    tabs,
    registerTab,
    closeTab,
    isComeBackShown,
    handleClickComeBack,
    setIsComeBackShown,
    isOpen,
    setIsOpen,
  };
};

export default useModalLogic;
