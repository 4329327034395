/* eslint-disable camelcase */

import OpenLock from 'components/Skin/Icon/OpenLock';
import Lock from 'components/Skin/Icon/Lock';
import { ISkin } from 'hooks/skin/types';
import {
  Container,
  ContainerBase,
  Title,
  ConsumptionStatus,
  Subtitle,
  ContainerModule,
  BackgroundColor,
  BackgroundColorBase,
} from './style';
import { ConsumptionIcon, TapIcon } from '../../Icon';
import {
  SubTitleBase,
  SubTitle,
  defaultStyle,
  TitleText,
  TitleTextBase,
  CardButtonBase,
  CardButton,
  ButtonTextBase,
  ButtonText,
} from '../../style';

interface Props {
  skin?: ISkin;
  consumptionActive: boolean;
  releasingPointActive: boolean;
  changePlanActive: boolean;
}

const Consumption: React.FC<Props> = ({ skin, consumptionActive, releasingPointActive, changePlanActive }: Props) => {
  let RealContainer;
  let RealTitleText;
  let RealSubTitle;
  let RealCardButton;
  let RealButtonText;
  let RealBackgroundColor;

  if (skin) {
    RealContainer = ContainerBase;
    RealTitleText = TitleTextBase;
    RealSubTitle = SubTitleBase;
    RealCardButton = CardButtonBase;
    RealButtonText = ButtonTextBase;
    RealBackgroundColor = BackgroundColorBase;
  } else {
    RealContainer = Container;
    RealTitleText = TitleText;
    RealSubTitle = SubTitle;
    RealCardButton = CardButton;
    RealButtonText = ButtonText;
    RealBackgroundColor = BackgroundColor;
  }

  return (
    <RealContainer
      isShown={consumptionActive || releasingPointActive}
      className="skin.main.cards.backgroundColor module-container consumption_is_on releasing_point_is_on change_plan_is_on"
      style={{ backgroundColor: skin?.main?.cards?.backgroundColor }}
    >
      <Title>
        <ConsumptionIcon selector="skin.main.cards.color" skin={skin} />
        <RealTitleText style={{ color: skin?.main?.cards?.color || defaultStyle.main.cards.color }}>
          Meus pontos de internet
        </RealTitleText>
      </Title>

      <Subtitle>
        <RealSubTitle color={skin?.main?.cards?.color}>
          Casa
          <span />
        </RealSubTitle>

        <ContainerModule className="module-container releasing_point_is_on " isShown={releasingPointActive}>
          <div className="lock">
            <RealSubTitle color={skin?.main?.cards?.color}>Conexão bloqueada</RealSubTitle>
            <div>
              <Lock selector="skin.main.cards.color" skin={skin} />
            </div>
          </div>

          <ContainerModule
            className="module-container change_plan_is_on not_consumption_is_on"
            isShown={changePlanActive && !consumptionActive}
            minHeight={70}
          />

          <ContainerModule
            className="module-container change_plan_is_on not_consumption_is_on"
            isShown={changePlanActive && !consumptionActive}
          >
            <RealBackgroundColor className="releasing-point-color mini" color={skin?.main?.cards?.backgroundColor} />
          </ContainerModule>

          <ContainerModule className="module-container consumption_is_on" isShown={consumptionActive}>
            <RealBackgroundColor className="releasing-point-color" color={skin?.main?.cards?.backgroundColor} />
          </ContainerModule>

          <div className="releasing-point">
            <RealCardButton type="button" color={skin?.main?.cards?.button?.backgroundColor}>
              <OpenLock selector="skin.main.cards.button.color" skin={skin} />
              <RealButtonText color={skin?.main?.cards?.button?.color}>Liberar acesso</RealButtonText>
            </RealCardButton>
          </div>
        </ContainerModule>
      </Subtitle>

      <ContainerModule className="module-container consumption_is_on" isShown={consumptionActive}>
        <div className="consumption-status">
          <ConsumptionStatus color="#76D14D">
            <p>Download</p>
            <div>
              <h1>3.56</h1>
              <p>GB</p>
            </div>
          </ConsumptionStatus>
          <RealSubTitle color={skin?.main?.cards?.color}>/</RealSubTitle>
          <ConsumptionStatus color="#F98D48">
            <p>Upload</p>
            <div>
              <h1>239</h1>
              <p>MB</p>
            </div>
          </ConsumptionStatus>
        </div>
      </ContainerModule>

      <ContainerModule
        className="module-container consumption_is_on change_plan_is_on"
        isShown={consumptionActive || changePlanActive}
      >
        <RealCardButton type="button" color={skin?.main?.cards?.button?.backgroundColor}>
          <div>
            <TapIcon selector="skin.main.cards.button.color" skin={skin} />
            <span style={{ minWidth: 5 }} />
            <ContainerModule className="module-container consumption_is_on" isShown={consumptionActive}>
              <RealButtonText color={skin?.main?.cards?.button?.color}>Consumo</RealButtonText>
            </ContainerModule>
            <span style={{ minWidth: 5 }} />
            <ContainerModule className="module-container consumption_is_on" isShown={consumptionActive}>
              <ContainerModule className="module-container change_plan_is_on" isShown={changePlanActive}>
                <RealButtonText color={skin?.main?.cards?.button?.color}>e</RealButtonText>
              </ContainerModule>
            </ContainerModule>
            <span style={{ minWidth: 5 }} />
            <ContainerModule className="module-container change_plan_is_on" isShown={changePlanActive}>
              <RealButtonText color={skin?.main?.cards?.button?.color}>Planos</RealButtonText>
            </ContainerModule>
          </div>
        </RealCardButton>
      </ContainerModule>
    </RealContainer>
  );
};

export default Consumption;
