/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useModal } from '../../hooks/Modal';
import { ReactComponent as Arrow } from './assets/arrow.svg';

import { TitleOptionsBody } from './style';

const TitleOptions: React.FC = () => {
  const { isComeBackShown, handleClickComeBack } = useModal();

  return (
    <TitleOptionsBody>
      {isComeBackShown && (
        <button type="button" onClick={handleClickComeBack}>
          <Arrow />
          <p>Voltar</p>
        </button>
      )}
    </TitleOptionsBody>
  );
};

export default TitleOptions;
