import { Switch, Redirect } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Redirect to={user ? '' : '/dashboard'} />
    </Switch>
  );
};

export default Routes;
