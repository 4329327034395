/* eslint-disable camelcase */
import React from 'react';
import pixCopyPasteIcon from 'assets/pixCopyPaste.png';
import digitableLineIcon from 'assets/digitableLine.png';
import printBillIcon from 'assets/print.png';
import { ISkin } from 'hooks/skin/types';
import { Container, ContainerBase, Title, MiniCard, MiniCardText, MiniCardTextBase, MiniCardBase } from './style';
import {
  SubTitle,
  SubTitleBase,
  TitleText,
  TitleTextBase,
  CardButtonBase,
  CardButton,
  ButtonTextBase,
  ButtonText,
} from '../../style';
import { BillIcon, CalendarIcon, InfoIcon, TapIcon } from '../../Icon';

interface Props {
  skin?: ISkin;
  isShown: boolean;
}

const Billing: React.FC<Props> = ({ skin, isShown }: Props) => {
  let RealContainer;
  let RealTitleText;
  let RealSubTitle;
  let RealMiniCard;
  let RealMiniCardText;
  let RealCardButton;
  let RealButtonText;

  if (skin) {
    RealContainer = ContainerBase;
    RealTitleText = TitleTextBase;
    RealSubTitle = SubTitleBase;
    RealMiniCardText = MiniCardTextBase;
    RealCardButton = CardButtonBase;
    RealButtonText = ButtonTextBase;
    RealMiniCard = MiniCardBase;
  } else {
    RealContainer = Container;
    RealTitleText = TitleText;
    RealSubTitle = SubTitle;
    RealMiniCardText = MiniCardText;
    RealCardButton = CardButton;
    RealButtonText = ButtonText;
    RealMiniCard = MiniCard;
  }

  return (
    <RealContainer
      isShown={isShown}
      className="skin.main.cards.backgroundColor module-container billing_is_on"
      color={skin?.main?.cards?.backgroundColor}
    >
      <Title>
        <BillIcon selector="skin.main.cards.color" skin={skin} />
        <RealTitleText color={skin?.main?.cards?.color}>Segunda via da fatura</RealTitleText>
      </Title>

      <RealSubTitle color={skin?.main?.cards?.color}>Última fatura aberta</RealSubTitle>

      <RealMiniCard color={skin?.main?.cards?.mini_cards?.backgroundColor}>
        <div style={{ display: 'flex' }}>
          <div>
            <CalendarIcon selector="skin.main.cards.mini_cards.color" skin={skin} />
            <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>10/06/2021</RealMiniCardText>
          </div>
          <div className="info">
            <InfoIcon selector="skin.main.cards.mini_cards.color" skin={skin} />
            <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>Mensalidade de internet</RealMiniCardText>
          </div>
        </div>

        <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>Vencimento:10/10/2021</RealMiniCardText>
        <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>Valor da fatura: 54,84</RealMiniCardText>
        <div style={{ marginTop: 10 }} />
        <div className="options">
          <div>
            <div>
              <div>
                <img src={pixCopyPasteIcon} alt="pix copy paste" />
              </div>
            </div>
            <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>Chave Pix</RealMiniCardText>
          </div>
          <div style={{ marginRight: 12 }} />
          <div>
            <div>
              <div>
                <img src={digitableLineIcon} alt="digitable line" />
              </div>
            </div>
            <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>Cód. Barras</RealMiniCardText>
          </div>
          <div style={{ marginRight: 12 }} />
          <div>
            <div>
              <div>
                <img src={printBillIcon} alt="print bill" />
              </div>
            </div>

            <RealMiniCardText color={skin?.main?.cards?.mini_cards?.color}>Imp. Boleto</RealMiniCardText>
          </div>
        </div>
      </RealMiniCard>

      <RealCardButton type="button" color={skin?.main?.cards?.button?.backgroundColor}>
        <div>
          <TapIcon selector="skin.main.cards.button.color" skin={skin} />
          <RealButtonText color={skin?.main?.cards?.button?.color}>Segunda via da fatura</RealButtonText>
        </div>
      </RealCardButton>
    </RealContainer>
  );
};

export default Billing;
