/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
import { useState, useCallback, Suspense, useEffect, useRef, lazy, FC } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useProvider } from 'hooks/provider';
import { useSystem } from 'hooks/system';
import Strip from 'components/Strip';
import { useAuth } from 'hooks/auth';
import SupportButton from 'components/SupportChangeAccountButton';
// import IndicationModal from 'components/IndicationModal';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';
import { joyrideStyles, joyrideLocale } from 'utils/joyride';
import { MyLoginSwal, MySwal } from 'utils/MySwal';
import SendSupportMessageButton from 'components/SendSupportMessageButton';
import {
  Content,
  Smartphone,
  Tabs,
  Menu,
  Tab,
  CurrentTab,
  PushNotification,
  LinkToApp,
  ResetWizardButton,
  Channels,
  ButtonsContainer,
} from './style';
import { ReactComponent as Bell } from '../../assets/bell.svg';
import { ReactComponent as Config } from '../../assets/config.svg';
import { ReactComponent as Monitor } from '../../assets/monitor.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Copy } from '../../assets/copy.svg';
import { ReactComponent as Footprints } from '../../assets/footprints.svg';
import { ReactComponent as Youtube } from '../../assets/youtube.svg';

import { useDashboard } from '../../hooks/dashboard';
import Skin from '../../components/Skin';

const MessagesTab = lazy(() => import('./tabs/MessagesTab'));
const CustomizationTab = lazy(() => import('./tabs/CustomizationTab'));
const SystemsTab = lazy(() => import('./tabs/SystemsTab'));
const MyAccountTab = lazy(() => import('./tabs/MyAccountTab'));

const Dashboard: FC = () => {
  const { account } = useAuth();
  const { provider, setTemporaryAttr } = useProvider();
  const { systems } = useSystem();
  const {
    setCurrentJoyride,
    Alert,
    isLoading,
    currentJoyride,
    currentTabRef,
    setCheckpoint,
    checkpoint,
    select,
    setSelect,
    resetWizard,
    setScroll,
    loaderMessage,
    setIsShowScroll,
  } = useDashboard();
  let showAlert = false;

  // const code = 'A6SZ5DX4';
  // const signUps = [
  //   { name: 'TesteNet', currentStep: 0 },
  //   { name: 'SuperaNet', currentStep: 1 },
  //   { name: 'SuperaNet', currentStep: 2 },
  //   { name: 'MaisNet', currentStep: 3 },
  //   { name: 'TesteNet', currentStep: 0 },
  //   { name: 'SuperaNet', currentStep: 1 },
  //   { name: 'SuperaNet', currentStep: 2 },
  //   { name: 'MaisNet', currentStep: 3 },
  //   { name: 'TesteNet', currentStep: 0 },
  //   { name: 'SuperaNet', currentStep: 1 },
  //   { name: 'SuperaNet', currentStep: 2 },
  //   { name: 'MaisNet', currentStep: 3 },
  // ];

  const pushRef = useRef<HTMLParagraphElement>(null);

  const contentPushContent = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackTab = (index: number) => {
    setSelect(index);

    if (select !== index) {
      setTemporaryAttr('name', provider?.name as string);
      if (contentPushContent[0] !== '') {
        contentPushContent[1]('');
      }
      const elsModules = Array.from(document.querySelectorAll(`.module-container`) as NodeListOf<HTMLElement>);
      elsModules.forEach(el => {
        if (el) {
          const { style } = el;
          style.display = '';
        }
      });
    }
  };

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      if (data.action === 'skip') {
        setCurrentJoyride('');
        setCheckpoint('dashboard', 'provider', 'system', 'ended');
        return;
      }

      if (data.action !== 'stop') {
        switch (data.index) {
          case 0:
            data.action !== 'reset' && callbackTab(0);
            break;
          case 1:
            callbackTab(1);
            break;
          case 2:
            callbackTab(2);
            break;
          case 3:
            callbackTab(3);
            if (data.status === 'finished') {
              callbackTab(1);
              setCurrentJoyride('');
              setCheckpoint('dashboard');
            }
            break;
          default:
            break;
        }
        setScroll(data);
      }
    },
    [callbackTab, setScroll, setCheckpoint, setCurrentJoyride],
  );

  if (
    !account?.isNotCollectable &&
    (account?.paymentStatus === 'warning' ||
      account?.paymentStatus === 'blocked' ||
      account?.paymentStatus === 'canceled')
  ) {
    showAlert = true;
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (systems?.find) {
      if (pushRef.current) {
        if (pushRef.current.offsetHeight >= 99) {
          contentPushContent[1](contentPushContent[0].slice(0, -1));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Alert, systems, contentPushContent[0], contentPushContent[1]]);

  useEffect(() => {
    if (
      account?.isTermsAccepted &&
      !checkpoint.dashboard &&
      !checkpoint.provider &&
      !checkpoint.system &&
      !checkpoint.ended
    ) {
      MySwal({
        iconHtml: `
          <img src="./assets/images/found.png" alt="First time" height="135px" />
        `,
        iconColor: 'white',
        showCancelButton: true,
        cancelButtonText: 'Não, obrigado',
        confirmButtonText: 'Sim, vamos lá',
        title: <strong>Gostaria de ajuda?</strong>,
        html: <>Como é a sua primeira vez por aqui, posso lhe auxiliar passo a passo.</>,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setCurrentJoyride('dashboard');
        } else {
          setIsShowScroll();
          setCheckpoint('ended');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.isTermsAccepted]);

  if (!account?.isTermsAccepted) {
    return <></>;
  }

  const steps: Array<Step> = [
    {
      target: '.tabs-step-0',
      title: 'Envio de Mensagens',
      content: 'Aqui você pode enviar mensagens para os seus clientes sincronizados.',
      disableBeacon: true,
      hideCloseButton: true,
    },
    {
      target: '.tabs-step-1',
      title: 'Personalização do App',
      content: 'Aqui você pode customizar as cores do App, informações de contato, logotipo e marca.',
      disableBeacon: true,
      hideCloseButton: true,
    },
    {
      target: '.tabs-step-2',
      title: 'Meu sistema',
      content: 'Aqui você pode integrar o seu sistema e decidir quais modulos estarão disponiveis para seu assinante.',
      disableBeacon: true,
      hideCloseButton: true,
    },
    {
      target: '.tabs-step-3',
      title: 'Minha conta',
      content: 'Aqui você pode ativar o faturamento, alterar sua senha, encerrar sessão ou apagar sua conta.',
      disableBeacon: true,
      hideCloseButton: true,
    },
  ];
  const isTourRunning = currentJoyride === 'dashboard' && !checkpoint.dashboard && !checkpoint.ended;

  const showContent = (value: number) => {
    switch (value) {
      case 1:
        return <CustomizationTab />;
      case 2:
        return <SystemsTab callbackTab={callbackTab} />;
      case 3:
        return <MyAccountTab />;
      case 0:
      default:
        return <MessagesTab statePush={contentPushContent} />;
    }
  };

  const TabsMenu = [
    { icon: <Bell />, text: <h3>Mensagens</h3>, key: 0 },
    { icon: <Config />, text: <h3>Personalizações</h3>, key: 1 },
    { icon: <Monitor />, text: <h3>Sistemas</h3>, key: 2 },
    { icon: <User />, text: <h3>Minha conta</h3>, key: 3 },
  ];

  const handleClickLinkToApp = () => {
    navigator.clipboard.writeText('https://www.provedor.app/baixar');
    Alert({ message: 'Link copiado', type: 'success', receivedAt: new Date() });
  };

  const handleOnClickShowChannel = () => {
    MyLoginSwal({
      html: (
        <iframe
          title="ytPlayer"
          id="ytplayer"
          width="872"
          height="496"
          src="https://www.youtube.com/embed/?listType=playlist&list=PLRxUlJZbGkmAGAvoY-0O7WZrpvDp6JJCu"
          frameBorder="0"
          allowFullScreen
        />
      ),
    });
  };

  return (
    <>
      <ReactJoyride
        continuous
        run={isTourRunning}
        styles={joyrideStyles}
        spotlightClicks
        locale={joyrideLocale}
        steps={steps}
        scrollToFirstStep={false}
        callback={handleJoyrideCallback}
        showSkipButton
        disableCloseOnEsc
        disableOverlayClose
        floaterProps={{
          disableAnimation: true,
        }}
      />
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Smartphone>
            <Skin img={provider?.logo} id="main">
              <PushNotification isShown={!!contentPushContent[0]}>
                <p className="title">{provider?.temporary_name || provider?.name || 'Nome do seu provedor'}</p>
                <p className="content" id="pushContent" ref={pushRef}>
                  {contentPushContent[0]}
                </p>
              </PushNotification>
            </Skin>
          </Smartphone>
          <LinkToApp onClick={handleClickLinkToApp}>
            <Copy />
            <p>Copiar link para download</p>
          </LinkToApp>
        </div>
        <Tabs>
          <div>
            <Menu>
              {TabsMenu.map((value, index) => {
                return (
                  <Tab
                    className={`tabs-step-${index}`}
                    key={value.key}
                    isSelected={select === index}
                    onClick={() => callbackTab(index)}
                  >
                    {value.icon}
                    {value.text}
                  </Tab>
                );
              })}
            </Menu>
          </div>
          <div>
            {showAlert && (
              <Strip
                blink
                message={
                  account?.paymentStatus === 'canceled' ? 'Atenção assinatura cancelada' : 'Atenção, fatura atrasada'
                }
                color={account?.paymentStatus === 'canceled' ? undefined : '#ffffff'}
                backgroundColor={account?.paymentStatus === 'canceled' ? undefined : '#ff0015'}
              />
            )}
            <CurrentTab ref={currentTabRef}>
              <Suspense
                fallback={
                  <div className="skeleton-suspense">
                    <div className="message">
                      <p>{loaderMessage}</p>
                    </div>
                    <SkeletonTheme color="#E3E6EA" highlightColor="#f0f3f8">
                      <Skeleton width="100%" height="100%" />
                    </SkeletonTheme>
                  </div>
                }
              >
                <div style={{ display: !isLoading ? 'block' : 'none' }}>{showContent(select)}</div>
              </Suspense>
              <div className="skeleton-suspense" style={{ display: isLoading ? 'block' : 'none' }}>
                <div className="message">
                  <p>{loaderMessage}</p>
                </div>
                <SkeletonTheme color="#E3E6EA" highlightColor="#f0f3f8">
                  <Skeleton width="100%" height="100%" />
                </SkeletonTheme>
              </div>
            </CurrentTab>
          </div>
        </Tabs>
      </Content>
      {/* <IndicationModal
        rulesLink={process.env.REACT_APP_INDICATION_RULES_LINK as string}
        code={code}
        signUps={signUps}
        messageProps={{
          subject: 'Você já conhece o App Provedor?',
          message: 'Olá, você já conhece o App Provedor? Se não use o meu link ',
          link: `https://${window.location.hostname}?code=${code}`,
          linkButtonCallback: () => Alert({ message: 'Link copiado', type: 'success', receivedAt: new Date() }),
        }}
      /> */}
      <Channels type="button" onClick={handleOnClickShowChannel}>
        <Youtube />
        <p>Vídeos</p>
      </Channels>
      <ResetWizardButton type="button" onClick={resetWizard}>
        <Footprints />
        <p>Passo a Passo</p>
      </ResetWizardButton>
      <ButtonsContainer>{account?.isSupport && <SupportButton />}</ButtonsContainer>
      <SendSupportMessageButton />
    </>
  );
};

export default Dashboard;
