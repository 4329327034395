import { IndicationMenuItemBodyBase } from 'components/IndicationModal/style';
import styled from 'styled-components';
import { ContainerBase } from '../Input/style';

export const ItemBody = styled(IndicationMenuItemBodyBase)`
  > span {
    margin: auto;
    padding: 20px;
  }

  > div.disabled {
    color: #999;
    > svg {
      &.more-info {
        color: #999 !important;
      }
    }
  }

  > div {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    margin: 5px 0 5px 0;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 5px;
    background-color: #eee;
    color: green;
    display: flex;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;

    > svg {
      align-self: center;
      margin: 5px;
      overflow: visible;

      &.warning {
        color: #ffc107 !important;
      }

      &.disabled {
        color: #999 !important;
      }

      &.more-info {
        width: 13px;
        height: 13px;
      }
    }

    > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
      flex: 1;
      margin-left: 5px;
      margin-right: 5px;
      align-self: center;
    }

    > span.notification {
      background-color: orange;
      color: #fff;
      font-size: 14px !important;
      height: 21px;
      width: 21px;
      padding-top: 3px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 6px;
      border-radius: 15px;
      position: relative;
      top: -10px;
      right: -14px;

      &.bigger {
        height: 28px;
        width: 28px;
        padding-top: 6px;
      }
    }
  }
`;

export const ModalBody = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  margin: auto;
`;

export const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  background-color: rgb(250, 250, 250);
  border-radius: 5px;
  padding: 10px;

  > div.message.left {
    > div.message-bubble {
      border-radius: 5px;
      border-top-left-radius: 0px;
    }
    > div.bubble-arrow {
      clip-path: polygon(100% 50%, 100% 0, 0 0);
    }
  }

  > div.message {
    margin-bottom: 5px;
    margin-top: 15px;
    margin-bottom: 15px;

    display: flex;

    span.spacer {
      flex: 1;
    }

    &.counter {
      > div.message-bubble {
        background: #ecf0fd;

        > div.header {
          > h4 {
            color: #2e49cc;
          }
        }
      }
      > div.bubble-arrow {
        background: #ecf0fd;
      }
    }

    > div.message-bubble {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: left;
      -webkit-box-direction: normal;
      pointer-events: auto;
      font-size: 14px;
      box-sizing: border-box;
      font-family: Arial, Helvetica, sans-serif;
      max-width: 85%;
      min-width: 220px;
      width: auto;
      height: auto;
      border-radius: 5px;
      border-top-right-radius: 0px;
      position: relative;
      box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
      background: #dcf8c6;
      float: right;
      padding: 10px;
      max-width: 70%;

      > div.header {
        > h4 {
          align-self: center;
          line-height: 1.5;
          -webkit-box-direction: normal;
          pointer-events: auto;
          text-align: left;
          box-sizing: border-box;
          font-family: Arial, Helvetica, sans-serif;
          user-select: none;
          font-weight: 600;
          font-size: 14px;
          display: inline-table;
          margin: 0;
          color: #2ecc51;
        }

        > p {
          align-self: center;
          font-size: 12px !important;
        }

        > button {
          background-color: #df4a4a;
          border-radius: 15px;
          height: 26px;
          width: 26px;
          margin-left: 5px;
          margin-top: -2px;
          border: solid 2px #fafafa;
          svg {
            height: 22px;
            width: 22px;
            color: #fff;
          }
        }
      }

      > div {
        display: flex;
      }

      > p {
        overflow-wrap: break-word;
        white-space: pre-line;
      }
    }

    > div.bubble-arrow {
      background-color: #dcf8c6;
      height: 20px;
      width: 20px;
      clip-path: polygon(0 0, 100% 0, 0 50%);
    }
  }
`;

export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > h5 {
    margin: auto;
    font-size: 20px;

    & ~ div {
      margin-top: 20px;
    }
  }

  > form {
    > ${ContainerBase} {
      margin-bottom: 7px;
    }

    > div.buttons-container {
      border-radius: 8px;
      background-color: #f5f7f9;
      list-style: none;
      text-align: left;
      padding: 10px 16px;
      display: flex;
      width: 100%;

      > span {
        flex: 1;
      }

      > button {
        list-style: none;
        box-sizing: border-box;
        font: inherit;
        overflow: visible;
        text-transform: none;
        font-family: inherit;
        background: 0 0;
        line-height: 1.2;
        transition: all 0.2s linear;
        letter-spacing: 0.3px;
        vertical-align: middle;
        outline: 0;
        display: inline-flex;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 500;
        height: 32px;
        min-width: 80px;
        background-color: #f3f5f7;
        border: 1px solid #cfd7df;
        background-image: linear-gradient(to bottom, #fff, #f3f5f7);
        color: #183247;
        padding: 2px 10px 3px 8px;
        margin: 0 2px;

        &.icon {
          min-width: 20px;

          > svg {
            color: #183247;
            width: 25px !important;
            height: 25px !important;

            path {
              fill: #183247;
            }
          }
        }

        &:hover {
          background-color: #f3f5f7;
          background-image: none;
        }

        &.danger {
          background-color: #d57f87;
          border: 1px solid #c5545f;
          background-image: linear-gradient(to bottom, #f9a6ae, #d57f87);
          color: #74090c;

          &:hover {
            background-color: #d57f87;
            background-image: none;
          }
        }

        &.success {
          color: #fff;
          background-color: #0e8f2b;
          border-color: #28a745;
          background-image: linear-gradient(to bottom, #28a745, #0e8f2b);

          &:hover {
            background-color: #0e8f2b;
            background-image: none;
          }

          > span.icon {
            min-width: 20px;

            > svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        > span.icon {
          list-style: none;
          font: inherit;
          text-transform: none;
          font-family: inherit;
          line-height: 1.2;
          letter-spacing: 0.3px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 500;
          color: #183247;
          box-sizing: border-box;
          display: inline-flex;
          margin-right: 4px;

          > svg {
            list-style: none;
            font: inherit;
            text-transform: none;
            font-family: inherit;
            line-height: 1.2;
            letter-spacing: 0.3px;
            font-size: 14px;
            cursor: pointer;
            font-weight: 500;
            color: #183247;
            box-sizing: border-box;
            max-height: 100%;
            width: 12px;
            height: 12px;
            fill: #12344d;
            overflow: hidden;
          }
        }
      }
    }
  }
`;

export const modalStyle = {
  overlay: {
    background: 'rgba(0,0,0,.4)',
    zIndex: 1000,
    minWidth: 800,
  },
  content: {
    margin: 100,
    background: 'none',
    border: 'none',
    inset: '0% 11% 0% 11%',
    padding: 'none',
  },
};
