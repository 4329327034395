/* eslint-disable camelcase */
import { createContext, useContext } from 'react';
import useSystemLogic from './logic';

import { Props, SystemContextData } from './types';

const SystemContext = createContext<SystemContextData>({} as SystemContextData);

const SystemProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    systems,
    sensitives,
    handleSystemSubscription,
    cleanSessionSystem,
    syncSystem,
    showAlertAfterSync,
    setShowAlertAfterSync,
  } = useSystemLogic();

  return (
    <SystemContext.Provider
      value={{
        systems,
        sensitives,
        handleSystemSubscription,
        cleanSessionSystem,
        syncSystem,
        showAlertAfterSync,
        setShowAlertAfterSync,
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};

function useSystem(): SystemContextData {
  const context = useContext(SystemContext);

  if (!context) {
    throw new Error('useSystem must be used within an SystemProvider');
  }

  return context;
}

export { SystemProvider, useSystem };
