import { Styles } from 'react-joyride';

export const joyrideStyles: Styles = {
  buttonNext: {
    backgroundColor: '#602f58',
  },
  buttonBack: {
    color: '#602f58',
  },
  beaconInner: {
    backgroundColor: '#602f58',
  },
  beaconOuter: {
    backgroundColor: '#602f5837',
    border: '2px solid #602f58',
  },
};

export const joyrideLocale = {
  back: 'Voltar',
  close: 'Fechar',
  last: 'Entendi',
  next: 'Entendi',
  open: 'Abrir',
  skip: 'Desistir',
};
