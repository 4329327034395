import { getValue } from 'utils/dotNotation';
import { SvgStroke, SvgStrokeBase } from './style';
import { ISkinProps } from './types';

const Calendar: React.FC<ISkinProps> = ({ skin, selector }: ISkinProps) => {
  let RealSvgStroke = SvgStrokeBase;

  if (!skin) {
    RealSvgStroke = SvgStroke;
  }

  return (
    <RealSvgStroke
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgFill"
      selector={selector}
      color={getValue({ skin }, selector)}
    >
      <path
        d="M11.875 2.5L3.125 2.5C2.43464 2.5 1.875 3.05964 1.875 3.75L1.875 12.5C1.875 13.1904 2.43464 13.75 3.125 13.75L11.875 13.75C12.5654 13.75 13.125 13.1904 13.125 12.5L13.125 3.75C13.125 3.05964 12.5654 2.5 11.875 2.5Z"
        stroke="black"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 1.25V3.75" stroke="black" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 1.25V3.75" stroke="black" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.875 6.25L13.125 6.25" stroke="black" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
    </RealSvgStroke>
  );
};

export default Calendar;
