import { createContext, useContext } from 'react';
import { Props, TicketContextDataContextData } from './types';
import useTicketLogic from './logic';

const TicketContext = createContext<TicketContextDataContextData>({} as TicketContextDataContextData);

const TicketProvider: React.FC<Props> = ({ children }: Props) => {
  const { tickets, loadingTickets, errorTickets } = useTicketLogic();

  return <TicketContext.Provider value={{ tickets, loadingTickets, errorTickets }}>{children}</TicketContext.Provider>;
};

function useTicket(): TicketContextDataContextData {
  const context = useContext(TicketContext);

  if (!context) {
    throw new Error('useTicket must be used within an TicketProvider');
  }

  return context;
}

export { TicketProvider, useTicket };
