import { createContext, useContext } from 'react';
import { Props, AuthContextDataContextData } from './types';
import useModalLogic from './logic';

const ModalContext = createContext<AuthContextDataContextData>({} as AuthContextDataContextData);

const ModalProvider: React.FC<Props> = ({ children }: Props) => {
  const { tabs, registerTab, closeTab, isComeBackShown, handleClickComeBack, setIsComeBackShown, isOpen, setIsOpen } =
    useModalLogic();

  return (
    <ModalContext.Provider
      value={{
        tabs,
        registerTab,
        closeTab,
        isComeBackShown,
        handleClickComeBack,
        setIsComeBackShown,
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): AuthContextDataContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };
