import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.div`
  z-index: 30;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

export const ResetWizardButton = styled.button`
  position: absolute;
  bottom: 36px;
  right: 140px;
  padding: 8px;
  border: none;
  z-index: 30;
  display: flex;
  border-radius: 30px 8px 30px 30px;
  align-items: center;

  > p {
    margin: auto;
    font-size: 0.9rem !important;
    font-weight: 600;
    font-weight: bold;
    float: left;
    margin: 0px 8px 0px 6px;
    color: #602f58;
  }

  > svg {
    height: 16px;
    width: 16px;
    float: left;
    circle,
    line {
      stroke: #602f58;
    }
  }
`;

export const Channels = styled(ResetWizardButton)`
  right: 290px;
  background-color: #ddd;

  > p {
    color: #ff0000;
  }

  > svg {
    circle,
    line {
      stroke: #ff0000;
    }
  }
`;

export const LinkToApp = styled.button`
  display: flex;
  margin: auto;
  margin-top: -80px !important;
  visibility: visible;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  user-select: none;
  cursor: pointer;
  border: none;

  font-weight: bold;
  margin-top: 10px;
  padding: 20px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  box-shadow: 0 0 10px #2d0e28;
  border-radius: 10px;
  color: #666666;
  text-decoration: none;

  background-image: linear-gradient(to right, #f0f3f8 0%, #fff 51%, #ddd 100%);
  transition: all 0.2s ease, visibility 0s;

  align-items: center;
  > svg {
    width: 20px;
    height: 20px;
  }

  > p {
    font-size: 12px !important;
    margin-left: 5px;
  }

  :hover {
    background-position: right center;
  }
`;
interface TabProps {
  isSelected?: boolean;
}

export const Content = styled.div`
  display: flex;
  min-width: 1024px;
  min-height: 610px;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;

  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  > div + div {
    margin-left: 32px;
  }
`;

interface SmartphoneProps {
  backgroundColor?: string;
  cardsBackgroundColor?: string;
  statusBarColor?: string;
  textColor?: string;
  img?: string;
}

export const Smartphone = styled.div<SmartphoneProps>`
  min-width: 300px;
  min-height: 685px;
  margin-top: auto;
  margin-bottom: auto;

  > svg {
    min-height: 600px;
    max-height: 600px;
    max-width: 300px;
    min-width: 300px;
    height: 80vh;
  }
`;

export const Tabs = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  min-width: 550px;
  max-width: 680px;

  @media (min-width: 1040px) {
    min-width: 680px;
    max-width: 760px;
  }

  @media (min-width: 1280px) {
    min-width: 760px;
    max-width: 860px;
  }

  @media (min-width: 1920px) {
    min-width: 860px;
    max-width: 1100px;
  }

  min-height: 560px;
  display: inline-block;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  border-radius: 0px 30px 5px 5px;

  .scroll-hidden {
    overflow-y: hidden !important;
  }
`;

export const Menu = styled.div`
  display: flex;
`;

export const Tab = styled.div<TabProps>`
  cursor: pointer;
  margin-top: 15px;
  padding-bottom: 7px;
  display: flex;
  background-color: #f0f3f8;
  border-radius: 15px 15px 0px 0px;

  width: 20%;

  min-width: 140px;
  max-width: 158px;

  padding: 10px 10px 0px 10px;

  > h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    margin-left: 4px;
    font-size: 12.6px;
    color: #666;
  }

  > svg {
    width: 22px;
    height: 22px;
  }

  @media (min-width: 1040px) {
    min-width: 155px;
    max-width: 220px;

    h3 {
      font-size: 13px;
      margin-left: 10px;
    }
  }

  @media (min-width: 1280px) {
    min-width: 200px;
    max-width: 220px;
  }

  @media (min-width: 1920px) {
    min-width: 250px;
    max-width: 250px;
  }

  @media (min-width: 1280px) {
    h3 {
      font-size: 16px;
      margin-left: 6px;
    }
    > svg {
      width: 25px;
      height: 25px;
    }
  }

  @media (min-width: 1920px) {
    h3 {
      font-size: 18px;
      margin-left: 10px;
    }
    > svg {
      width: 30px;
      height: 30px;
    }
  }

  path {
    fill: #666;
    stroke: #666;
  }
  circle {
    fill: #666;
    stroke: #666;
  }

  ${props =>
    props.isSelected &&
    css`
      margin-top: 5px;
      height: 65px;
      background-color: #ffffff;

      height: 51px;

      @media (min-width: 1280px) {
        height: 55px;
      }

      @media (min-width: 1920px) {
        height: 60px;
      }

      > h3 {
        color: #602f58;
      }

      path {
        fill: #602f58;
        stroke: #602f58;
      }
      circle {
        fill: #602f58;
        stroke: #602f58;
      }
    `}
`;

export const CurrentTab = styled.div<TabProps>`
  min-width: 630px;
  max-width: 760px;

  @media (min-width: 1280px) {
    min-width: 850px;
    max-width: 900px;
  }

  @media (min-width: 1920px) {
    min-width: 1100px;
    max-width: 1100px;
  }

  background-color: #ffffff;
  border-radius: 0px 10px 5px 5px;
  padding: 15px 10px 10px 10px;

  min-height: 500px;
  max-height: 500px;

  overflow-y: auto !important;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    width: 11px;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  > .skeleton-suspense {
    > div.message {
      position: absolute;
      min-height: 512px;
      width: 100%;
      display: flex;
      align-items: center;

      > p {
        width: 100%;
        text-align: center;
        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 18px !important;
        color: #666 !important;
      }
    }

    span {
      min-height: 512px;
      span {
        min-height: 512px;
      }
    }
  }
`;

interface PushNotificationProps {
  isShown: boolean;
}

export const PushNotification = styled.div<PushNotificationProps>`
  background-color: #fff;
  border-radius: 8px;
  margin-top: 50px;
  margin-left: 22px;
  padding: 8px;
  position: absolute;
  min-width: 240px;
  max-width: 240px;
  max-height: 224px;
  min-height: 42px;
  z-index: 0;
  display: auto;

  ${props =>
    !props.isShown &&
    css`
      display: none;
    `}

  .title {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  .content {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    text-align: justify;
    max-height: 99px;
    font-size: 10px;
    max-width: 222px;
    white-space: break-spaces;
  }
`;

interface ConfirmationProps {
  isShown: boolean;
}

export const Confirmation = styled.div<ConfirmationProps>`
  background-color: #f0f3f8;
  width: 100%;
  min-height: 515px;
  padding: 20px;
  border-radius: 5px;
  display: none;
  flex-direction: column;

  ${props =>
    props.isShown &&
    css`
      display: flex;
    `}

  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 59px;
    text-align: center;

    color: #333;
    margin-bottom: 35px;
  }

  span {
    min-height: 50px;
    min-width: 100%;
    height: 150px;
    width: 100%;
  }
`;
