import { OverlayContainer, OverlayBackground, OverlayLoader } from './style';

interface Props {
  isLoading: boolean;
  children?: React.ReactNode;
  type?: 'default' | 'tab';
}

const Overlay: React.FC<Props> = ({ isLoading, children, type = 'default' }: Props) => {
  const styles = { default: {}, tab: { maxHeight: '540px', marginTop: '60px', maxWidth: '850px' } };

  return (
    <div style={{ display: isLoading ? 'block' : 'none' }}>
      <OverlayContainer>
        <OverlayBackground style={styles[type]}>{children}</OverlayBackground>
        <OverlayLoader>
          <span aria-hidden="true" />
        </OverlayLoader>
      </OverlayContainer>
    </div>
  );
};

export default Overlay;
